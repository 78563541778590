import React, { useMemo } from 'react';
import { Practitioner } from '@medplum/fhirtypes';
import { HumanNameDisplay } from '@medplum/react';
import { Maybe } from 'medplum-gql';

import classes from './TaskTable.module.css';
import { UnassignedBadge } from '@components/shared/UnassignedBadge';

interface AssignedToColumnProps {
  assignee: Maybe<Practitioner>;
  profile: Practitioner;
  practitionerResponded: boolean;
}

const AssigneeDetails = ({ assignee, profile, practitionerResponded }: AssignedToColumnProps): JSX.Element => {
  const isAssigneeLoggedIn = useMemo(() => {
    return profile.id === assignee!.id;
  }, [profile, assignee]);

  return (
    <div>
      {isAssigneeLoggedIn && !practitionerResponded && <span className={classes.dot} />}
      <span>
        <HumanNameDisplay value={assignee?.name![0]} />
      </span>
      {isAssigneeLoggedIn && <span className={classes.meIndicator}> (Me)</span>}
    </div>
  );
};

export function AssignedToColumn(props: AssignedToColumnProps): JSX.Element {
  return <>{props.assignee ? <AssigneeDetails {...props} /> : <UnassignedBadge />}</>;
}
