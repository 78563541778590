import React, { useEffect } from 'react';
import posthog from 'posthog-js';
import { PostHogProvider as BaseProvider } from 'posthog-js/react';
import { posthogApiHost, posthogApiKey, appEnv } from '../../config';
import { formatHumanName } from '@medplum/core';
import { useMedplumProfile } from '@medplum/react';

const options = {
  api_host: posthogApiHost,
};

export const PostHogProvider = ({ children }: { children: React.ReactNode }) => {
  const profile = useMedplumProfile();

  useEffect(() => {
    if (appEnv === 'dev' && posthogApiKey && profile) {
      posthog.init(posthogApiKey, { api_host: posthogApiHost });
      const email = profile.telecom?.find((t) => t.system === 'email')?.value || 'unknown email';
      const name = profile.name?.[0] ? formatHumanName(profile.name?.[0]) : 'unknown name';

      posthog.identify(`Practitioner/${profile.id}`, { email, name });
    }
  }, [profile]);

  return appEnv === 'dev' ? (
    <BaseProvider apiKey={posthogApiKey} options={options}>
      {children}
    </BaseProvider>
  ) : (
    <>{children}</>
  );
};
