import { CommunicationMedium } from 'const-utils';
import { BaseCommunication } from './baseCommunication';
import { PractitionerInfoFragment } from 'medplum-gql';

export class Notification extends BaseCommunication<CommunicationMedium.Notification> {
  get recipient(): PractitionerInfoFragment | null {
    if ('Practitioner' !== this.communication.recipient?.[0].resource?.__typename) {
      return null;
    }

    return this.communication.recipient?.[0].resource;
  }

  get title(): string | null | undefined {
    return this.communication.note?.[0].text;
  }

  get received(): boolean {
    return !!this.communication.received;
  }

  get sender(): PractitionerInfoFragment | null {
    if ('Practitioner' !== this.communication.sender?.resource?.__typename) {
      return null;
    }

    return this.communication.sender?.resource;
  }
}
