import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
};

export type Access = {
  __typename?: 'Access';
  params: Array<AccessParameter>;
  policy: Scalars['String']['output'];
};

export type AccessParameter = {
  __typename?: 'AccessParameter';
  name: Scalars['String']['output'];
  valueReference?: Maybe<Reference>;
};

export enum CarePathway {
  Ac = 'AC',
  Bh = 'BH',
  Cc = 'CC',
  M = 'M'
}

export type Caregiver = {
  __typename?: 'Caregiver';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  reference: Scalars['String']['output'];
};

export type ChatAttachment = {
  __typename?: 'ChatAttachment';
  contentType: Scalars['String']['output'];
  reference?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type ChatCommunication = {
  __typename?: 'ChatCommunication';
  encounterId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  payload: ChatPayload;
  received?: Maybe<Scalars['Date']['output']>;
  sender: Sender;
  senderType: SenderType;
  sent: Scalars['Date']['output'];
};

export type ChatPayload = {
  __typename?: 'ChatPayload';
  contentAttachments?: Maybe<Array<Maybe<ChatAttachment>>>;
  contentString?: Maybe<Scalars['String']['output']>;
};

export type ChatTask = {
  __typename?: 'ChatTask';
  assignee?: Maybe<Practitioner>;
  focusId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isSuicideAlert: Scalars['Boolean']['output'];
  open: Scalars['Boolean']['output'];
  priority: TaskPriority;
  status: Scalars['String']['output'];
  tags: Array<Scalars['String']['output']>;
};

export type ChatTimelineResult = {
  __typename?: 'ChatTimelineResult';
  timelineItems: Array<TimelineItem>;
};

export type Device = {
  __typename?: 'Device';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  reference: Scalars['String']['output'];
};

export type DevicePermissionsResult = {
  __typename?: 'DevicePermissionsResult';
  deviceId: Scalars['String']['output'];
  deviceNames: Array<Scalars['String']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  modelNumber?: Maybe<Scalars['String']['output']>;
  permission?: Maybe<Scalars['String']['output']>;
  version: Array<Scalars['String']['output']>;
};

export type GetOutreachPanelInput = {
  attribution?: InputMaybe<Scalars['String']['input']>;
  carePathway?: InputMaybe<Scalars['String']['input']>;
  disposition?: InputMaybe<Scalars['String']['input']>;
  engagementPodCareTeamId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  outreachStatus?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  programStatus?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<OutreachPanelSortOptions>;
};

export type HumanName = {
  __typename?: 'HumanName';
  family: Scalars['String']['output'];
  given: Scalars['String']['output'];
};

export type ListUsersResult = {
  __typename?: 'ListUsersResult';
  total: Scalars['Int']['output'];
  users: Array<User>;
};

export type MarketRole = {
  __typename?: 'MarketRole';
  endDate?: Maybe<Scalars['String']['output']>;
  market: Scalars['String']['output'];
  role: Scalars['String']['output'];
  startDate?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  reactivateCaregiver: Result;
  resetPatient: ResetPatientResponse;
  revokeUsers: Result;
  translateText: Translation;
  upsertUser: Result;
};


export type MutationReactivateCaregiverArgs = {
  request: ReactivationInput;
};


export type MutationResetPatientArgs = {
  request: ResetPatientInput;
};


export type MutationRevokeUsersArgs = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationTranslateTextArgs = {
  targetLanguage: Scalars['String']['input'];
  text: Scalars['String']['input'];
};


export type MutationUpsertUserArgs = {
  user: UserInput;
};

export type OutreachPanelResult = {
  __typename?: 'OutreachPanelResult';
  allPatientIds: Array<Scalars['String']['output']>;
  cachedAt: Scalars['Float']['output'];
  patients: Array<PatientPanelResult>;
  total: Scalars['Int']['output'];
};

export type OutreachPanelSortOptions = {
  field: PanelSortField;
  order: PanelSortOrder;
};

export type PageData = {
  count?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export enum PanelSortField {
  LastOutreach = 'lastOutreach',
  Name = 'name',
  OutreachCount = 'outreachCount'
}

export enum PanelSortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type PatientPanelResult = {
  __typename?: 'PatientPanelResult';
  attribution?: Maybe<Scalars['String']['output']>;
  carePathway?: Maybe<Scalars['String']['output']>;
  disposition?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastOutreach: Scalars['String']['output'];
  name: Scalars['String']['output'];
  outreachCount: Scalars['Int']['output'];
  outreachStatus?: Maybe<Scalars['String']['output']>;
  programStatus?: Maybe<Scalars['String']['output']>;
};

export type Practitioner = {
  __typename?: 'Practitioner';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  reference: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  chatTimeline: ChatTimelineResult;
  devicePermissions: Array<DevicePermissionsResult>;
  exportUsers: UsersCsv;
  listUsers: ListUsersResult;
  outreachPanels: OutreachPanelResult;
};


export type QueryChatTimelineArgs = {
  pageData?: InputMaybe<PageData>;
  patientId: Scalars['String']['input'];
};


export type QueryDevicePermissionsArgs = {
  caregiverId: Scalars['String']['input'];
};


export type QueryListUsersArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOutreachPanelsArgs = {
  input: GetOutreachPanelInput;
};

export type ReactivationInput = {
  caregiverId: Scalars['String']['input'];
  reason: Scalars['String']['input'];
};

export type Reference = {
  __typename?: 'Reference';
  display?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
};

export type ResetPatientInput = {
  patientId: Scalars['String']['input'];
  resetMessages?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ResetPatientResponse = {
  __typename?: 'ResetPatientResponse';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type Result = {
  __typename?: 'Result';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type Sender = Caregiver | Device | Practitioner;

export enum SenderType {
  Caregiver = 'CAREGIVER',
  Device = 'DEVICE',
  Practitioner = 'PRACTITIONER'
}

export enum TaskPriority {
  Asap = 'ASAP',
  Routine = 'ROUTINE',
  Stat = 'STAT',
  Urgent = 'URGENT'
}

export type TimelineItem = {
  __typename?: 'TimelineItem';
  communication: ChatCommunication;
  market: Scalars['String']['output'];
  task: ChatTask;
};

export type Translation = {
  __typename?: 'Translation';
  translatedText: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  access: Array<Access>;
  admin: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  readOnly: Scalars['Boolean']['output'];
  roles?: Maybe<Array<MarketRole>>;
};

export type UserInput = {
  admin: Scalars['Boolean']['input'];
  approverEndDate?: InputMaybe<Scalars['String']['input']>;
  approverStartDate?: InputMaybe<Scalars['String']['input']>;
  carePathways: Array<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  engagementPodId?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  markets: Array<Scalars['String']['input']>;
  readOnly: Scalars['Boolean']['input'];
  role: Scalars['String']['input'];
  update: Scalars['Boolean']['input'];
};

export type UsersCsv = {
  __typename?: 'UsersCsv';
  csv: Scalars['String']['output'];
};

export type ReactivateCaregiverAccessMutationVariables = Exact<{
  request: ReactivationInput;
}>;


export type ReactivateCaregiverAccessMutation = { __typename?: 'Mutation', reactivateCaregiver: { __typename?: 'Result', success: boolean } };

export type ResetPatientMutationVariables = Exact<{
  request: ResetPatientInput;
}>;


export type ResetPatientMutation = { __typename?: 'Mutation', resetPatient: { __typename?: 'ResetPatientResponse', success: boolean, message?: string | null } };

export type RevokeCarehubAccessMutationVariables = Exact<{
  userIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type RevokeCarehubAccessMutation = { __typename?: 'Mutation', revokeUsers: { __typename?: 'Result', success: boolean } };

export type TranslateChatTextMutationVariables = Exact<{
  text: Scalars['String']['input'];
  targetLanguage: Scalars['String']['input'];
}>;


export type TranslateChatTextMutation = { __typename?: 'Mutation', translateText: { __typename?: 'Translation', translatedText: string } };

export type UpsertCarehubUserMutationVariables = Exact<{
  input: UserInput;
}>;


export type UpsertCarehubUserMutation = { __typename?: 'Mutation', upsertUser: { __typename?: 'Result', success: boolean } };

export type ExportUsersCsvQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportUsersCsvQuery = { __typename?: 'Query', exportUsers: { __typename?: 'UsersCsv', csv: string } };

export type GetChatTimelineForPatientQueryVariables = Exact<{
  patientId: Scalars['String']['input'];
}>;


export type GetChatTimelineForPatientQuery = { __typename?: 'Query', chatTimeline: { __typename?: 'ChatTimelineResult', timelineItems: Array<{ __typename?: 'TimelineItem', market: string, task: { __typename?: 'ChatTask', id: string, tags: Array<string>, focusId: string, priority: TaskPriority, open: boolean, status: string, isSuicideAlert: boolean, assignee?: { __typename?: 'Practitioner', id: string, name: string } | null }, communication: { __typename?: 'ChatCommunication', id: string, sent: any, received?: any | null, encounterId: string, senderType: SenderType, payload: { __typename?: 'ChatPayload', contentString?: string | null, contentAttachments?: Array<{ __typename?: 'ChatAttachment', url: string, contentType: string, title?: string | null } | null> | null }, sender: { __typename: 'Caregiver', id: string, name: string } | { __typename: 'Device', id: string, name: string } | { __typename: 'Practitioner', id: string, name: string } } }> } };

export type GetDevicePermissionsQueryVariables = Exact<{
  caregiverId: Scalars['String']['input'];
}>;


export type GetDevicePermissionsQuery = { __typename?: 'Query', devicePermissions: Array<{ __typename?: 'DevicePermissionsResult', deviceId: string, deviceNames: Array<string>, manufacturer?: string | null, modelNumber?: string | null, version: Array<string>, permission?: string | null }> };

export type GetOutreachPanelQueryVariables = Exact<{
  input: GetOutreachPanelInput;
}>;


export type GetOutreachPanelQuery = { __typename?: 'Query', outreachPanels: { __typename?: 'OutreachPanelResult', cachedAt: number, total: number, allPatientIds: Array<string>, patients: Array<{ __typename?: 'PatientPanelResult', id: string, name: string, lastOutreach: string, carePathway?: string | null, programStatus?: string | null, attribution?: string | null, outreachStatus?: string | null, disposition?: string | null, outreachCount: number }> } };

export type GetUserPageQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUserPageQuery = { __typename?: 'Query', listUsers: { __typename?: 'ListUsersResult', total: number, users: Array<{ __typename?: 'User', id: string, firstName: string, lastName: string, email: string, admin: boolean, readOnly: boolean, roles?: Array<{ __typename?: 'MarketRole', market: string, role: string, startDate?: string | null, endDate?: string | null }> | null, access: Array<{ __typename?: 'Access', policy: string, params: Array<{ __typename?: 'AccessParameter', name: string, valueReference?: { __typename?: 'Reference', display?: string | null, reference?: string | null } | null }> }> }> } };


export const ReactivateCaregiverAccessDocument = gql`
    mutation ReactivateCaregiverAccess($request: ReactivationInput!) {
  reactivateCaregiver(request: $request) {
    success
  }
}
    `;
export type ReactivateCaregiverAccessMutationFn = Apollo.MutationFunction<ReactivateCaregiverAccessMutation, ReactivateCaregiverAccessMutationVariables>;

/**
 * __useReactivateCaregiverAccessMutation__
 *
 * To run a mutation, you first call `useReactivateCaregiverAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateCaregiverAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateCaregiverAccessMutation, { data, loading, error }] = useReactivateCaregiverAccessMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useReactivateCaregiverAccessMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateCaregiverAccessMutation, ReactivateCaregiverAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateCaregiverAccessMutation, ReactivateCaregiverAccessMutationVariables>(ReactivateCaregiverAccessDocument, options);
      }
export type ReactivateCaregiverAccessMutationHookResult = ReturnType<typeof useReactivateCaregiverAccessMutation>;
export type ReactivateCaregiverAccessMutationResult = Apollo.MutationResult<ReactivateCaregiverAccessMutation>;
export type ReactivateCaregiverAccessMutationOptions = Apollo.BaseMutationOptions<ReactivateCaregiverAccessMutation, ReactivateCaregiverAccessMutationVariables>;
export const ResetPatientDocument = gql`
    mutation ResetPatient($request: ResetPatientInput!) {
  resetPatient(request: $request) {
    success
    message
  }
}
    `;
export type ResetPatientMutationFn = Apollo.MutationFunction<ResetPatientMutation, ResetPatientMutationVariables>;

/**
 * __useResetPatientMutation__
 *
 * To run a mutation, you first call `useResetPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPatientMutation, { data, loading, error }] = useResetPatientMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useResetPatientMutation(baseOptions?: Apollo.MutationHookOptions<ResetPatientMutation, ResetPatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPatientMutation, ResetPatientMutationVariables>(ResetPatientDocument, options);
      }
export type ResetPatientMutationHookResult = ReturnType<typeof useResetPatientMutation>;
export type ResetPatientMutationResult = Apollo.MutationResult<ResetPatientMutation>;
export type ResetPatientMutationOptions = Apollo.BaseMutationOptions<ResetPatientMutation, ResetPatientMutationVariables>;
export const RevokeCarehubAccessDocument = gql`
    mutation RevokeCarehubAccess($userIds: [String!]!) {
  revokeUsers(ids: $userIds) {
    success
  }
}
    `;
export type RevokeCarehubAccessMutationFn = Apollo.MutationFunction<RevokeCarehubAccessMutation, RevokeCarehubAccessMutationVariables>;

/**
 * __useRevokeCarehubAccessMutation__
 *
 * To run a mutation, you first call `useRevokeCarehubAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeCarehubAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeCarehubAccessMutation, { data, loading, error }] = useRevokeCarehubAccessMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useRevokeCarehubAccessMutation(baseOptions?: Apollo.MutationHookOptions<RevokeCarehubAccessMutation, RevokeCarehubAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeCarehubAccessMutation, RevokeCarehubAccessMutationVariables>(RevokeCarehubAccessDocument, options);
      }
export type RevokeCarehubAccessMutationHookResult = ReturnType<typeof useRevokeCarehubAccessMutation>;
export type RevokeCarehubAccessMutationResult = Apollo.MutationResult<RevokeCarehubAccessMutation>;
export type RevokeCarehubAccessMutationOptions = Apollo.BaseMutationOptions<RevokeCarehubAccessMutation, RevokeCarehubAccessMutationVariables>;
export const TranslateChatTextDocument = gql`
    mutation TranslateChatText($text: String!, $targetLanguage: String!) {
  translateText(text: $text, targetLanguage: $targetLanguage) {
    translatedText
  }
}
    `;
export type TranslateChatTextMutationFn = Apollo.MutationFunction<TranslateChatTextMutation, TranslateChatTextMutationVariables>;

/**
 * __useTranslateChatTextMutation__
 *
 * To run a mutation, you first call `useTranslateChatTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTranslateChatTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [translateChatTextMutation, { data, loading, error }] = useTranslateChatTextMutation({
 *   variables: {
 *      text: // value for 'text'
 *      targetLanguage: // value for 'targetLanguage'
 *   },
 * });
 */
export function useTranslateChatTextMutation(baseOptions?: Apollo.MutationHookOptions<TranslateChatTextMutation, TranslateChatTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TranslateChatTextMutation, TranslateChatTextMutationVariables>(TranslateChatTextDocument, options);
      }
export type TranslateChatTextMutationHookResult = ReturnType<typeof useTranslateChatTextMutation>;
export type TranslateChatTextMutationResult = Apollo.MutationResult<TranslateChatTextMutation>;
export type TranslateChatTextMutationOptions = Apollo.BaseMutationOptions<TranslateChatTextMutation, TranslateChatTextMutationVariables>;
export const UpsertCarehubUserDocument = gql`
    mutation UpsertCarehubUser($input: UserInput!) {
  upsertUser(user: $input) {
    success
  }
}
    `;
export type UpsertCarehubUserMutationFn = Apollo.MutationFunction<UpsertCarehubUserMutation, UpsertCarehubUserMutationVariables>;

/**
 * __useUpsertCarehubUserMutation__
 *
 * To run a mutation, you first call `useUpsertCarehubUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCarehubUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCarehubUserMutation, { data, loading, error }] = useUpsertCarehubUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertCarehubUserMutation(baseOptions?: Apollo.MutationHookOptions<UpsertCarehubUserMutation, UpsertCarehubUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertCarehubUserMutation, UpsertCarehubUserMutationVariables>(UpsertCarehubUserDocument, options);
      }
export type UpsertCarehubUserMutationHookResult = ReturnType<typeof useUpsertCarehubUserMutation>;
export type UpsertCarehubUserMutationResult = Apollo.MutationResult<UpsertCarehubUserMutation>;
export type UpsertCarehubUserMutationOptions = Apollo.BaseMutationOptions<UpsertCarehubUserMutation, UpsertCarehubUserMutationVariables>;
export const ExportUsersCsvDocument = gql`
    query ExportUsersCsv {
  exportUsers {
    csv
  }
}
    `;

/**
 * __useExportUsersCsvQuery__
 *
 * To run a query within a React component, call `useExportUsersCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportUsersCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportUsersCsvQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportUsersCsvQuery(baseOptions?: Apollo.QueryHookOptions<ExportUsersCsvQuery, ExportUsersCsvQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportUsersCsvQuery, ExportUsersCsvQueryVariables>(ExportUsersCsvDocument, options);
      }
export function useExportUsersCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportUsersCsvQuery, ExportUsersCsvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportUsersCsvQuery, ExportUsersCsvQueryVariables>(ExportUsersCsvDocument, options);
        }
export type ExportUsersCsvQueryHookResult = ReturnType<typeof useExportUsersCsvQuery>;
export type ExportUsersCsvLazyQueryHookResult = ReturnType<typeof useExportUsersCsvLazyQuery>;
export type ExportUsersCsvQueryResult = Apollo.QueryResult<ExportUsersCsvQuery, ExportUsersCsvQueryVariables>;
export const GetChatTimelineForPatientDocument = gql`
    query GetChatTimelineForPatient($patientId: String!) {
  chatTimeline(patientId: $patientId, pageData: {}) {
    timelineItems {
      market
      task {
        id
        tags
        focusId
        priority
        open
        status
        isSuicideAlert
        assignee {
          id
          name
        }
      }
      communication {
        id
        sent
        received
        encounterId
        senderType
        payload {
          contentString
          contentAttachments {
            url
            contentType
            title
          }
        }
        sender {
          __typename
          ... on Practitioner {
            id
            name
          }
          ... on Caregiver {
            id
            name
          }
          ... on Device {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetChatTimelineForPatientQuery__
 *
 * To run a query within a React component, call `useGetChatTimelineForPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatTimelineForPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatTimelineForPatientQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetChatTimelineForPatientQuery(baseOptions: Apollo.QueryHookOptions<GetChatTimelineForPatientQuery, GetChatTimelineForPatientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChatTimelineForPatientQuery, GetChatTimelineForPatientQueryVariables>(GetChatTimelineForPatientDocument, options);
      }
export function useGetChatTimelineForPatientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChatTimelineForPatientQuery, GetChatTimelineForPatientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChatTimelineForPatientQuery, GetChatTimelineForPatientQueryVariables>(GetChatTimelineForPatientDocument, options);
        }
export type GetChatTimelineForPatientQueryHookResult = ReturnType<typeof useGetChatTimelineForPatientQuery>;
export type GetChatTimelineForPatientLazyQueryHookResult = ReturnType<typeof useGetChatTimelineForPatientLazyQuery>;
export type GetChatTimelineForPatientQueryResult = Apollo.QueryResult<GetChatTimelineForPatientQuery, GetChatTimelineForPatientQueryVariables>;
export const GetDevicePermissionsDocument = gql`
    query GetDevicePermissions($caregiverId: String!) {
  devicePermissions(caregiverId: $caregiverId) {
    deviceId
    deviceNames
    manufacturer
    modelNumber
    version
    permission
  }
}
    `;

/**
 * __useGetDevicePermissionsQuery__
 *
 * To run a query within a React component, call `useGetDevicePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDevicePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDevicePermissionsQuery({
 *   variables: {
 *      caregiverId: // value for 'caregiverId'
 *   },
 * });
 */
export function useGetDevicePermissionsQuery(baseOptions: Apollo.QueryHookOptions<GetDevicePermissionsQuery, GetDevicePermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDevicePermissionsQuery, GetDevicePermissionsQueryVariables>(GetDevicePermissionsDocument, options);
      }
export function useGetDevicePermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDevicePermissionsQuery, GetDevicePermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDevicePermissionsQuery, GetDevicePermissionsQueryVariables>(GetDevicePermissionsDocument, options);
        }
export type GetDevicePermissionsQueryHookResult = ReturnType<typeof useGetDevicePermissionsQuery>;
export type GetDevicePermissionsLazyQueryHookResult = ReturnType<typeof useGetDevicePermissionsLazyQuery>;
export type GetDevicePermissionsQueryResult = Apollo.QueryResult<GetDevicePermissionsQuery, GetDevicePermissionsQueryVariables>;
export const GetOutreachPanelDocument = gql`
    query GetOutreachPanel($input: GetOutreachPanelInput!) {
  outreachPanels(input: $input) {
    patients {
      id
      name
      lastOutreach
      carePathway
      programStatus
      attribution
      outreachStatus
      disposition
      outreachCount
    }
    cachedAt
    total
    allPatientIds
  }
}
    `;

/**
 * __useGetOutreachPanelQuery__
 *
 * To run a query within a React component, call `useGetOutreachPanelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutreachPanelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutreachPanelQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOutreachPanelQuery(baseOptions: Apollo.QueryHookOptions<GetOutreachPanelQuery, GetOutreachPanelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOutreachPanelQuery, GetOutreachPanelQueryVariables>(GetOutreachPanelDocument, options);
      }
export function useGetOutreachPanelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOutreachPanelQuery, GetOutreachPanelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOutreachPanelQuery, GetOutreachPanelQueryVariables>(GetOutreachPanelDocument, options);
        }
export type GetOutreachPanelQueryHookResult = ReturnType<typeof useGetOutreachPanelQuery>;
export type GetOutreachPanelLazyQueryHookResult = ReturnType<typeof useGetOutreachPanelLazyQuery>;
export type GetOutreachPanelQueryResult = Apollo.QueryResult<GetOutreachPanelQuery, GetOutreachPanelQueryVariables>;
export const GetUserPageDocument = gql`
    query GetUserPage($page: Int, $count: Int, $name: String) {
  listUsers(page: $page, count: $count, name: $name) {
    users {
      id
      firstName
      lastName
      email
      admin
      readOnly
      roles {
        market
        role
        startDate
        endDate
      }
      access {
        policy
        params {
          name
          valueReference {
            display
            reference
          }
        }
      }
    }
    total
  }
}
    `;

/**
 * __useGetUserPageQuery__
 *
 * To run a query within a React component, call `useGetUserPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      count: // value for 'count'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetUserPageQuery(baseOptions?: Apollo.QueryHookOptions<GetUserPageQuery, GetUserPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserPageQuery, GetUserPageQueryVariables>(GetUserPageDocument, options);
      }
export function useGetUserPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserPageQuery, GetUserPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserPageQuery, GetUserPageQueryVariables>(GetUserPageDocument, options);
        }
export type GetUserPageQueryHookResult = ReturnType<typeof useGetUserPageQuery>;
export type GetUserPageLazyQueryHookResult = ReturnType<typeof useGetUserPageLazyQuery>;
export type GetUserPageQueryResult = Apollo.QueryResult<GetUserPageQuery, GetUserPageQueryVariables>;