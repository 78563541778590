import React from 'react';
import { Paper, Table, Text } from '@mantine/core';
import { System } from 'const-utils';
import { CarePathwayReferralTask } from 'imagine-dsl/models/tasks/carePathwayReferralTask';

interface DataDrivenReferralProps {
  task: CarePathwayReferralTask;
}

const DataDrivenReferral: React.FC<DataDrivenReferralProps> = ({ task }) => {
  return (
    <Paper my="md" withBorder>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>ICD-10 code</Table.Th>
            <Table.Th>Description</Table.Th>
            <Table.Th>Most recent month</Table.Th>
            <Table.Th>First month</Table.Th>
            <Table.Th>Number of months</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {task?.inputBySystem(System.CarePathwayDataReferral)?.valueCodeableConcept?.coding?.map((c, index) => {
            const ICD = c.code;
            const Description = c.display;
            const MostRecentMonth = c.extension?.find(
              (e) => e.url === System.CarePathwayDataDiagnosisMostRecentMonth.toString(),
            )?.valueString;
            const FirstMonth = c.extension?.find(
              (e) => e.url === System.CarePathwayDataDiagnosisFirstMonth.toString(),
            )?.valueString;
            const NumberOfMonths = c.extension?.find(
              (e) => e.url === System.CarePathwayDataDiagnosisNumberOfMonths.toString(),
            )?.valueString;

            return (
              <Table.Tr key={index}>
                <Table.Td>
                  <Text fw="bold">{ICD}</Text>
                </Table.Td>
                <Table.Td>
                  <Text>{Description}</Text>
                </Table.Td>
                <Table.Td>
                  <Text fs={MostRecentMonth ? undefined : 'italic'} c={MostRecentMonth ? undefined : 'dimmed'}>
                    {MostRecentMonth}
                  </Text>
                </Table.Td>
                <Table.Td>
                  <Text fs={FirstMonth ? undefined : 'italic'} c={FirstMonth ? undefined : 'dimmed'}>
                    {FirstMonth}
                  </Text>
                </Table.Td>
                <Table.Td>
                  <Text fs={NumberOfMonths ? undefined : 'italic'} c={NumberOfMonths ? undefined : 'dimmed'}>
                    {NumberOfMonths}
                  </Text>
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
    </Paper>
  );
};

export default DataDrivenReferral;
