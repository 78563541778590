import React, { useMemo, useState } from 'react';
import { Text, Stack, Paper, Grid, ActionIcon, Group } from '@mantine/core';
import { IconCheck, IconCirclePlusFilled } from '@tabler/icons-react';
import { useSearchParams } from 'react-router-dom';
import { TaskType } from 'const-utils';
import {
  useGetIncompleteOutreachTasksByPatientIdQuery,
  useGetIncompleteCarePathwayTasksByPatientIdQuery,
  useGetReviewTasksByPatientIdQuery,
} from 'medplum-gql';
import { compact } from 'lodash';
import { SurveyReviewTask } from 'imagine-dsl/models/tasks/surveyReviewTask';
import { BaseTask } from 'imagine-dsl/models/tasks/baseTask';
import { TasksDrawer } from '@/components/tasks/drawer';
import { TasksCard } from './tasksCard';
import { useDisclosure } from '@mantine/hooks';
import UpsertTaskModal from '@/components/shared/UpsertTaskModal';

interface PatientOpenTasksProps {
  patientId: string;
}

export const PatientOpenTasks = ({ patientId }: PatientOpenTasksProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const focusedTaskId = useMemo(() => {
    return searchParams.get('task');
  }, [searchParams]);

  const taskTags = [TaskType.ScreenerReview, TaskType.ReviewBHSurvey, TaskType.ReviewDisasterPreparednessSurvey];

  const { data: surveyReviewTasksData, refetch: refetchSurveyReviewTasks } = useGetReviewTasksByPatientIdQuery({
    variables: {
      patientId,
      tags: taskTags.join(','),
    },
  });
  const reviewTasks = compact(surveyReviewTasksData?.TaskList).map((t) => new SurveyReviewTask(t));

  const screenerReviewTask: SurveyReviewTask[] = reviewTasks.filter(
    (t) => !t.completed && t.type === TaskType.ScreenerReview,
  );
  const disasterPrepReviewTask: SurveyReviewTask[] = reviewTasks.filter(
    (t) => !t.completed && t.type === TaskType.ReviewDisasterPreparednessSurvey,
  );
  const bhReviewTasks: SurveyReviewTask[] = reviewTasks.filter(
    (t) => !t.completed && t.type === TaskType.ReviewBHSurvey,
  );

  const { data: carePathwayReferralReviewTasksData, refetch: refetchCarePathwayReferralTasks } =
    useGetIncompleteCarePathwayTasksByPatientIdQuery({
      variables: {
        patientId,
      },
    });

  const { data: outreachTasksData, refetch: refetchOutreachTasks } = useGetIncompleteOutreachTasksByPatientIdQuery({
    variables: {
      patientId,
    },
  });

  const outreachTasks = useMemo(() => {
    return compact(outreachTasksData?.TaskList).map((t) => new BaseTask(t)) || [];
  }, [outreachTasksData]);

  const carePathwayReferralReviewTasks = useMemo(() => {
    return compact(carePathwayReferralReviewTasksData?.TaskList).map((t) => new BaseTask(t)) || [];
  }, [carePathwayReferralReviewTasksData]);

  const focusedTask = useMemo(() => {
    const allTasks = [
      ...screenerReviewTask,
      ...disasterPrepReviewTask,
      ...bhReviewTasks,
      ...carePathwayReferralReviewTasks,
      ...outreachTasks,
    ];
    return allTasks.find((task) => task?.id === focusedTaskId);
  }, [
    screenerReviewTask,
    disasterPrepReviewTask,
    bhReviewTasks,
    carePathwayReferralReviewTasks,
    outreachTasks,
    focusedTaskId,
  ]);

  const openDrawer = (taskId: string) => {
    setSearchParams({ ...searchParams, task: taskId });
  };

  const closeDrawer = () => {
    searchParams.delete('task');
    setSearchParams({ ...searchParams });
  };

  const totalOpenTasks = useMemo(() => {
    return (
      screenerReviewTask.length +
      disasterPrepReviewTask.length +
      bhReviewTasks.length +
      carePathwayReferralReviewTasks.length +
      outreachTasks.length
    );
  }, [screenerReviewTask, disasterPrepReviewTask, bhReviewTasks, carePathwayReferralReviewTasks, outreachTasks]);

  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const allTasks = [
    ...screenerReviewTask,
    ...disasterPrepReviewTask,
    ...bhReviewTasks,
    ...carePathwayReferralReviewTasks,
    ...outreachTasks,
  ];

  const visibleTasks = expanded ? allTasks : allTasks.slice(0, 4);

  const [openTaskModal, handleTaskModal] = useDisclosure();

  const closeTaskModal = async (): Promise<void> => {
    handleTaskModal.close();
  };

  return (
    <Paper mb={20} shadow="xs" p="xl" radius="lg">
      <Stack gap="xs">
        <Grid justify="space-between" align="center">
          <Text size="xl" style={{ marginBottom: '10px' }} c="imagine-green">
            Open tasks ({totalOpenTasks})
          </Text>
          <ActionIcon onClick={() => handleTaskModal.open()} mb="lg">
            <IconCirclePlusFilled size={24} />
          </ActionIcon>
        </Grid>

        {totalOpenTasks === 0 && (
          <Group align="center" gap="xs">
            <IconCheck color="green" />
            <Text>There are no open tasks for this patient</Text>
          </Group>
        )}

        <Stack gap="lg">
          {visibleTasks.map((task) => (
            <TasksCard key={task.id} task={task as BaseTask} onClick={() => openDrawer(task.id!)} />
          ))}
          {totalOpenTasks > 4 && (
            <Text
              onClick={handleToggleExpand}
              style={{ cursor: 'pointer', textAlign: 'center' }}
              td="underline"
              fw={700}
              c="imagine-green.6"
            >
              {expanded ? 'Collapse to 4 tasks' : `View all ${totalOpenTasks} tasks`}
            </Text>
          )}
        </Stack>
      </Stack>

      {focusedTask && (
        <TasksDrawer
          task={focusedTask as BaseTask}
          opened={!!focusedTask}
          onClose={closeDrawer}
          withViewPatientProfile
          refetchTasks={[refetchSurveyReviewTasks, refetchCarePathwayReferralTasks, refetchOutreachTasks]}
        />
      )}
      {openTaskModal && (
        <UpsertTaskModal
          patientId={patientId}
          opened={openTaskModal}
          closeModal={closeTaskModal}
          operation="Create"
          refetchTasks={[refetchSurveyReviewTasks, refetchCarePathwayReferralTasks, refetchOutreachTasks]}
        />
      )}
    </Paper>
  );
};
