import React from 'react';
import hubLogoSrc from '@assets/logo/hub.svg';
import hubLogoDevSrc from '@assets/logo/hub-dev.svg';
import hubLogoStageSrc from '@assets/logo/hub-stage.svg';
import { Config, getConfig } from '@/config';

const logoSrcs: Record<Config['appEnv'], string> = {
  local: hubLogoDevSrc,
  dev: hubLogoDevSrc,
  stage: hubLogoStageSrc,
  prod: hubLogoSrc,
};

const { appEnv } = getConfig();

export const CARE_HUB_LOGO_SRC = logoSrcs[appEnv] ?? hubLogoSrc;

export const CareHubLogo = ({ size }: { size: number | string }): JSX.Element => {
  return <img alt="Care Hub Logo" style={{ height: size, width: size }} src={CARE_HUB_LOGO_SRC} />;
};
