export const enum System {
  Acuity = 'https://imaginepediatrics.org/acuity',
  AcuitySeverity = 'https://imaginepediatrics.org/acuity/severity',
  AddressType = 'https://imaginepediatrics.org/address-type',
  AppStoreReviewer = 'https://imaginepediatrics.org/app-store-reviewer',
  AthenaId = 'https://imaginepediatrics.org/athena-id',
  Attribution = 'https://imaginepediatrics.org/attribution',
  BHSurveyType = 'https://imaginepediatrics.org/bh-survey-type',
  BHTOCTaskResolveReason = 'https://imaginepediatrics.org/bh-toc-task-resolve-reason',
  Bots = 'https://imaginepediatrics.org/bots',
  BulkTask = 'https://imaginepediatrics.org/bulk-task',
  CarePathway = 'https://imaginepediatrics.org/care-pathway',
  CarePathwayDataDiagnosisFirstMonth = 'https://imaginepediatrics.org/care-pathway-data-diagnosis-first-month',
  CarePathwayDataDiagnosisMostRecentMonth = 'https://imaginepediatrics.org/care-pathway-data-diagnosis-most-recent-month',
  CarePathwayDataDiagnosisNumberOfMonths = 'https://imaginepediatrics.org/care-pathway-data-diagnosis-number-of-months',
  CarePathwayDataReferral = 'https://imaginepediatrics.org/care-pathway-data-referral',
  CarePathwayReferralAssociatedDiagnoses = 'https://imaginepediatrics.org/care-pathway-referral-associated-diagnoses',
  CarePathwayReferralCancellationReason = 'https://imaginepediatrics.org/care-pathway-referral-cancellation-reason',
  CarePathwayReferralDenialReason = 'https://imaginepediatrics.org/care-pathway-referral-denial-reason',
  CarePathwayReferralDescription = 'https://imaginepediatrics.org/care-pathway-referral-description',
  CarePathwayReferralOverride = 'https://imaginepediatrics.org/care-pathway-referral-override',
  CarePathwayReferralReason = 'https://imaginepediatrics.org/care-pathway-referral-reason',
  CarePathwayReferralReviewNote = 'https://imaginepediatrics.org/care-pathway-referral-review-note',
  CarePathwayReferralType = 'https://imaginepediatrics.org/care-pathway-referral-type',
  CareTeam = 'https://imaginepediatrics.org/care-team',
  CareTeamRole = 'https://imaginepediatrics.org/care-team-role',
  Caregiver = 'https://imaginepediatrics.org/caregiver',
  CaregiverReactivation = 'https://imaginepediatrics.org/caregiver-reactivation',
  CaregiverSMSConsent = 'https://imaginepediatrics.org/caregiver-sms-consent',
  Cluster = 'https://imaginepediatrics.org/cluster',
  CommunicationCategory = 'https://imaginepediatrics.org/communication-category',
  CommunicationMedium = 'https://imaginepediatrics.org/communication-medium',
  CompositionType = 'https://imaginepediatrics.org/composition/type',
  Consent = 'https://imaginepediatrics.org/consent',
  ConsentType = 'https://imaginepediatrics.org/consent-type',
  ConsentSource = 'https://imaginepediatrics.org/consent-source',
  ConsentJurisdiction = 'https://imaginepediatrics.org/consent-jurisdiction',
  ContactType = 'https://imaginepediatrics.org/contact-type',
  Coverage = 'https://imaginepediatrics.org/coverage',
  Device = 'https://imaginepediatrics.org/device',
  DeviceBiometricPublicKey = 'https://imaginepediatrics.org/device/biometric-public-key',
  DevicePermissions = 'https://imaginepediatrics.org/device/permissions',
  DevicePushToken = 'https://imaginepediatrics.org/device/push-token',
  DeviceType = 'https://imaginepediatrics.org/device-type',
  DisengagementReason = 'https://imaginepediatrics.org/disengagement-reason',
  DocumentBranding = 'https://imaginepediatrics.org/document-branding',
  DocumentType = 'https://imaginepediatrics.org/document-type',
  EngagementLastOutreachDate = 'https://imaginepediatrics.org/engagement-last-outreach-date',
  EngagementOutreachCount = 'https://imaginepediatrics.org/engagement-outreach-count',
  EngagementPodAssignmentFallbackReason = 'https://imaginepediatrics.org/engagement-pod-assignment-fallback-reason',
  EncounterInitiator = 'https://imaginepediatrics.org/encounter-initiator',
  EncounterType = 'https://imaginepediatrics.org/encounter-type',
  EpisodeOfCare = 'https://imaginepediatrics.org/episode-of-care',
  ExternalCareTeam = 'https://imaginepediatrics.org/external-care-team',
  ExternalPractitionerAssociation = 'https://imaginepediatrics.org/external-practitioner-association',
  Feature = 'https://imaginepediatrics.org/feature',
  GenderIdentity = 'https://imaginepediatrics.org/gender-identity',
  HealthcareCategory = 'https://imaginepediatrics.org/healthcare-category',
  Impa = 'https://github.com/Imagine-Pediatrics/impa',
  Impression = 'https://imaginepediatrics.org/impression',
  LineOfBusiness = 'https://imaginepediatrics.org/line-of-business',
  LivingArrangement = 'https://imaginepediatrics.org/living-arrangement',
  ManualConsentUpload = 'https://imaginepediatrics.org/manual-consent-upload',
  MigrationSource = 'https://imaginepediatrics.org/migration',
  NotificationTopic = 'https://imaginepediatrics.org/notification-topic',
  OneTimePasscodeUsed = 'https://imaginepediatrics.org/one-time-passcode-used',
  OpeningEncounterCommunication = 'https://imaginepediatrics.org/opening-encounter-communication',
  Organization = 'https://imaginepediatrics.org/organization',
  OrganizationType = 'https://imaginepediatrics.org/organization-type',
  OriginalCarePathwayValue = 'https://imaginepediatrics.org/original-care-pathway-value',
  OutreachAttempt = 'https://imaginepediatrics.org/outreach-attempt',
  OutreachDisposition = 'https://imaginepediatrics.org/outreach-disposition',
  OutreachStatus = 'https://imaginepediatrics.org/outreach-status',
  OutreachStatusReason = 'https://imaginepediatrics.org/outreach-status-reason',
  OutreachTaskType = 'https://imaginepediatrics.org/outreach-task-type',
  PasswordResetRequired = 'https://imaginepediatrics.org/password-reset-required',
  Patient = 'https://imaginepediatrics.org/fhir/CodeSystem/patient',
  PatientList = 'https://imaginepediatrics.org/patient-list',
  PatientNoteCreation = 'https://imaginepediatrics.org/patient-note-creation',
  PatientType = 'https://imaginepediatrics.org/patient-type',
  PhoneStatus = 'https://imaginepediatrics.org/phone-status',
  Pod = 'https://imaginepediatrics.org/pod',
  Practitioner = 'https://imaginepediatrics.org/practitioner',
  PractitionerRole = 'https://imaginepediatrics.org/practitioner-role',
  PreferredLanguage = 'https://imaginepediatrics.org/preferred-language',
  PreferredPronoun = 'https://imaginepediatrics.org/preferred-pronoun',
  PrimaryCaregiver = 'https://imaginepediatrics.org/primary-caregiver',
  PrimaryPhone = 'https://imaginepediatrics.org/primary-phone',
  UsernameCredential = 'https://imaginepediatrics.org/username-credential',
  ProgramStatus = 'https://imaginepediatrics.org/program-status',
  QuestionnaireAnswerOption = 'https://imaginepediatrics.org/questionnaire-answer-option',
  QuestionnaireAnswerTally = 'https://imaginepediatrics.org/questionnaire-answer-tally',
  QuestionnaireAudience = 'https://imaginepediatrics.org/questionnaire-audience',
  QuestionnaireItemDisplayType = 'https://imaginepediatrics.org/questionnaire-item-display-type',
  QuestionnaireItemMaxTally = 'https://imaginepediatrics.org/questionnaire-item-max-tally',
  QuestionnaireLanguage = 'https://imaginepediatrics.org/questionnaire-language',
  QuestionnaireResponseCategory = 'https://imaginepediatrics.org/response-category',
  QuestionnaireResponseInReview = 'https://imaginepediatrics.org/questionnaire-response-in-review',
  QuestionnaireResponseScore = 'https://imaginepediatrics.org/response-score',
  QuestionnaireSucidalityPrompt = 'https://imaginepediatrics.org/seek-help-prompt',
  QuestionnaireType = 'https://imaginepediatrics.org/questionnaire-type',
  RelatedPerson = 'https://imaginepediatrics.org/related-person',
  ScriptExecution = 'https://imagine-pediatrics.org/script-execution',
  Subscriptions = 'https://imaginepediatrics.org/subscriptions',
  TaskApprovalReason = 'https://imaginepediatrics.org/task-approval-reason',
  TaskCancellationReasonNote = 'https://imaginepediatrics.org/task-cancellation-reason-note',
  TaskCategory = 'https://imaginepediatrics.org/task-category',
  TaskPriority = 'https://imaginepediatrics.org/task-priority',
  TaskType = 'https://imaginepediatrics.org/task-type',
  Test = 'https://imaginepediatrics.org/test',
  VideoMeetingId = 'https://imaginepediatrics.org/video-meeting-id',
  VideoProvider = 'https://imaginepediatrics.org/video-provider',
}
