import React, { useMemo } from 'react';
import { ActionIcon, Group, Loader, Stack, Stepper, ThemeIcon, Tooltip } from '@mantine/core';
import {
  OutreachStatus,
  OutreachStatusNotInterestedReason,
  OutreachStatusUnableToEnrollReason,
  ProgramStatus,
  outreachStatusMap,
  outreachStatusNotInterestedReasonMap,
  outreachStatusUnableToEnrollReasonMap,
  programStatusDisplay,
} from 'const-utils/codeSystems/ImaginePediatrics';
import { getProgramStatusFromEpisodeOfCareStatus, programStatusToEpisodeOfCareStatus } from 'imagine-dsl/utils/patient';
import { useDisclosure } from '@mantine/hooks';
import {
  IconAlertTriangle,
  IconCheck,
  IconCircleX,
  IconEditCircle,
  IconNumber2,
  IconNumber3,
} from '@tabler/icons-react';
import { OutreachStatusEditModal } from '../modals/OutreachStatusEditModal';
import { ProgramStatusEditModal } from '../modals/ProgramStatusEditModal';
import { Patient } from 'medplum-gql';
import { System } from 'const-utils';
import { useQuery } from '@tanstack/react-query';
import { useMedplum } from '@medplum/react';
import { PatientWithMeta } from 'imagine-dsl/utils/types/patient';

const allStatusKeys: ProgramStatus[] = [
  ProgramStatus.NotEnrolled,
  ProgramStatus.Enrolled,
  ProgramStatus.Onboarded,
  ProgramStatus.Disenrolled,
];

interface PatientPrimaryInformationProps {
  patient: PatientWithMeta;
}

export const PatientTimelineStepper = ({ patient }: PatientPrimaryInformationProps): JSX.Element => {
  const medplum = useMedplum();
  const [Opened, { close, open }] = useDisclosure(false);
  const result = useQuery([`episodeOfCare-${patient.id}`], async () => {
    return medplum.searchResources('EpisodeOfCare', `patient=Patient/${patient.id}`);
  });

  const episodeOfCare = useMemo(() => result.data?.at(0), [result.data]);

  if (result.isLoading) {
    return <Loader />;
  }

  const currentStatus = episodeOfCare?.status ?? '';
  const isDisenrolled = currentStatus === programStatusToEpisodeOfCareStatus[ProgramStatus.Disenrolled];
  const disenrollmentReason = episodeOfCare?.statusHistory
    ?.find((status) => status.status === programStatusToEpisodeOfCareStatus[ProgramStatus.Disenrolled])
    ?.extension?.find((ext) => ext.url === System.DisengagementReason.toString())?.valueCode;
  const outreachStatusReasonCode = episodeOfCare?.statusHistory
    ?.slice()
    .reverse()
    .find(
      (status) =>
        status.status === programStatusToEpisodeOfCareStatus[ProgramStatus.NotEnrolled] &&
        status.extension?.find((ext) => ext.url === System.OutreachStatus.toString()),
    )
    ?.extension?.find((ext) => ext.url === System.OutreachStatusReason.toString())?.valueCode;

  const currentProgramStatus = getProgramStatusFromEpisodeOfCareStatus(currentStatus) || ProgramStatus.NotEnrolled;
  const activeIndex = allStatusKeys.findIndex((status) => status.toString() === currentProgramStatus) + 1;
  const statusHistory = episodeOfCare?.statusHistory;
  const hasOnboarded = statusHistory?.some(
    (status) => status.status === programStatusToEpisodeOfCareStatus[ProgramStatus.Onboarded],
  );
  const hasEnrolled = statusHistory?.some(
    (status) => status.status === programStatusToEpisodeOfCareStatus[ProgramStatus.Enrolled],
  );

  const patientOutreachStatus = (patient?.meta?.tag?.find((tag) => tag.system === System.OutreachStatus)?.code ??
    OutreachStatus.NotYetOutreached) as OutreachStatus;
  const outreachStatus = outreachStatusMap[patientOutreachStatus];
  const outreachWarning = outreachStatus?.showWarning ?? false;
  let outreachStatusReason: { label: string } | undefined;

  if (patientOutreachStatus === OutreachStatus.UnableToEnroll && outreachStatusReasonCode) {
    outreachStatusReason =
      outreachStatusUnableToEnrollReasonMap[outreachStatusReasonCode as OutreachStatusUnableToEnrollReason];
  }
  if (patientOutreachStatus === OutreachStatus.NotInterested && outreachStatusReasonCode) {
    outreachStatusReason =
      outreachStatusNotInterestedReasonMap[outreachStatusReasonCode as OutreachStatusNotInterestedReason];
  }

  // Construct patientStatuses to always include the three statuses
  const patientStatuses = allStatusKeys
    .filter((key) => !(key === ProgramStatus.Disenrolled && !isDisenrolled))
    .map((key) => {
      const statusHistory = episodeOfCare?.statusHistory;
      const relevantStatus = statusHistory?.filter(
        (status) => getProgramStatusFromEpisodeOfCareStatus(status.status) === key,
      );

      const statusIsDisenrolled = key === ProgramStatus.Disenrolled;

      let description = '';
      if (relevantStatus && relevantStatus.length > 0) {
        const latestStatus = relevantStatus[relevantStatus.length - 1];
        const startDate = new Intl.DateTimeFormat('en-US').format(new Date(latestStatus.period.start ?? ''));

        // Check if the status comes after the current episodeOfCare.status
        const currentStatusIndex = allStatusKeys.findIndex(
          (status) => programStatusToEpisodeOfCareStatus[status] === episodeOfCare?.status,
        );
        const keyStatusIndex = allStatusKeys.findIndex((status) => status === key);

        if (keyStatusIndex <= currentStatusIndex) {
          const valueCode = latestStatus.extension?.find(
            (ext) => ext.url === System.DisengagementReason.toString(),
          )?.valueCode;
          description = `${startDate}${valueCode ? ` (${valueCode})` : ''}`;
        }
      }

      if (key === ProgramStatus.NotEnrolled && !hasEnrolled) {
        if (outreachStatus) {
          description = outreachStatus.label;
          if (outreachStatusReason) {
            description += ` \n${outreachStatusReason.label}`;
          }
        }
      }

      return {
        programStatus: key,
        label: programStatusDisplay[key] ?? '',
        isActive: key.toString() === currentProgramStatus,
        description: description?.length ? description : ' ', // the space helps keep the stepper labels aligned
        isDisenrolled: statusIsDisenrolled,
      };
    });

  const handleEditClick = (): void => open();

  const isNotEnrolled =
    episodeOfCare?.status === programStatusToEpisodeOfCareStatus[ProgramStatus.NotEnrolled] ||
    typeof episodeOfCare?.status === 'undefined'; // This is to handle edge case where EoC is not created for patient

  const shouldShowProgramStatusEditIcon = !isNotEnrolled;
  const shouldShowOutreachStatusEditIcon = isNotEnrolled;
  const shouldProgramStatusEditIconDisabled = isDisenrolled && disenrollmentReason === 'Deceased';

  return (
    <Group>
      {shouldShowProgramStatusEditIcon && (
        <ProgramStatusEditModal isOpen={Opened} onClose={close} episodeOfCare={episodeOfCare} patient={patient} />
      )}
      {shouldShowOutreachStatusEditIcon && (
        <OutreachStatusEditModal
          isOpen={Opened}
          onClose={close}
          patient={patient as Patient}
          episodeOfCare={episodeOfCare}
        />
      )}
      <Stepper
        w={patientStatuses.some((status) => status.isDisenrolled) || outreachWarning ? '60%' : '40%'}
        mt="xs"
        size="xs"
        iconSize={20}
        active={activeIndex}
        style={{ marginRight: '25px' }}
      >
        {patientStatuses.map((status, idx) => {
          let color = 'imagine-green';
          let completedIcon = <IconCheck name={`completed-step-${status.programStatus}`} size="1.1rem" />;
          if (status.isDisenrolled && isDisenrolled) {
            color = 'red';
            completedIcon = <IconCircleX size="1.1rem" />;
          } else if (status.programStatus === ProgramStatus.Onboarded && !hasOnboarded) {
            color = 'gray';
            completedIcon = <IconNumber3 size="0.8rem" />;
          } else if (status.programStatus === ProgramStatus.Enrolled && !hasEnrolled) {
            color = 'gray';
            completedIcon = <IconNumber2 size="0.8rem" />;
          } else if (status.programStatus === ProgramStatus.NotEnrolled && outreachWarning) {
            color = 'orange';
            completedIcon = <IconAlertTriangle size="0.8rem" />;
          }

          return (
            <Stepper.Step
              completedIcon={completedIcon}
              key={idx}
              label={status.label}
              description={
                <Stack gap={6}>
                  {status.description.split('\n').map((line, i) => (
                    <div key={i}>{line}</div>
                  ))}
                </Stack>
              }
              color={color}
            />
          );
        })}
      </Stepper>
      {shouldShowProgramStatusEditIcon ? (
        <ActionIcon onClick={() => handleEditClick()} disabled={shouldProgramStatusEditIconDisabled}>
          {shouldProgramStatusEditIconDisabled ? (
            <Tooltip label="Cannot edit a patient's status if the patient is deceased">
              <ThemeIcon color="imagine-green" variant="transparent">
                <IconEditCircle size={25} />
              </ThemeIcon>
            </Tooltip>
          ) : (
            <ThemeIcon color="imagine-green" variant="transparent">
              <IconEditCircle size={25} />
            </ThemeIcon>
          )}
        </ActionIcon>
      ) : (
        <></>
      )}
      {shouldShowOutreachStatusEditIcon ? (
        <ActionIcon onClick={() => handleEditClick()}>
          <ThemeIcon color="imagine-green" variant="transparent">
            <IconEditCircle size={25} />
          </ThemeIcon>
        </ActionIcon>
      ) : (
        <></>
      )}
    </Group>
  );
};
