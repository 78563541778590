import React, { useState } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { GetPatientQuery, Patient } from 'medplum-gql';
import { ThemeIcon, Tooltip } from '@mantine/core';
import { uploadCaregiverConsent, uploadPatientConsent } from 'imagine-dsl/services/consentService';
import { showNotification } from '@mantine/notifications';
import { AttachmentButton, useMedplum } from '@medplum/react';
import { Attachment, Patient as FHIRPatient } from '@medplum/fhirtypes';
import { createReference } from '@medplum/core';
import { IconCloudUpload } from '@tabler/icons-react';
import { ManualConsentUploadModal } from '../modals/ManualConsentUploadModal';

interface ConsentUploadProps {
  patient: Patient | FHIRPatient;
  isCaregiver?: boolean;
  refetch: () => Promise<ApolloQueryResult<GetPatientQuery>>;
}
export const ConsentUploadButton = ({ patient, isCaregiver = false, refetch }: ConsentUploadProps): JSX.Element => {
  const [pendingAttachment, setPendingAttachment] = useState<Attachment | undefined>(undefined);
  const [uploading, setUploading] = useState(false);
  const medplum = useMedplum();

  const onAttachDocument = async (value: Attachment | undefined): Promise<void> => {
    if (!value) {
      return;
    }
    setUploading(true);
    const patientReference = createReference(patient as FHIRPatient);
    try {
      if (isCaregiver) {
        await uploadCaregiverConsent(medplum, patientReference, value);
      } else {
        await uploadPatientConsent(medplum, patientReference, value);
      }

      setTimeout(async () => {
        await refetch();
        showNotification({
          color: 'status-success',
          title: 'Consent Upload',
          message: 'Document uploaded successfully',
        });
        setUploading(false);
      }, 1000);
    } catch (error: unknown) {
      showNotification({
        color: 'status-error',
        title: 'Error uploading document',
        message: (error as Error).message,
      });
      setPendingAttachment(undefined);
    }
  };
  return (
    <>
      <AttachmentButton
        onUpload={(attachment) => {
          if (attachment.contentType?.includes('application/pdf')) {
            setPendingAttachment(attachment);
          } else {
            showNotification({
              color: 'status-error',
              title: 'Error',
              message: 'Document must be a PDF',
            });
          }
        }}
        disabled={uploading}
      >
        {(props) => (
          <Tooltip label="Upload consent document">
            <ThemeIcon {...props} variant="transparent" style={{ cursor: 'pointer' }}>
              <IconCloudUpload size={20} />
            </ThemeIcon>
          </Tooltip>
        )}
      </AttachmentButton>

      {pendingAttachment && (
        <ManualConsentUploadModal
          modalTitle={'Upload Consent Document'}
          pendingAttachment={pendingAttachment}
          setPendingAttachment={setPendingAttachment}
          onAttachDocument={onAttachDocument}
        />
      )}
    </>
  );
};
