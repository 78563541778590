import {
  ActionIcon,
  AppShell,
  Text,
  Badge,
  Flex,
  Group,
  Skeleton,
  Stack,
  Title,
  Tooltip,
  ThemeIcon,
} from '@mantine/core';
import { ProfileResource } from '@medplum/core';
import { useMedplum } from '@medplum/react';
import {
  IconCheckupList,
  IconChevronsLeft,
  IconMessages,
  IconSearch,
  IconUsers,
  IconUser,
  IconAlertTriangle,
} from '@tabler/icons-react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { StyledNavLink } from './StyledNavLink';
import { useCommandCenterAlertAndCountContext } from '@/pages/CommandCenter/CommandCenterAlertProvider';
import { AppVersion } from './AppVersion';
import { CareHubLogo } from '../CareHubLogo';
import { PinnedPatientList } from './PinnedPatientList';
import { useMediaQuery } from '@mantine/hooks';
import { NotificationBubble } from './NotificationBubbles';
import { engagementRoles, techSupportRoles, useHasRole } from '@/hooks/useHasRole';
import { useTaskCountContext } from '@/pages/Task/TaskCountProvider';

export interface VerticalNavProps {
  open: boolean;
  toggle: () => void;
  profile?: ProfileResource;
}

export const VerticalNav = (props: VerticalNavProps): JSX.Element => {
  const medplum = useMedplum();

  const isUserManagementEnabled = useHasRole(techSupportRoles) || medplum.isProjectAdmin();
  const isEngagementAssociate = useHasRole(engagementRoles);

  const { tasksUnassignedCount: ccTasksUnassignedCount, tasksAssignedToMeCount: ccTasksAssignedToMeCount } =
    useCommandCenterAlertAndCountContext();
  const { tasksAssignedToMeCount, hasOverdueTasks } = useTaskCountContext();

  const fullHeight = useMediaQuery('(min-height: 40em)');

  const isAdmin = medplum.isProjectAdmin();

  return (
    <AppShell.Navbar>
      <Stack justify="space-between" h="100%">
        <Stack w="100%" align="center" gap={0} mah="45%">
          <Group w="100%" align="center" my="xl" px="lg" justify="space-between">
            <Group>
              <CareHubLogo size={48} />
              <Flex direction="column">
                <Title>Care Hub</Title>
                <AppVersion />
                <NavLink to="/release-notes">
                  <Text ta="center" fz={8} c="dimmed">
                    Release Notes
                  </Text>
                </NavLink>
              </Flex>
            </Group>
            <ActionIcon bg="white" size="sm" variant="outline" radius="xl" onClick={props.toggle}>
              <IconChevronsLeft width="70%" height="70%" stroke={1.5} />
            </ActionIcon>
          </Group>

          <StyledNavLink
            to="/"
            leftSection={<IconMessages width="30px" height="30px" />}
            label="Command Center"
            rightSection={
              <NotificationBubble unassignedCount={ccTasksUnassignedCount} myCount={ccTasksAssignedToMeCount} />
            }
          />
          <StyledNavLink
            to="/Patients"
            leftSection={<IconSearch width="30px" height="30px" />}
            label="Patient Search"
          />

          {(isEngagementAssociate || isAdmin) && (
            <StyledNavLink
              to="/PatientPanel"
              leftSection={<IconUsers width="30px" height="30px" />}
              label="Patient Panel"
            />
          )}
          {(!isEngagementAssociate || isAdmin) && (
            <StyledNavLink
              to="/MyPatients"
              leftSection={<IconUsers width="30px" height="30px" />}
              label="My Patients"
            />
          )}
          <StyledNavLink
            to="/Task"
            label="Tasks"
            leftSection={<IconCheckupList width="30px" height="30px" />}
            rightSection={
              <Group gap={4}>
                <Tooltip fw="normal" label="Assigned tasks">
                  <Badge bg="brand-yellow.3" c="black" variant="filled" size="xs">
                    {tasksAssignedToMeCount}
                  </Badge>
                </Tooltip>
                {hasOverdueTasks ? (
                  <Tooltip fw="normal" label="Some tasks are overdue">
                    <ThemeIcon size={20} c="status-warn">
                      <IconAlertTriangle color="status-warn" size={20} />
                    </ThemeIcon>
                  </Tooltip>
                ) : (
                  <Skeleton w={20} />
                )}
              </Group>
            }
          />
          {isUserManagementEnabled && (
            <StyledNavLink to="/Users" label="Users" leftSection={<IconUser width="30px" height="30px" />} />
          )}
          {fullHeight && <PinnedPatientList />}
        </Stack>
      </Stack>
    </AppShell.Navbar>
  );
};
