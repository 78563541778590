import React, { useState } from 'react';
import { ActionIcon, Menu, ThemeIcon } from '@mantine/core';
import { IconCircleMinus, IconDotsVertical, IconEditCircle } from '@tabler/icons-react';
import { useRevoke } from './useRevoke';

interface UserActionsProps {
  practitionerId: string;
  removeUser: () => void;
  editUser: () => void;
}

export const UserActions = (props: UserActionsProps) => {
  const [opened, setOpened] = useState(false);

  const revoke = useRevoke(props.practitionerId);

  const revokeAndRemove = async () => {
    await revoke();
    props.removeUser();
  };

  return (
    <Menu
      data-cy="user-actions-menu"
      opened={opened}
      onClose={() => setOpened(false)}
      width={250}
      position="bottom"
      withArrow
      shadow="md"
      radius="md"
    >
      <Menu.Target>
        <ActionIcon color="imagine-green">
          <IconDotsVertical size={22} onClick={() => setOpened(!opened)} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          leftSection={
            <ThemeIcon color="imagine-green" variant="transparent">
              <IconEditCircle size={20} />
            </ThemeIcon>
          }
          onClick={props.editUser}
        >
          Edit User
        </Menu.Item>
        <Menu.Item
          leftSection={
            <ThemeIcon color="imagine-green" variant="transparent">
              <IconCircleMinus size={20} />
            </ThemeIcon>
          }
          onClick={revokeAndRemove}
        >
          Revoke Access
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
