import React from 'react';
import { useVideo } from './video/VideoProvider';
import { DyteInterface } from './video/DyteInterface';
import { TwilioInterface } from './video/TwilioInterface';
import { DyteProvider } from '@dytesdk/react-web-core';
import { ErrorModal } from '@/design-system/ErrorModal';

export const VideoChat = (): JSX.Element | null => {
  const { activeTwilioCall, activeDyteCall, errorModalOpen, dyteMeeting, setErrorModalOpen } = useVideo();

  return (
    <>
      {errorModalOpen && (
        <ErrorModal
          title="Unable to load video call"
          bodyText="Please try again in a few minutes."
          opened={errorModalOpen}
          onClose={() => setErrorModalOpen(false)}
        />
      )}

      {/* renders Twilio UI based on return from join endpoint */}
      {activeTwilioCall && <TwilioInterface />}

      {/* renders Dyte UI based on return from join endpoint */}
      {activeDyteCall && (
        <DyteProvider value={dyteMeeting}>
          <DyteInterface />
        </DyteProvider>
      )}
    </>
  );
};
