import React, { useMemo } from 'react';
import { Calendar } from '@mantine/dates';
import { format, isAfter, isBefore, isEqual, isToday } from 'date-fns';
import { ActionIcon, Input, MantineStyleProps, Popover } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { useForceUpdate } from '@mantine/hooks';

type DateRangePickerProps = {
  setStartDate: (date?: Date | null) => void;
  setEndDate: (date?: Date | null) => void;
  startDate?: Date | null;
  endDate?: Date | null;
  clearable?: boolean;
  disabled?: boolean;
  placeholder?: string;
} & MantineStyleProps;

export const DateRangePicker = (props: DateRangePickerProps): JSX.Element => {
  const { setStartDate, setEndDate, startDate, endDate, clearable, disabled, placeholder } = props;

  const forceUpdate = useForceUpdate();

  const rangeValue = useMemo(() => {
    if (startDate && endDate) {
      return `${format(startDate, 'MM/dd/yyyy')} - ${format(endDate, 'MM/dd/yyyy')}`;
    }

    if (startDate) {
      return `${format(startDate, 'MM/dd/yyyy')} -`;
    }

    return '';
  }, [startDate, endDate]);

  const clear = () => {
    setStartDate(null);
    setEndDate(null);
    forceUpdate();
  };

  const ClearIcon = (): JSX.Element => {
    return (
      <ActionIcon onClick={clear}>
        <IconX size={16} />
      </ActionIcon>
    );
  };

  const inputRightSection = clearable ? <ClearIcon /> : undefined;
  return (
    <>
      <Popover>
        <Popover.Target>
          <Input
            disabled={disabled}
            rightSectionPointerEvents="all"
            rightSection={inputRightSection}
            w={props.w}
            placeholder={placeholder ?? 'Select date range'}
            value={rangeValue}
            readOnly
          />
        </Popover.Target>
        <Popover.Dropdown>
          <Calendar
            getDayProps={(date) => {
              const isStart = startDate?.toISOString().split('T')[0] === date.toISOString().split('T')[0];
              const isEnd = endDate?.toISOString().split('T')[0] === date.toISOString().split('T')[0];
              const isInRange = startDate && isAfter(date, startDate) && endDate && isBefore(date, endDate);
              const disabled =
                !(startDate && endDate) && startDate && !isEqual(date, startDate) && isBefore(date, startDate);

              const onClick = () => {
                if (!startDate || (startDate && endDate)) {
                  setStartDate(date);
                  setEndDate(undefined);
                }

                if (startDate && !endDate && isAfter(date, startDate)) {
                  setEndDate(date);
                }

                forceUpdate();
              };
              const highlightToday = isToday(date) && !(isStart || isEnd || isInRange);

              return {
                selected: isStart || isEnd,
                inRange: isInRange ?? false,
                disabled: disabled ?? false,
                onClick,
                bg: highlightToday ? 'blue.0' : '',
                fw: highlightToday ? 'bold' : '',
                weekend: false,
                style: { borderRadius: highlightToday ? '100%' : '' },
              };
            }}
          />
        </Popover.Dropdown>
      </Popover>
    </>
  );
};
