import React from 'react';
import { PatientProfile as RefactoredPatientProfilePage } from './refactoredNewPage';
import { PatientProfile as OldPatientProfilePage } from './oldPage';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';

export function PatientProfile(): JSX.Element {
  const flags = useFeatureFlags();
  // TODO: Delete OldPatientProfilePage and move code from RefactoredPatientProfilePage to here when change is deployed to PROD and we remove the feature flag
  return <>{flags.PatientProfileOpenTasks ? <RefactoredPatientProfilePage /> : <OldPatientProfilePage />}</>;
}
