import { Text, Checkbox, Paper, Select, Button, Stack } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import React from 'react';
import { useOutreach } from '../Context';
import { updateCaregiverSmsConsent, sendAppDownloadLink } from '../handlers/appDownload';
import { SuccessMessage } from '../SuccessMessage';
import { InAppPreview } from '../InAppPreview';
import { useApiClient } from '@/hooks/useApiClient';
import { getCaregiverPhoneNumbers, getPatientCaregiver, getPhoneNumberOptions } from '../util';
import appImage from '@assets/images/in-app-previews/app-download.png';

export const AppDownloadStep = (): JSX.Element => {
  const { wrapHandler, patient, appDownloadForm, setAppDownloadSentAt, appDownloadLinkSentAt, error } = useOutreach();
  const medplum = useMedplum();
  const apiClient = useApiClient();

  const caregiver = getPatientCaregiver(patient);
  const caregiverId = caregiver?.id;
  const phoneNumbers = getCaregiverPhoneNumbers(caregiver);
  const phoneNumberOptions = getPhoneNumberOptions(phoneNumbers);

  const handleSendAppDownloadLink = async (): Promise<void> =>
    wrapHandler(async () => {
      appDownloadForm.validate();

      if (!appDownloadForm.isValid()) {
        return;
      }

      await sendAppDownloadLink(apiClient, appDownloadForm.values.phoneNumber, caregiverId!);
      setAppDownloadSentAt(new Date());
    });

  const consentInputProps = appDownloadForm.getInputProps('consent', { type: 'checkbox' });

  const handleSmsConsentChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> =>
    wrapHandler(async () => {
      const prevValue = appDownloadForm.values.consent;
      consentInputProps.onChange(event);

      try {
        await updateCaregiverSmsConsent(medplum, caregiverId!, event.target.checked);
      } catch (error: unknown) {
        appDownloadForm.setFieldValue('consent', prevValue);
        throw error;
      }
    });

  const hasSmsConsent = appDownloadForm.values.consent;

  return (
    <form id="app-download-form">
      <InAppPreview imageUrl={appImage}>
        <Stack gap="md">
          <Paper withBorder p="md">
            <label>Consent to send text messages</label>
            <Checkbox
              {...consentInputProps}
              onChange={handleSmsConsentChange}
              required
              label="Caregiver has consented to receive text messsages"
              fw="bold"
            />
          </Paper>
          {hasSmsConsent && (
            <>
              <Select
                data-cy="phone-number-select"
                label="Select phone number"
                description={
                  <>
                    <strong>NOTE:</strong> This will be the phone number the caregiver uses to log in
                  </>
                }
                placeholder="Phone number"
                required
                {...appDownloadForm.getInputProps('phoneNumber')}
                data={phoneNumberOptions}
                style={{
                  maxWidth: '289px',
                }}
              />
              <Button
                name="send-app-download"
                disabled={!!appDownloadLinkSentAt}
                onClick={handleSendAppDownloadLink}
                variant="outline"
              >
                Send app download link
              </Button>
              {appDownloadLinkSentAt && (
                <SuccessMessage>App download link sent on {appDownloadLinkSentAt.toLocaleString()}</SuccessMessage>
              )}
            </>
          )}
          {error && <Text c="red">{error}</Text>}
        </Stack>
      </InAppPreview>
    </form>
  );
};
