import { ExtractResource, Resource, ResourceType } from '@medplum/fhirtypes';

/**
 * The keys of the managed fields of a resource plus resourceType
 * as that is always managed.
 * @param T - The managed fields type
 */
type ManagedKeys<T extends object> = keyof T | 'resourceType';
/**
 * Represents the paramaters that are required to build a resource
 * and omits the keys that are managed by the resource builder
 * @param T - The resource type
 * @param U - The managed fields type
 */
export type ResourceBuilderParams<T extends Resource, U extends object> = Omit<T, ManagedKeys<U>> & U;

export const isOfType = <U extends ResourceType>(
  resource: Resource | undefined,
  resourceType: U,
): resource is ExtractResource<U> => {
  return resource?.resourceType === resourceType;
};

export function isA<U extends ResourceType>(resourceType: U) {
  return (resource: Resource | undefined): resource is ExtractResource<U> => isOfType(resource, resourceType);
}
