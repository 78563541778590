import { MedplumClient, PatchOperation } from '@medplum/core';
import { Bundle, BundleEntry, CodeableConcept, Practitioner, Task } from '@medplum/fhirtypes';
import { System, TaskType } from 'const-utils';
import { buildOutreachTask } from '../utils/task';
import { taskTypeMap } from 'const-utils/codeSystems/ImaginePediatrics';

/**
 * Creates a task for a patient
 * @param options - the parameters to create a task
 * @param options.medplum - the medplum client to use
 * @param options.forPatientId - the id of the patient to create the task for
 * @param options.assignToPractitionerId - the id of the practitioner to assign the task to
 * @param options.requestedByPractitionerId - the id of the practitioner who requested the task
 * @param options.dueDate - the date the task is due
 * @param options.taskType  - the type of task
 * @param options.businessStatus - the status of the task
 * @param options.notes - the notes for the task
 * @param options.priority - the business priority of the task
 * @param options.id - the id of the task to update - if not provided it will create a new task
 * @param options.createdDate - the date the task was originally created
 * @param overridesFn - function to override final task object before create/update
 * @returns the created task
 */
export const upsertTask = async (
  {
    medplum,
    forPatientId,
    assignToPractitionerId,
    requestedByPractitionerId,
    dueDate,
    taskType,
    businessStatus,
    notes,
    priority = 'routine',
    id,
    createdDate,
  }: {
    medplum: MedplumClient;
    forPatientId: string;
    assignToPractitionerId: string;
    requestedByPractitionerId: string;
    dueDate: string;
    taskType: string;
    businessStatus: string;
    notes?: string;
    priority?: 'routine' | 'urgent' | 'asap' | 'stat' | undefined;
    id?: string;
    createdDate?: string;
  },
  overridesFn?: (t: Task) => Task,
): Promise<Task> => {
  //TODO: determine how we know which task type goes with what department, i.e. outreach
  const task = buildOutreachTask({
    forPatientId,
    assignToPractitionerId,
    requestedByPractitionerId,
    dueDate,
    taskType: { code: taskType, display: taskTypeMap[taskType as TaskType]?.display || taskType },
    businessStatus,
    notes,
    priority,
    id,
    createdDate,
  });
  const finalTask = overridesFn ? overridesFn(task) : task;

  const resource = task.id ? await medplum.updateResource(finalTask) : await medplum.createResource(finalTask);
  return resource;
};

/**
 * Creates tasks for a list of patient
 * @param options - the parameters to create a task
 * @param options.medplum - the medplum client to use
 * @param options.forPatientIds - the ids of the patient to create the task for
 * @param options.assignToPractitionerId - the id of the practitioner to assign the task to
 * @param options.requestedByPractitionerId - the id of the practitioner who requested the task
 * @param options.dueDate - the date the task is due
 * @param options.taskType  - the type of task
 * @param options.businessStatus - the status of the task
 * @param options.notes - the notes for the task
 * @param options.priority - the business priority of the task
 * @param options.createdDate - the date the task was originally created
 * @returns the created task
 */
export const bulkTaskCreation = async ({
  medplum,
  forPatientIds,
  assignToPractitionerId,
  requestedByPractitionerId,
  dueDate,
  taskType,
  businessStatus,
  notes,
  priority = 'routine',
  createdDate,
}: {
  medplum: MedplumClient;
  forPatientIds: string[];
  assignToPractitionerId: string;
  requestedByPractitionerId: string;
  dueDate: string;
  taskType: string;
  businessStatus: string;
  notes?: string;
  priority?: 'routine' | 'urgent' | 'asap' | 'stat' | undefined;
  createdDate?: string;
}): Promise<Bundle> => {
  const tasks = forPatientIds.map((forPatientId) =>
    buildOutreachTask({
      forPatientId,
      assignToPractitionerId,
      requestedByPractitionerId,
      dueDate,
      taskType: { code: taskType, display: taskTypeMap[taskType as TaskType]?.display || taskType },
      businessStatus,
      notes,
      priority,
      createdDate,
      bulkTask: true,
    }),
  );
  const taskRequests: BundleEntry[] = tasks.map((task) => ({
    request: {
      method: task.id ? 'PUT' : 'POST',
      url: task.id ? `Task/${task.id}` : 'Task',
    },
    resource: task,
  }));

  const creations: Bundle = {
    resourceType: 'Bundle',
    type: 'transaction',
    entry: taskRequests,
  };

  const response = await medplum.executeBatch(creations);
  return response;
};

/**
 *
 * @param medplum - the medplum client to use
 * @param practitionerId - the id of the practitioner to assign task to
 * @param taskId - the id of the task to assign
 * @param taskMarket - the market that the task is included in. Used to ensure the practitioner has access to the market
 */
export const assignTask = async (
  medplum: MedplumClient,
  practitionerId: string,
  taskId: string,
  taskMarket: string,
): Promise<void> => {
  const searchCriteria = `practitioner=Practitioner/${practitionerId}`;

  const practitionerRoles = await medplum.search('PractitionerRole', searchCriteria);

  const marketsForPractitioner = practitionerRoles.entry?.map(
    (role) => role.resource?.organization?.reference?.split('/')[1],
  );

  if (!marketsForPractitioner?.includes(taskMarket)) {
    throw new Error('Practitioner does not have access to the market for this alert.');
  }

  await medplum.patchResource('Task', taskId, [
    {
      op: 'add',
      path: '/owner',
      value: {
        reference: `Practitioner/${practitionerId}`,
      },
    },
    {
      op: 'replace',
      path: '/status',
      value: 'in-progress',
    },
    {
      op: 'add',
      path: '/lastModified',
      value: new Date().toISOString(),
    },
  ]);
};

/**
 *
 * @param medplum - the medplum client to use
 * @param taskId - the id of the task to resolve
 * @param encounterId - the id of the encounter to finish
 * @param reason - the codeable concept reason for resolving the task
 * @param defaultOwner - the default owner is the profile of the practitioner who is resolving the task
 */
// TODO: Switch to a batch transaction when it's available IP-905
export const resolveTask = async (
  medplum: MedplumClient,
  taskId: string,
  encounterId?: string,
  reason?: CodeableConcept,
  defaultOwner?: Practitioner,
): Promise<void> => {
  const taskPatchOperations: PatchOperation[] = [
    {
      op: 'add',
      path: '/status',
      value: 'completed',
    },
    {
      op: 'add',
      path: '/executionPeriod/end',
      value: new Date().toISOString(),
    },
    {
      op: 'add',
      path: '/lastModified',
      value: new Date().toISOString(),
    },
  ];
  if (reason) {
    taskPatchOperations.push({
      op: 'add',
      path: '/statusReason',
      value: reason,
    });
  }

  if (defaultOwner?.id) {
    taskPatchOperations.push({
      op: 'add',
      path: '/owner',
      value: {
        reference: `Practitioner/${defaultOwner.id}`,
      },
    });
  }

  await Promise.all([
    medplum.patchResource('Task', taskId, taskPatchOperations),
    encounterId &&
      medplum.patchResource('Encounter', encounterId, [
        {
          op: 'add',
          path: '/status',
          value: 'finished',
        },
      ]),
  ]);
};

/**
 *
 * @param medplum - the medplum client to use
 * @param taskId - the id of the task to resolve
 * @param reason - cancellation reason
 */
export const cancelTask = async (medplum: MedplumClient, taskId: string, reason: CodeableConcept): Promise<void> => {
  const currentTask = await medplum.readResource('Task', taskId);
  const executionPeriod = currentTask?.executionPeriod ?? {};

  await Promise.all([
    medplum.patchResource('Task', taskId, [
      {
        op: 'add',
        path: '/status',
        value: 'cancelled',
      },
      {
        op: 'add',
        path: '/executionPeriod',
        value: {
          ...executionPeriod,
          end: new Date().toISOString(),
        },
      },
      {
        op: 'add',
        path: '/lastModified',
        value: new Date().toISOString(),
      },
      {
        op: 'add',
        path: '/statusReason',
        value: reason,
      },
    ]),
  ]);
};

/**
 *
 * @param medplum - the medplum client to use
 * @param taskId - the id of the task to reopen
 * @param encounterId - the id of the encounter to reopen
 *
 */
// TODO: Switch to a batch transaction when it's available IP-905
export const reopenTask = async (medplum: MedplumClient, taskId: string, encounterId: string): Promise<void> => {
  await Promise.all([
    medplum.patchResource('Task', taskId, [
      {
        op: 'add',
        path: '/status',
        value: 'in-progress',
      },
      {
        op: 'add',
        path: '/lastModified',
        value: new Date().toISOString(),
      },
    ]),
    medplum.patchResource('Encounter', encounterId, [
      {
        op: 'add',
        path: '/status',
        value: 'in-progress',
      },
    ]),
  ]);
};

export const taskAssignmentHistory = async (medplum: MedplumClient, taskId: string): Promise<BundleEntry<Task>[]> => {
  const historyBundle = await medplum.readHistory('Task', taskId, { cache: 'no-cache' });
  if (!historyBundle?.entry) {
    return [];
  }

  return historyBundle.entry.reduce<BundleEntry<Task>[]>((assignmentHistory, history, index) => {
    const currOwner = history.resource?.owner;
    // Skip the last entry because that's the creation of the task no assignment history
    if (index === historyBundle.entry!.length - 1) {
      return assignmentHistory;
    }

    // The previous owner is the one in the list after the current
    const prevOwner = historyBundle.entry?.[index + 1]?.resource?.owner;
    if (prevOwner?.reference !== currOwner?.reference) {
      return [...assignmentHistory, history];
    } else {
      return assignmentHistory;
    }
  }, []);
};

/**
 *
 * @param medplum - the medplum client to use
 * @param taskId - the id of the task to get the previous status of
 * @param currentVersionId - the version id of the current task
 * @param currentStatus - the current status of the task
 * @returns the previous status of the task
 */
export const previousTaskStatus = async (
  medplum: MedplumClient,
  taskId: string,
  currentVersionId: string,
  currentStatus: Task['status'],
): Promise<string> => {
  const historyBundle = await medplum.readHistory('Task', taskId, { cache: 'no-cache' });
  if (!historyBundle?.entry) {
    throw new Error(`No history found for task: ${taskId}`);
  }

  const indexOfCurrentVersion = historyBundle.entry?.findIndex((entry) => {
    return entry.resource?.meta?.versionId === currentVersionId;
  });
  if (indexOfCurrentVersion === -1) {
    throw new Error(`Index of current history entry not found for task: ${taskId}`);
  }

  const histories = historyBundle.entry.slice(indexOfCurrentVersion);

  const statusChangeEntry = histories.find((entry) => entry.resource?.status !== currentStatus);
  if (!statusChangeEntry) {
    return currentStatus;
  }

  const previousStatus = statusChangeEntry.resource?.status;
  if (!previousStatus) {
    throw new Error(`No previous status found for task: ${taskId}`);
  }

  return previousStatus;
};

export const isSuicideAlertTask = (task: Task): boolean => {
  const suicideAlertTag = task.meta?.tag?.find(
    (tag) => tag.system === System.TaskType && tag.code === TaskType.SuicideRisk,
  );

  if (suicideAlertTag) {
    return true;
  }
  return false;
};
