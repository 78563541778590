import React, { ReactNode, useState } from 'react';
import { Combobox, TextInput, useCombobox, ComboboxItem } from '@mantine/core';
import { Maybe } from 'medplum-gql';

interface SelectWithSearchProps {
  label: string;
  assign: (practitionerId: string) => void;
  search: (value: Maybe<string>) => void;
  listOptions: ComboboxItem[] | undefined;
  fieldValidation?: () => void;
  fieldError?: ReactNode;
  formValue: string;
  defaultValue?: string;
  disabled?: boolean;
}
/**
 * SelectWithSearch component
 * @param options - object with the following properties:
 * @param options.label - label for the input
 * @param options.assign - function to assign the value to the form field
 * @param options.search - function to search for the value
 * @param options.listOptions - list of options to display
 * @param options.fieldValidation - function to validate the form field on the parent component
 * @param options.fieldError - error message to display that comes from the form field
 * @param options.formValue - value of the form field, used to compare local value to ensure they stay in sync
 * @param options.defaultValue - default value for the input to display upon first load
 * @param options.disabled - boolean to disable the input
 * @returns - a combobox with a text input that allows the user to search for a value in a list of provided options
 */
export function SelectWithSearch({
  label,
  assign,
  search,
  listOptions,
  fieldValidation = () => {},
  fieldError,
  formValue,
  defaultValue,
  disabled,
}: SelectWithSearchProps) {
  const combobox = useCombobox();
  const [value, setValue] = useState(defaultValue ?? '');
  const shouldFilterOptions = !listOptions?.some((item: ComboboxItem) => item.value === value);
  const filteredOptions = shouldFilterOptions
    ? listOptions?.filter((item) => item.label.toLowerCase().includes(value.toLowerCase().trim()))
    : listOptions;

  const options =
    filteredOptions?.map((item) => (
      <Combobox.Option
        value={item.value}
        key={item.value}
        onClick={async () => {
          setValue(item.label);
          assign(item.value);
          combobox.closeDropdown();
        }}
      >
        {item.label}
      </Combobox.Option>
    )) ?? [];

  return (
    <Combobox disabled={disabled} store={combobox} onClose={() => fieldValidation()}>
      <Combobox.Target>
        <TextInput
          required
          disabled={disabled}
          label={label}
          placeholder="Pick value or type to search"
          value={value}
          onChange={(event) => {
            setValue(event.currentTarget.value);
            search(event.currentTarget.value);
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={async () => {
            if (value === '' && formValue !== '') {
              assign('');
            }
            if (options.length === 0) {
              setValue('');
            }
            fieldValidation();
            combobox.closeDropdown();
          }}
          error={fieldError}
        />
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Options>
          {options?.length === 0 ? <Combobox.Empty>Input or Modify Search Criteria</Combobox.Empty> : options}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
