import React from 'react';
import { Modal, Button, CloseButton, Group, Flex } from '@mantine/core';
import { IconExclamationCircle } from '@tabler/icons-react';

interface ErrorModalProps {
  title: string;
  bodyText: string;
  opened: boolean;
  onClose: () => void;
}

export const ErrorModal = ({ title, bodyText, opened, onClose }: ErrorModalProps): React.JSX.Element => {
  return (
    <Modal withCloseButton={false} centered size={'lg'} padding={'10px'} opened={opened} onClose={onClose}>
      <Group justify="space-between">
        <Group>
          <IconExclamationCircle color="red" />
          <Modal.Title>{title}</Modal.Title>
        </Group>
        <CloseButton onClick={onClose} />
      </Group>
      <Modal.Body p="0">
        <p>{bodyText}</p>
      </Modal.Body>
      <Flex justify={'end'}>
        <Button onClick={onClose}>Close</Button>
      </Flex>
    </Modal>
  );
};
