import { useMedplumProfile, useSubscription } from '@medplum/react';
import { CommunicationMedium, SubscriptionsExtensions } from 'const-utils';
import { ApolloQueryResult } from '@apollo/client';
import { Notification } from 'imagine-dsl/models/communications/notification';
import { isOfType } from 'imagine-dsl/utils/resource';
import { compact } from 'lodash';
import {
  CountNotificationsForPractitionerQuery,
  Maybe,
  NotificationsForPractitionerQuery,
  useCountNotificationsForPractitionerQuery,
  useNotificationsForPractitionerQuery,
} from 'medplum-gql';
import React, { PropsWithChildren, useContext, useMemo, useState } from 'react';
import { Communication } from '@medplum/fhirtypes';
import { useLocation } from 'react-router-dom';
import { usePagination } from '@/design-system/hooks/usePagination';

interface NotificationsProviderProps {}

interface NotificationsContext {
  notifications: Notification[];
  notificationsCount: number;
  filteredNotifications: Notification[];
  allNotificationsLoading: boolean;
  filteredNotificationsLoading?: boolean;
  unreadCount?: Maybe<number>;
  updateUnreadOnly: (unreadOnly: boolean) => void;
  unreadOnly: boolean;
  refetchNotifications: () => Promise<ApolloQueryResult<NotificationsForPractitionerQuery>>;
  refetchUnreadCount: () => Promise<ApolloQueryResult<CountNotificationsForPractitionerQuery>>;
  refetch: () => Promise<void>;
}

export const PER_PAGE = 50;
const NotificationsContextValue = React.createContext<NotificationsContext | undefined>(undefined);

export const useNotificationContext = (): NotificationsContext => {
  const context = useContext(NotificationsContextValue);
  if (!context) {
    throw new Error('useNotificationContext must be used within a NotificationsProvider');
  }
  return context;
};

export const NotificationsProvider = ({ children }: PropsWithChildren<NotificationsProviderProps>): JSX.Element => {
  const profile = useMedplumProfile();
  const [unreadOnly, setUnreadOnly] = useState<boolean>(false);
  const location = useLocation();
  const { currentPage, changePage } = usePagination({});
  const offset = (currentPage - 1) * PER_PAGE;

  const {
    data,
    loading: allNotificationsLoading,
    refetch: refetchNotifications,
  } = useNotificationsForPractitionerQuery({
    variables: { practitionerRef: `Practitioner/${profile!.id}` },
    skip: !profile?.id,
    pollInterval: 30_000,
  });

  const filter = unreadOnly ? 'received pr ne true' : undefined;

  const {
    data: filteredNotificationsData,
    loading: filteredNotificationsLoading,
    refetch: refetchFilteredNotifications,
  } = useNotificationsForPractitionerQuery({
    variables: { filter, count: PER_PAGE, offset, practitionerRef: `Practitioner/${profile!.id}` },
    skip: !profile?.id || location.pathname !== '/notifications',
  });

  const { data: unreadCountData, refetch: refetchUnreadCount } = useCountNotificationsForPractitionerQuery({
    variables: { practitionerRef: `Practitioner/${profile!.id}` },
    skip: !profile?.id,
    pollInterval: 30_000,
  });

  const communicationSubOptions = {
    subscriptionProps: {
      extension: [SubscriptionsExtensions.InteractionFilter('create')],
    },
  };

  const refetchAll = async (): Promise<void> => {
    await refetchNotifications();
    await refetchUnreadCount();
    await refetchFilteredNotifications();
  };

  useSubscription(
    `Communication?medium=${CommunicationMedium.Notification}&recipient=Practitioner/${profile?.id}`,
    async (communicationBundle) => {
      const communicationEntry = communicationBundle.entry?.find((e) => e.resource?.resourceType === 'Communication');
      if (!communicationEntry) {
        return;
      }

      const communication = isOfType(communicationEntry.resource, 'Communication')
        ? (communicationEntry.resource as Communication)
        : undefined;

      if (!communication) {
        return;
      }

      await refetchAll();
    },
    communicationSubOptions,
  );

  const notifications = useMemo(() => {
    return compact(data?.CommunicationConnection?.edges?.map((e) => e?.resource)).map(
      (communication) => new Notification(communication),
    );
  }, [data]);

  const filteredNotifications = useMemo(() => {
    const notifications = compact(
      filteredNotificationsData?.CommunicationConnection?.edges?.map((e) => e?.resource),
    ).map((communication) => new Notification(communication));
    if (unreadOnly) {
      return notifications.filter((notification) => !notification.received);
    }
    return notifications;
  }, [unreadOnly, filteredNotificationsData]);

  const updateUnreadOnly = (unreadOnly: boolean): void => {
    setUnreadOnly(unreadOnly);
    changePage(1);
  };

  return (
    <NotificationsContextValue.Provider
      value={{
        allNotificationsLoading,
        filteredNotifications,
        filteredNotificationsLoading,
        notifications,
        notificationsCount: filteredNotificationsData?.CommunicationConnection?.count || 0,
        unreadCount: unreadCountData?.CommunicationConnection?.count,
        unreadOnly,
        refetchNotifications,
        refetchUnreadCount,
        updateUnreadOnly,
        refetch: refetchAll,
      }}
    >
      {children}
    </NotificationsContextValue.Provider>
  );
};
