import React, { useMemo } from 'react';
import { Table, Text, LoadingOverlay, Group, Stack } from '@mantine/core';
import { Patient, Practitioner } from '@medplum/fhirtypes';
import { HumanNameDisplay } from '@medplum/react';
import { TasksDrawer } from './drawer';
import { formatAge } from 'imagine-dsl/utils/strings';
import { useTaskAlertContext } from '@/pages/Task/TaskAlertProvider';
import { format } from 'date-fns';
import { ClusterBadge } from '../ClusterBadge';
import { useGetCarePathway } from '@/hooks/useGetCarePathway';
import { EMPTY_DATA_PLACEHOLDER } from '@/constants';
import { IconCheck, IconPointFilled, IconX } from '@tabler/icons-react';
import { BaseTask } from 'imagine-dsl/models/tasks/baseTask';
import { Pagination } from '@/design-system/Pagination';
import { useGetTaskTitleRenderers } from '@/hooks/useGetTaskTitleRenderers';
import { UnassignedBadge } from '../shared/UnassignedBadge';
import { TaskType } from 'const-utils';
import { useSearchParams } from 'react-router-dom';
import { useGetTaskQuery } from 'medplum-gql';
import { useTaskDrawerHandle } from './useTaskDrawer';

const getCarePathwayStatusDetails = (status: string) => {
  if (status === 'accepted') {
    return {
      text: 'Approved',
      icon: <IconCheck size={14} color="gray" />,
    };
  } else if (status === 'rejected') {
    return {
      text: 'Denied',
      icon: <IconX size={14} color="gray" />,
    };
  } else if (status === 'cancelled') {
    return {
      text: 'Cancelled',
      icon: <IconX size={14} color="gray" />,
    };
  } else {
    return {
      text: status,
      icon: null,
    };
  }
};

const renderResolvedBy = (assignedPractitioner: Practitioner | undefined, systemResolvedBy: string | false) => {
  if (!assignedPractitioner && !systemResolvedBy) {
    return <UnassignedBadge />;
  }
  if (systemResolvedBy) {
    return <Text>{systemResolvedBy}</Text>;
  }
  return <HumanNameDisplay value={assignedPractitioner?.name?.[0]} />;
};

const TaskRow = ({
  task,
  openTaskDrawer,
}: {
  task: BaseTask;
  openTaskDrawer: (taskId: string) => void;
}): JSX.Element => {
  const patient = task?.for as Patient;
  const assignedPractitioner = task?.owner as Practitioner;
  const taskTitleColumnRenderers = useGetTaskTitleRenderers();
  const TaskTitle = taskTitleColumnRenderers[task.type!] ?? (() => <Text>{task.type}</Text>);
  const { carePathway, cluster } = useGetCarePathway({ patientId: patient?.id || '' });
  const systemResolvedBy =
    task.type === TaskType.CarePathwayReferralReview && task.carePathwayReferralType === 'data' && 'Care Hub System';

  return (
    <Table.Tr onClick={() => openTaskDrawer(task.id!)} key={task?.id} style={{ cursor: 'pointer' }}>
      <Table.Td>
        <Text size="lg">
          <HumanNameDisplay value={patient?.name?.[0]} />
        </Text>
        {patient.birthDate && (
          <Group gap={'2'}>
            <Text c="gray">{patient.managingOrganization?.resource?.name ?? EMPTY_DATA_PLACEHOLDER}</Text>
            <IconPointFilled size={14} color="gray" />
            <Text c="gray">
              {formatAge(patient.birthDate, { emptyText: EMPTY_DATA_PLACEHOLDER, abbreviate: true })}
            </Text>
            <IconPointFilled size={14} color="gray" />
            <ClusterBadge
              carePathway={carePathway}
              cluster={cluster || 'Unknown'}
              iconOnly
              hideSubtext
              condensedDisplay
            />
          </Group>
        )}
      </Table.Td>
      <Table.Td>
        <TaskTitle task={task} />
      </Table.Td>
      <Table.Td>
        <Stack gap={0}>
          {renderResolvedBy(assignedPractitioner, systemResolvedBy)}
          {task.type === TaskType.CarePathwayReferralReview && (
            <Group gap="xs">
              {getCarePathwayStatusDetails(task.status).icon}
              <Text c="gray">{getCarePathwayStatusDetails(task.status).text}</Text>
            </Group>
          )}
        </Stack>
      </Table.Td>
      <Table.Td>{task.lastModified ? format(task.lastModified, 'Pp') : EMPTY_DATA_PLACEHOLDER}</Table.Td>
    </Table.Tr>
  );
};

const PAGE_SIZE = 10;
export const ResolvedTasksTable = (): JSX.Element => {
  const { loading, error, refetchTasks, tasks, totalTasks } = useTaskAlertContext();
  const [searchParams] = useSearchParams();
  const { openDrawer, closeDrawer } = useTaskDrawerHandle();
  const focusedTaskId = useMemo(() => {
    return searchParams.get('task');
  }, [searchParams]);

  const foundTask = useMemo(() => {
    if (!tasks || !focusedTaskId) {
      return undefined;
    }

    return tasks.find((task) => task?.id === focusedTaskId);
  }, [tasks, focusedTaskId]);

  const { data } = useGetTaskQuery({
    variables: { taskId: focusedTaskId! },
    skip: !focusedTaskId && !foundTask,
  });

  const focusedTask = useMemo(() => {
    if (foundTask) {
      return foundTask;
    }

    return data?.Task ? new BaseTask(data.Task) : undefined;
  }, [data, foundTask]);

  if (error) {
    return (
      <Text size="xl" fw={700} c="imagine-green" mt="lg" ml="lg">
        Error fetching tasks
      </Text>
    );
  }

  return (
    <>
      <TasksDrawer
        withViewPatientProfile
        task={focusedTask}
        onClose={closeDrawer}
        opened={!!focusedTask}
        refetchTasks={[refetchTasks]}
      />
      <Table highlightOnHover horizontalSpacing="sm" verticalSpacing="sm">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Patient</Table.Th>
            <Table.Th>Task</Table.Th>
            <Table.Th>Resolved by</Table.Th>
            <Table.Th>Resolved date</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {loading ? (
            <tr>
              <td colSpan={3}>
                <LoadingOverlay visible zIndex={1000} overlayProps={{ radius: 'sm', blur: 2 }} />
              </td>
            </tr>
          ) : (
            tasks
              ?.filter((task) => !!task)
              .map((task) => <TaskRow key={task?.id} task={task} openTaskDrawer={openDrawer} />)
          )}
          {tasks?.length === 0 && (
            <Table.Tr>
              <Table.Td colSpan={3}>
                <Text size="xl" fw={700} c="imagine-green" mt="lg" ml="lg">
                  No tasks found.
                </Text>
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
      <Pagination perPage={PAGE_SIZE} count={totalTasks} />
    </>
  );
};
