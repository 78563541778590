import { formatHumanName, parseReference } from '@medplum/core';
import { HumanName, Reference } from '@medplum/fhirtypes';
import {
  AlertTaskFragment,
  BaseTaskInfoFragment,
  GetPatientActivityQuery,
  SurveyReviewTaskFragment,
} from 'medplum-gql';
import { isDefined } from 'imagine-dsl/utils/lists';
import { System, TaskType } from 'const-utils';

export enum ActivityType {
  Touchpoint = 'Touchpoint',
  Video = 'Video',
  Alert = 'Alert',
  All = 'All',
  ADT = 'ADT',
  Task = 'Task',
}

export interface VideoCall {
  encounterId: string;
  status: string;
  practitioners: {
    name?: string;
    role?: string;
  }[];
  caregivers: {
    name?: string;
    relationship?: string;
  }[];
  callTime?: Date;
  duration: {
    start?: Date;
    end?: Date;
  };
}
export function mapFromGraphqlVideoCall(data: GetPatientActivityQuery): VideoCall[] {
  const mapped =
    data.Patient?.videoCalls
      ?.map((videoData) => {
        if (!videoData?.id) {
          return null;
        }

        const practitioners = (videoData.participant || [])
          .map((p) => p.individual?.resource)
          .filter(isDefined)
          .map((participant) => {
            if (participant.__typename === 'Practitioner') {
              const name = participant.name ? formatHumanName(participant.name?.[0] as HumanName) : undefined;
              const role = participant.PractitionerRoleList?.[0]?.code?.[0]?.coding?.[0]?.display ?? undefined;
              return {
                name,
                role,
              };
            }
            return undefined;
          })
          .filter(isDefined);

        const caregivers = (videoData.participant || [])
          .map((p) => p.individual?.resource)
          .filter(isDefined)
          .map((participant) => {
            if (participant.__typename === 'RelatedPerson') {
              const caregiver = participant.PatientList?.[0];
              const name = caregiver?.name?.[0] ? formatHumanName(caregiver.name[0] as HumanName) : undefined;
              const relationship = participant.relationship?.[0]?.coding?.[0]?.display ?? undefined;
              return {
                name,
                relationship,
              };
            }
            return undefined;
          })
          .filter(isDefined);

        const videoCall: VideoCall = {
          encounterId: videoData.id,
          status: videoData.status ?? 'unknown',
          practitioners,
          caregivers,
          callTime: videoData?.period?.start ? new Date(videoData.period.start) : undefined,
          duration: {
            start: videoData?.period?.start ? new Date(videoData.period.start) : undefined,
            end: videoData?.period?.end ? new Date(videoData.period.end) : undefined,
          },
        };

        return videoCall;
      })
      .filter(isDefined) ?? [];

  return mapped;
}

export interface TaskActivity {
  id: string;
  status: string;
  priority: string;
  date?: Date;
  owner?: {
    name?: string;
    role?: string;
  };
  type: TaskType;
  author?: string;
  task?: AlertTaskFragment | SurveyReviewTaskFragment | BaseTaskInfoFragment;
}

const mapFromGraphqlTask =
  (extractKey: 'alerts' | 'tasks') =>
  (data: GetPatientActivityQuery): TaskActivity[] => {
    const mapped =
      data.Patient?.[extractKey]
        ?.map((task) => {
          if (!task?.id) {
            return null;
          }

          let owner: { name?: string; role?: string } | undefined;
          if (task.owner?.resource?.__typename === 'Practitioner') {
            const name = task.owner.resource.name
              ? formatHumanName(task.owner.resource.name?.[0] as HumanName)
              : undefined;
            const role = task.owner.resource.PractitionerRoleList?.[0]?.code?.[0]?.coding?.[0]?.display ?? undefined;
            owner = {
              name,
              role,
            };
          }

          const alertDate = task.executionPeriod?.end ?? task.meta?.lastUpdated;

          const authorRef = task.meta?.author?.reference ? task.meta.author : undefined;

          const type = task.meta?.tag?.find((tag) => tag.system === System.TaskType)?.code as TaskType;

          const alert: TaskActivity = {
            id: task.id,
            status: task.status ?? 'unknown',
            priority: task.priority ?? 'unknown',
            date: alertDate ? new Date(alertDate) : undefined,
            owner,
            type,
            author:
              authorRef && parseReference(authorRef as Reference)?.at(0) === 'Practitioner'
                ? (task.meta?.author?.display ?? undefined)
                : undefined,

            task: { ...task, resourceType: 'Task' },
          };

          return alert;
        })
        .filter(isDefined) ?? [];

    return mapped;
  };

export interface ADTEncounterActivity {
  id: string;
  status: string;
  start?: string;
  end?: string;
  description: string;
  diagnosis?: string;
  location?: string;
}
export function mapFromGraphqlADT(data: GetPatientActivityQuery): ADTEncounterActivity[] {
  const mapped =
    data.Patient?.adtEncounters
      ?.map((encounter) => {
        if (!encounter?.id) {
          return null;
        }
        const healthCategory = encounter.meta?.tag?.find((tag) => tag.system === System.HealthcareCategory);
        const encounterClass = encounter.class.display;
        let description = '';
        if (healthCategory) {
          description += healthCategory.display;
        }
        if (encounterClass) {
          description += description ? ` - ${encounterClass}` : encounterClass;
        }
        const reason = encounter.reasonCode?.[0];
        const adtEncounter: ADTEncounterActivity = {
          id: encounter.id,
          status: encounter.status ?? 'unknown',
          start: encounter.period?.start ?? '',
          end: encounter.period?.end ?? '',
          description,
          diagnosis: reason?.coding?.[0].display ?? '-',
          location: encounter.location?.at(0)?.location.display ?? '',
        };
        return adtEncounter;
      })
      .filter(isDefined) ?? [];
  return mapped;
}

export const mapFromGraphqlAlerts = mapFromGraphqlTask('alerts');
// TODO:: Use this mapping once we have designs for the screen tasks for the activity feed.
export const mapFromGraphqlTasks = mapFromGraphqlTask('tasks');
