import { useUserSession } from '@/components/shared/UserSessionContext';
import { getErrorMessage, logError } from '@/errors';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { Button, Group, Modal, Radio, RadioGroup, Select, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { createReference, formatHumanName } from '@medplum/core';
import { Patient, Questionnaire, Task } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';
import { System, TaskType } from 'const-utils';
import { PreferredLanguage, QuestionnaireType } from 'const-utils/codeSystems/ImaginePediatrics';
import { SurveyTask } from 'imagine-dsl/models/tasks/surveyTask';
import { getPatientFirstLanguagePreferenceCode } from 'imagine-dsl/utils/patient';
import { buildQuestionnaireTask } from 'imagine-dsl/utils/questionnaire';
import { compact } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

interface SendSurveyModalProps {
  opened: boolean;
  onClose: () => void;
  surveyTasks: SurveyTask[];
  caregivers: Patient[];
  patient: Patient;
  refetchTask: () => Promise<void>;
}

export const SendSurveyModal = ({
  caregivers,
  opened,
  onClose,
  surveyTasks,
  patient,
  refetchTask,
}: SendSurveyModalProps): JSX.Element => {
  const flags = useFeatureFlags();
  const { profile } = useUserSession();
  const medplum = useMedplum();
  const screenerInProgress = surveyTasks.some((survey) => !survey.completed && survey.type === TaskType.Screener);
  const bhSurveyInProgress = surveyTasks.some((survey) => !survey.completed && survey.type === TaskType.BHSurvey);
  const disasterSurveyInProgress = surveyTasks.some(
    (survey) => !survey.completed && survey.type === TaskType.DisasterPreparednessSurvey,
  );
  const caregiverOptions = useMemo(() => {
    return caregivers.map((caregiver) => ({
      value: caregiver.id!,
      label: caregiver.name?.[0] ? formatHumanName(caregiver.name?.[0]) : 'Unknown',
    }));
  }, [caregivers]);
  const [questionnaires, setQuestionnaires] = useState<Questionnaire[]>();
  const [sendingSurvey, setSendingSurvey] = useState(false);

  const initialValues: { caregiverId: string | undefined; selectedSurvey: TaskType | undefined } = {
    caregiverId: caregiverOptions[0]?.value,
    selectedSurvey: undefined,
  };

  const form = useForm({
    initialValues,
    validate: {
      caregiverId: (value) => (value ? undefined : 'Caregiver is required'),
      selectedSurvey: (value) => (value ? undefined : 'Survey is required'),
    },
  });

  useEffect(() => {
    if (!form.values.selectedSurvey) {
      return;
    }

    const patientLanguage = getPatientFirstLanguagePreferenceCode(patient.communication || []);
    const language =
      patientLanguage === PreferredLanguage.En.toString() || patientLanguage === PreferredLanguage.Es.toString()
        ? patientLanguage
        : PreferredLanguage.En;

    if (form.values.selectedSurvey === TaskType.Screener) {
      medplum
        .searchOne('Questionnaire', `_tag=${form.values.selectedSurvey}&_tag=${language}`)
        .then((data) => setQuestionnaires(data ? [data] : []))
        .catch((error) => logError(error));
    }

    if (form.values.selectedSurvey === TaskType.BHSurvey) {
      medplum
        .search('Questionnaire', `_tag=${form.values.selectedSurvey}&_tag=${language}`)
        .then((data) => setQuestionnaires(compact(data.entry?.map((entry) => entry.resource)) || []))
        .catch((error) => logError(error));
    }

    if (form.values.selectedSurvey === TaskType.DisasterPreparednessSurvey) {
      medplum
        .search('Questionnaire', `_tag=${form.values.selectedSurvey}`) //note this survey has translated values and does not require language preference
        .then((data) => {
          setQuestionnaires(compact(data.entry?.map((entry) => entry.resource)) || []);
        })
        .catch((error) => logError(error));
    }
  }, [medplum, patient.communication, form.values.selectedSurvey]);

  const submitHandler = () => {
    setSendingSurvey(true);
    const assignee = caregivers.find((caregiver) => caregiver.id === form.values.caregiverId);
    if (!assignee) {
      notifications.show({
        color: 'status-error',
        title: 'Error',
        message: 'There was an error assigning the survey to the selected caregiver',
      });

      setSendingSurvey(false);
      return;
    }

    if (questionnaires?.length === 0) {
      notifications.show({
        color: 'status-error',
        title: 'Error',
        message: 'There was an error sending the survey',
      });

      setSendingSurvey(false);
      return;
    }
    if (!form.isValid()) {
      setSendingSurvey(false);
      return;
    }

    const surveyAssignmentPromises: Promise<Task>[] = [];

    questionnaires?.forEach((questionnaire) => {
      const taskObj = buildQuestionnaireTask({
        questionnaireReference: createReference(questionnaire),
        patientReference: createReference(patient),
        caregiverReference: createReference(assignee),
        requesterReference: createReference(profile),
        taskType: form.values.selectedSurvey!,
      });

      if (form.values.selectedSurvey === TaskType.BHSurvey) {
        const bhType = questionnaire.meta?.tag?.find((t) => t.system === System.BHSurveyType)?.code;
        taskObj.meta?.tag?.push({ system: System.BHSurveyType, code: bhType });
      }

      surveyAssignmentPromises.push(medplum.createResource(taskObj));
    });
    Promise.all(surveyAssignmentPromises)
      .then(async () => {
        await refetchTask();
        form.reset();
        onClose();
        setSendingSurvey(false);
      })
      .catch((error) => {
        logError(error);
        notifications.show({ color: 'status-error', title: 'Error', message: getErrorMessage(error) });
        setSendingSurvey(false);
      });
  };

  return (
    <Modal.Root opened={opened} onClose={onClose}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Send Survey</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={form.onSubmit(submitHandler)}>
            <Stack>
              <RadioGroup name="selectedSurvey" {...form.getInputProps('selectedSurvey')}>
                <Radio
                  label="PRAPARE"
                  value={QuestionnaireType.Screener}
                  disabled={screenerInProgress}
                  description={screenerInProgress ? 'Screener survey already in progress' : undefined}
                />
                <Radio
                  mt={12}
                  label="BH Surveys"
                  value={QuestionnaireType.BHSurvey}
                  disabled={bhSurveyInProgress}
                  description={bhSurveyInProgress ? 'BH surveys already in progress' : undefined}
                />
                {(flags.DisasterPreparednessSurvey || medplum.isProjectAdmin()) && (
                  <Radio
                    mt={12}
                    label="Disaster Preparedness Surveys"
                    value={QuestionnaireType.DisasterPreparedness}
                    disabled={disasterSurveyInProgress}
                    description={
                      disasterSurveyInProgress ? 'Disaster preparedness survey already in progress' : undefined
                    }
                  />
                )}
              </RadioGroup>
              <Select
                name="caregiverId"
                mb={8}
                data={caregiverOptions}
                placeholder="Select caregiver"
                allowDeselect={false}
                {...form.getInputProps('caregiverId')}
              />
            </Stack>
            <Group justify="flex-end" ml="auto">
              <Button loading={sendingSurvey} disabled={questionnaires?.length === 0} type="submit">
                Send
              </Button>
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
            </Group>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
