import { Alert, Button, ComboboxItem, Group, Modal, Select, Stack, TextInput, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect, useState } from 'react';
import { TaskPriorityCodes, useBulkTaskCreation } from './useBulkTaskCreation';
import { Maybe, SearchPractitionersQueryVariables, useSearchPractitionersQuery } from 'medplum-gql';
import { formatHumanName } from '@medplum/core';
import { HumanName } from '@medplum/fhirtypes';
import { useUserSession } from './UserSessionContext';
import { SelectWithSearch } from './SelectWithSearch';
import { IconInfoCircle } from '@tabler/icons-react';
import { showNotification } from '@mantine/notifications';
import { manuallyCreatableTaskTypeOptions } from 'const-utils/codeSystems/ImaginePediatrics';
import { engagementManagerRoles, useHasRole } from '@/hooks/useHasRole';
import { medplum } from '@/utils/session';

interface BulkCreationTaskModalProps {
  patientIds: string[];
  opened: boolean;
  closeModal: () => Promise<void>;
  onCreationSuccess?: () => void;
}

export interface TaskFormValues {
  taskType: string;
  assignedTo: string;
  assignedToName?: string;
  dueDate?: string;
  dueTime: string;
  priority: string;
  notes: string;
  id?: string;
  requestor?: string;
  createdDate?: string;
}

const BulkTaskCreationModal = ({
  patientIds,
  opened,
  closeModal,
  onCreationSuccess,
}: BulkCreationTaskModalProps): JSX.Element => {
  const { profile } = useUserSession();
  const hasElevatedPrivilege = useHasRole(engagementManagerRoles) || medplum.isProjectAdmin();
  const form = useForm<TaskFormValues>({
    initialValues: {
      taskType: '',
      assignedTo: hasElevatedPrivilege ? '' : profile.id!,
      dueTime: '23:59',
      dueDate: undefined,
      notes: '',
      priority: '',
      id: undefined,
      requestor: profile.id,
      createdDate: new Date().toISOString(),
    },
    validate: {
      taskType: (value) => !value && 'Task Type is required',
      assignedTo: (value) => !value && 'Assignee is required',
      dueDate: (value) => !value && 'Due Date is required',
      priority: (value) => !value && 'Priority is required',
    },
    validateInputOnBlur: true,
  });

  const [searchParams, setSearchParams] = useState<Maybe<SearchPractitionersQueryVariables>>();
  const { data } = useSearchPractitionersQuery({
    skip: !searchParams,
    variables: searchParams || {},
  });
  const [practitionerOptions, setPractitionerOptions] = useState<ComboboxItem[]>();
  useEffect(() => {
    if (data) {
      setPractitionerOptions(
        data.PractitionerList?.filter((p) => p?.name?.[0].family && p?.name?.[0].given).map((practitioner) => {
          return {
            label: formatHumanName(practitioner?.name?.[0] as HumanName),
            value: practitioner?.id ?? '',
          };
        }),
      );
    }
  }, [data]);

  const onSearch = (name: Maybe<string>): void => {
    if (!name) {
      setSearchParams({});
    } else {
      setSearchParams({ name });
    }
  };
  const onAssignToTeamMember = (practitionerId: string): void => {
    form.setFieldValue('assignedTo', practitionerId);
    form.validateField('assignedTo');
  };
  const [saveTask, loading] = useBulkTaskCreation(patientIds, form.values);

  const close = async (): Promise<void> => {
    await closeModal();
  };

  const handleSave = async (): Promise<void> => {
    if (form.isValid()) {
      const result = await saveTask();
      if (result) {
        form.reset();
        await close();
        onCreationSuccess?.();
        showNotification({ message: `${patientIds.length} Tasks created successfully`, color: 'status-success' });
      }
    } else {
      form.validate();
    }
  };

  return (
    <Modal.Root size="lg" padding="xl" radius="xl" opened={opened} onClose={close}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title c="imagine-green" style={{ fontSize: '20px' }}>
            Create Task
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Stack gap="md">
            <Alert variant="light" color="blue" icon={<IconInfoCircle />}>
              <b>Please note:</b> This same task will be created for <b>{patientIds.length} patients</b>
            </Alert>
            <Select
              label="Task type"
              placeholder="Please select"
              required
              data={manuallyCreatableTaskTypeOptions}
              {...form.getInputProps('taskType')}
            />
            {hasElevatedPrivilege ? (
              <SelectWithSearch
                label="Assign to"
                assign={onAssignToTeamMember}
                search={onSearch}
                listOptions={practitionerOptions}
                fieldValidation={() => form.validateField('assignedTo')}
                fieldError={form.errors.assignedTo}
                formValue={form.values.assignedTo}
              />
            ) : (
              // If the user does not have the elevated privilege, the task will be assigned to their user
              <TextInput label="Assign to" value={profile.name && formatHumanName(profile.name[0])} disabled />
            )}

            <Group grow>
              <TextInput required label="Due Date" type="date" {...form.getInputProps('dueDate')} />
              <TextInput required label="Due Time" type="time" {...form.getInputProps('dueTime')} />
            </Group>
            <Select
              label="Select priority"
              required
              data={TaskPriorityCodes.map((p) => ({
                label: p.display ?? p.code,
                value: p.code,
              }))}
              {...form.getInputProps('priority')}
            />
            <Textarea label="Notes" {...form.getInputProps('notes')} autosize minRows={4} maxRows={6} />
            <Group justify="flex-end" mt={'sm'}>
              <Button variant="outline" onClick={close}>
                Cancel
              </Button>
              <Button onClick={handleSave} loading={loading}>
                Create Tasks
              </Button>
            </Group>
          </Stack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

export default BulkTaskCreationModal;
