export enum ConsentType {
  CaregiverConsent = 'caregiver-consent',
  PatientConsent = 'patient-consent',
  ConsentToTreatAndTelehealth = 'Consent to Treat and Telehealth',
  ReleaseOfInformation = 'Release of information consent',
  Eula = 'End User License Agreement',
}

export enum ConsentIdentifier {
  ConsentToTreat = 'https://imaginepediatrics.org/consentToTreat',
  EULA = 'https://imaginepediatrics.org/eula',
  ReleaseOfInformation = 'https://imaginepediatrics.org/releaseOfInformation',
}

export enum LegacyConsentIdentifier {
  'consent-to-treat' = 'https://imaginepediatrics.org/consentToTreat',
  'release-of-information' = 'https://imaginepediatrics.org/releaseOfInformation',
  'end-user-license-agreement' = 'https://imaginepediatrics.org/eula',
}

export enum ConsentQuestionnaireLinkId {
  ConsentAccept = 'consent-accept',
}
export enum ConsentCode {
  'consent-to-treat' = 'Consent to Treat and Telehealth',
  'release-of-information' = 'Release of information consent',
  'end-user-license-agreement' = 'End User License Agreement',
}
