import { CodeSystemConcept } from '@medplum/fhirtypes';
import { CommunicationCategory, communicationMediumMap } from './Communications';
import { ContactType } from './Contacts';
import { organizationTypeMap } from './Organizations';
import { callDispositionMap, CallRoute, CommunicationNoteType, outreachModalityMap } from './Outreach';
import {
  GenderIdentity,
  PreferredPronoun,
  PreferredLanguage,
  LivingSituation,
  AddressType,
  PhoneStatus,
} from './Patient';
import { Attribution, DisEngagementReason, ProgramStatus } from './ProgramStatus';
import { taskTypeMap, TaskPriority, bhTOCTaskResolveReasons } from './Tasks';
import { PatientType } from '../../tagging';
import { Feature } from './Features';

export const enum Concepts {
  AddressType = 'address-type',
  Attribution = 'attribution',
  BHTOCTaskResolveReason = 'bh-toc-task-resolve-reason',
  CarePathwayReferralReason = 'care-pathway-referral-reason',
  CallDisposition = 'call-disposition',
  CommunicationCategory = 'communication-category',
  CommunicationMedium = 'communication-medium',
  CommunicationNoteType = 'communication-note-type',
  ContactType = 'contact-type',
  DisengagementReason = 'disengagement-reason',
  Feature = 'feature',
  GenderIdentity = 'gender-identity',
  LivingSituation = 'living-situation',
  OrganizationType = 'organization-type',
  OutreachModality = 'outreach-modality',
  PatientType = 'patient-type',
  PhoneStatus = 'phone-status',
  PreferredLanguage = 'preferred-language',
  PreferredPronoun = 'preferred-pronoun',
  ProgramStatus = 'program-status',
  TaskPriority = 'task-priority',
  TaskType = 'task-type',
}

// TODO: continue to create display objects / maps for enums to enable type-safe exhaustiveness
// in Imagine Pediatrics CodeSystem generation as well as to make lookups for display purposes easier and more performant

// Require exhaustive listing of concepts, enabling dynamic generation of Imagine Pediatrics CodeSystem
type CodeSystemConceptsMap = {
  [K in Concepts]: CodeSystemConcept & { code: K };
};

export const codeSystemConcepts: CodeSystemConceptsMap = {
  [Concepts.AddressType]: {
    code: Concepts.AddressType,
    display: 'Address Type',
    definition: 'Address Type',
    concept: [
      {
        code: AddressType.Home,
        display: 'Home',
        definition: 'Home address',
      },
      {
        code: AddressType.Mailing,
        display: 'Mailing',
        definition: 'Mailing address',
      },
      {
        code: AddressType.Visit,
        display: 'Visit',
        definition: 'Visit address',
      },
    ],
  },
  [Concepts.Attribution]: {
    code: Concepts.Attribution,
    display: 'Attribution',
    definition: 'Attribution',
    concept: [
      {
        code: Attribution.Attributed,
        display: 'Attributed',
        definition: 'Eligible for the program',
      },
      {
        code: Attribution.NotAttributed,
        display: 'Not attributed',
        definition: 'Ineligible for the program',
      },
    ],
  },
  [Concepts.BHTOCTaskResolveReason]: {
    code: Concepts.BHTOCTaskResolveReason,
    display: 'BH TOC Task Resolve Reason',
    definition: 'BH TOC Task Resolve Reason',
    concept: Object.entries(bhTOCTaskResolveReasons).map(([code, display]) => ({
      code,
      display,
    })),
  },
  [Concepts.CarePathwayReferralReason]: {
    code: Concepts.CarePathwayReferralReason,
    display: 'Care Pathway Referral Reason',
    definition: 'Care pathway referral reason',
    concept: [
      {
        code: 'MMCNCC',
        display:
          'Medical model conditions that have not been captured in claims (ie: malignancy, tracheostomy, ventilator dependence, gastrotomy tube, premature neonate)',
      },
      {
        code: 'SMIDNCC',
        display:
          'Behavioral (SMI) diagnoses that have not been captured in claims (ie: bipolar disorder, personality disorder, gender dysphoria, schizophrenia, suicide ideation, major depressive disorder)',
      },
      {
        code: 'HU', // HISTUT
        display:
          'Historical utilization (ie: 0 PCP visits in the past 12mo, 1 inpatient visit in the past 12mo, 3 ED visits in the past 12mo)',
      },
      {
        code: 'IDHLAI',
        display:
          'Indwelling devices with high likelihood of admissions for infection (ie: VP shunt, central line/port, HD or PD catheter, urologic devices/surgeries (urostomy, ilial conduit))',
      },
      {
        code: 'SCMCHLA',
        display:
          'Significant chronic medical conditions with high likelihood of admission (ie: pulmonary HTN, chronic lung disease on O2 or nighttime support, sleep apnea, bronchopulmonary dysplasia, need for non-invasive ventilation support)',
      },
      {
        code: 'CMCHLERV',
        display:
          'Chronic medical conditions with high likelihood of ER visit (ie: seizure disorders WITH > 2 ED visits or IP admissions per year for seizures)',
      },
      {
        code: 'MM', // MULTMED
        display: 'Multiple medications (ie: > 4 chronic medications excluding seasonal allergy medications)',
      },
      {
        code: 'SBHDHLC',
        display:
          'Significant behavioral health diagnoses with high likelihood of complexity (ie: multiple mental health conditions that are not the 6 SMI conditions above OR one of the following mental health conditions causing significant impairment on daily functioning such as: Adjustment Disorder with depressed, anxious, or combined presentation, Reactive Attachment Disorder, Attention Deficit Hyperactivity Disorder with inattentive, hyperactive/impulsive, combined presentation, Conduct Disorder, Oppositional Defiant Disorder, Obsessive Compulsive Disorder, Generalized Anxiety Disorder, Post Traumatic Stress Disorder, or Adverse Childhood Experiences (ACEs), including foster care placement)',
      },
      {
        code: 'PISAB',
        display:
          "Patient is a sibling of another patient in the care pathway (ie: Patient's sibling is in medical model)",
      },
    ],
  },
  [Concepts.CallDisposition]: {
    code: Concepts.CallDisposition,
    display: 'Call Disposition',
    definition: 'Call disposition',
    concept: Object.entries(callDispositionMap).map(([code, { display, definition }]) => ({
      code,
      display,
      definition: definition ?? display,
    })),
  },
  [Concepts.CommunicationCategory]: {
    code: Concepts.CommunicationCategory,
    display: 'CommunicationCategory',
    definition: 'Communication category',
    concept: [
      {
        code: CommunicationCategory.Outreach,
        display: 'Outreach',
        definition: 'This communication is an outreach attempt.',
      },
      {
        code: CommunicationCategory.Test,
        display: 'Test',
        definition: 'This communication is a test message used for verifying a patient during onboarding.',
      },
      {
        code: CommunicationCategory.Welcome,
        display: 'Welcome',
        definition: 'This communication is a welcome message to a new caregiver after signing EULA.',
      },
    ],
  },
  [Concepts.CommunicationMedium]: {
    code: Concepts.CommunicationMedium,
    display: 'Communication Medium',
    definition: 'Communication medium',
    concept: Object.entries(communicationMediumMap).map(([code, display]) => ({
      code,
      display,
    })),
  },
  [Concepts.CommunicationNoteType]: {
    code: Concepts.CommunicationNoteType,
    display: 'CommunicationNoteType',
    definition: 'Communication note type',
    concept: [
      {
        code: CommunicationNoteType.CallbackTime,
        display: 'Callback time',
        definition: 'Callback time',
      },
      {
        code: CommunicationNoteType.CallbackStart,
        display: 'Callback start time',
        definition: 'Callback start time',
      },
      {
        code: CommunicationNoteType.CallbackEnd,
        display: 'Callback end time',
        definition: 'Callback end time',
      },
      {
        code: CommunicationNoteType.CallbackTimezone,
        display: 'Callback timezone',
        definition: 'Callback timezone',
      },
      {
        code: CommunicationNoteType.CallRoute,
        display: 'Call route',
        definition: 'Call route',
        concept: [
          {
            code: CallRoute.Incoming,
            display: 'Incoming',
            definition: 'Incoming call',
          },
          {
            code: CallRoute.Outgoing,
            display: 'Outgoing',
            definition: 'Outgoing call',
          },
        ],
      },
      {
        code: CommunicationNoteType.ContactName,
        display: 'Contact name',
        definition: 'Contact name',
      },
      {
        code: CommunicationNoteType.Notes,
        display: 'Notes',
        definition: 'Notes',
      },
    ],
  },
  [Concepts.ContactType]: {
    code: Concepts.ContactType,
    display: 'Contact Type',
    definition: 'The type of a contact, such as emergency contact, legal guardian, etc.',
    concept: [
      {
        code: ContactType.DurablePOA,
        display: 'Durable POA',
        definition: 'Durable Power of Attorney',
      },
      {
        code: ContactType.EmergencyContact,
        display: 'Emergency Contact',
        definition: 'Emergency Contact',
      },
      {
        code: ContactType.HealthCareProxy,
        display: 'Health Care Proxy',
        definition: 'Health Care Proxy',
      },
      {
        code: ContactType.HealthCarePOA,
        display: 'Health Care POA',
        definition: 'Health Care Power of Attorney',
      },
      {
        code: ContactType.LegalGuardian,
        display: 'Legal Guardian',
        definition: 'Legal Guardian',
      },
      {
        code: ContactType.PatientDesignatedRepresentative,
        display: 'Patient Designated Representative',
        definition: 'Patient Designated Representative',
      },
      {
        code: ContactType.Self,
        display: 'Self',
        definition: 'Self',
      },
      {
        code: ContactType.Other,
        display: 'Other/Unknown',
        definition: 'Other or Unknown Contact Type',
      },
    ],
  },
  [Concepts.DisengagementReason]: {
    code: Concepts.DisengagementReason,
    display: 'Disengagement Reason',
    definition: 'Disengagement Reason',
    concept: [
      {
        code: DisEngagementReason.OptOut,
        display: 'Opt Out',
        definition: 'The individual has opted out of the program or service.',
      },
      {
        code: DisEngagementReason.InappropriateConduct,
        display: 'Inappropriate Conduct',
        definition: 'The individual is ineligible due to inappropriate conduct.',
      },
      {
        code: DisEngagementReason.NoLongerQualifies,
        display: 'No Longer With Health Plan',
        definition: 'The individual no longer qualifies for the program or service.',
      },
      {
        code: DisEngagementReason.Deceased,
        display: 'Deceased',
        definition: 'The individual is deceased.',
      },
      {
        code: DisEngagementReason.AgedOut,
        display: 'Aged Out',
        definition: 'The individual has aged out of the program or service eligibility.',
      },
    ],
  },
  [Concepts.Feature]: {
    code: Concepts.Feature,
    display: 'Feature',
    definition: 'Feature',
    concept: [
      {
        code: Feature.AcuteCareOnly,
        display: 'Acute Care Only',
        definition: 'Determines if an organization provides Acute Care Only',
      },
      {
        code: Feature.PRAPARE,
        display: 'PRAPARE Survey',
        definition: 'PRAPARE Survey sent to caregivers for a household',
      },
    ],
  },
  [Concepts.GenderIdentity]: {
    code: Concepts.GenderIdentity,
    display: 'Gender Identity',
    definition: 'Gender identity',
    concept: [
      {
        code: GenderIdentity.Male,
        display: 'male',
        definition: 'the patient identifies as male',
      },
      {
        code: GenderIdentity.Female,
        display: 'female',
        definition: 'the patient identifies as female',
      },
      {
        code: GenderIdentity.NonBinary,
        display: 'non-binary',
        definition: 'the patient identifies as female',
      },
      {
        code: GenderIdentity.TransgenderMale,
        display: 'transgender-male',
        definition: 'the patient identifies with neither/both female and male',
      },
      {
        code: GenderIdentity.TransgenderFemale,
        display: 'transgender-female',
        definition: 'the patient identifies as transgender female-to-male',
      },
      {
        code: GenderIdentity.Other,
        display: 'other',
        definition: 'other gender identity',
      },
      {
        code: GenderIdentity.NonDisclose,
        display: 'does not wish to disclose',
        definition: 'the patient does not wish to disclose his gender identity',
      },
    ],
  },
  [Concepts.LivingSituation]: {
    code: Concepts.LivingSituation,
    display: 'Living Situation',
    definition: 'Living Situation',
    concept: [
      {
        code: LivingSituation.HL,
        display: 'Homeless',
        definition: 'Living arrangements lacking a permanent residence.',
      },
      {
        code: LivingSituation.M,
        display: 'Nomadic',
        definition: 'Nomadic',
      },
      {
        code: LivingSituation.T,
        display: 'Transient',
        definition: 'Transient',
      },
      {
        code: LivingSituation.I,
        display: 'Institution',
        definition: 'Institution',
      },
      {
        code: LivingSituation.CS,
        display: 'Community shelter',
        definition:
          'A group living arrangement specifically for the care of those in need of temporary and crisis housing assistance. Examples include domestic violence shelters, shelters for displaced or homeless individuals, Salvation Army, Jesus House, etc. Community-based services may be provided.',
      },
      {
        code: LivingSituation.G,
        display: 'Group Home',
        definition: 'Group Home',
      },
      {
        code: LivingSituation.N,
        display: 'Nursing Home',
        definition: 'Nursing Home/LTC',
      },
      {
        code: LivingSituation.X,
        display: 'Extended care facility',
        definition: 'Extended care facility',
      },
      {
        code: LivingSituation.PR,
        display: 'Private residence',
        definition: 'A living arrangement within a private residence for single family.',
      },
      {
        code: LivingSituation.H,
        display: 'Independent Household',
        definition: 'Independent Household',
      },
      {
        code: LivingSituation.R,
        display: 'Retirement Community',
        definition: 'Retirement Community',
      },
      {
        code: LivingSituation.SL,
        display: 'Supported living',
        definition:
          'Assisted living in a single family residence for persons with physical, behavioral, or functional health, or socio-economic challenges. There may or may not be on-site supervision but the housing is designed to assist the client with developing independent living skills. Community-based services may be provided.',
      },
      {
        code: LivingSituation.IL,
        display: 'Independent Living',
        definition: 'Independent Living',
      },
    ],
  },
  [Concepts.OrganizationType]: {
    code: Concepts.OrganizationType,
    display: 'Organization Type',
    definition: 'Organization type',
    concept: Object.entries(organizationTypeMap).map(([code, display]) => ({
      code,
      display,
    })),
  },
  [Concepts.OutreachModality]: {
    code: Concepts.OutreachModality,
    display: 'Outreach Modality',
    definition: 'Outreach modality',
    concept: Object.entries(outreachModalityMap).map(([code, { display }]) => ({
      code,
      display,
    })),
  },
  [Concepts.PatientType]: {
    code: Concepts.PatientType,
    display: 'Patient Type',
    definition: 'Patient type',
    concept: [
      {
        code: PatientType.Patient,
        display: 'Patient',
        definition: 'A patient who is, has, or can be been enrolled in the program',
      },
      {
        code: PatientType.Caregiver,
        display: 'Caregiver',
        definition: 'A caregiver of a patient',
      },
    ],
  },
  [Concepts.PhoneStatus]: {
    code: Concepts.PhoneStatus,
    display: 'Invalid Phone Status',
    definition: 'Invalid Phone Status',
    concept: [
      {
        code: PhoneStatus.WrongNumber,
        display: 'Wrong Number',
        definition: 'Wrong Number',
      },
      {
        code: PhoneStatus.Invalid,
        display: 'Invalid',
        definition: 'Invalid',
      },
      {
        code: PhoneStatus.DoNotCall,
        display: 'Do Not Call',
        definition: 'Do Not Call',
      },
    ],
  },
  [Concepts.PreferredLanguage]: {
    code: Concepts.PreferredLanguage,
    display: 'Preferred Language',
    definition: 'Preferred Language',
    concept: [
      {
        code: PreferredLanguage.Ar,
        display: 'Arabic',
        definition: 'Arabic',
      },
      {
        code: PreferredLanguage.Asl,
        display: 'ASL - American Sign Language',
        definition: 'American Sign Language',
      },
      {
        code: PreferredLanguage.Bn,
        display: 'Bengali',
        definition: 'Bengali',
      },
      {
        code: PreferredLanguage.Cs,
        display: 'Czech',
        definition: 'Czech',
      },
      {
        code: PreferredLanguage.Da,
        display: 'Danish',
        definition: 'Danish',
      },
      {
        code: PreferredLanguage.De,
        display: 'German',
        definition: 'German',
      },
      {
        code: PreferredLanguage.DeAT,
        display: 'German (Austria)',
        definition: 'German (Austria)',
      },
      {
        code: PreferredLanguage.DeCH,
        display: 'German (Switzerland)',
        definition: 'German (Switzerland)',
      },
      {
        code: PreferredLanguage.DeDE,
        display: 'German (Germany)',
        definition: 'German (Germany)',
      },
      {
        code: PreferredLanguage.El,
        display: 'Greek',
        definition: 'Greek',
      },
      {
        code: PreferredLanguage.En,
        display: 'English',
        definition: 'English',
      },
      {
        code: PreferredLanguage.EnAU,
        display: 'English (Australia)',
        definition: 'English (Australia)',
      },
      {
        code: PreferredLanguage.EnCA,
        display: 'English (Canada)',
        definition: 'English (Canada)',
      },
      {
        code: PreferredLanguage.EnGB,
        display: 'English (Great Britain)',
        definition: 'English (Great Britain)',
      },
      {
        code: PreferredLanguage.EnIN,
        display: 'English (India)',
        definition: 'English (India)',
      },
      {
        code: PreferredLanguage.EnNZ,
        display: 'English (New Zeland)',
        definition: 'English (New Zeland)',
      },
      {
        code: PreferredLanguage.EnSG,
        display: 'English (Singapore)',
        definition: 'English (Singapore)',
      },
      {
        code: PreferredLanguage.EnUS,
        display: 'English (United States)',
        definition: 'English (United States)',
      },
      {
        code: PreferredLanguage.Es,
        display: 'Spanish',
        definition: 'Spanish',
      },
      {
        code: PreferredLanguage.EsAR,
        display: 'Spanish (Argentina)',
        definition: 'Spanish (Argentina)',
      },
      {
        code: PreferredLanguage.EsES,
        display: 'Spanish (Spain)',
        definition: 'Spanish (Spain)',
      },
      {
        code: PreferredLanguage.EsUY,
        display: 'Spanish (Uruguay)',
        definition: 'Spanish (Uruguay)',
      },
      {
        code: PreferredLanguage.Fi,
        display: 'Finnish',
        definition: 'Finnish',
      },
      {
        code: PreferredLanguage.Fr,
        display: 'French',
        definition: 'French',
      },
      {
        code: PreferredLanguage.FrBE,
        display: 'French (Belgium)',
        definition: 'French (Belgium)',
      },
      {
        code: PreferredLanguage.FrCH,
        display: 'French (Switzerland)',
        definition: 'French (Switzerland)',
      },
      {
        code: PreferredLanguage.FrFR,
        display: 'French (France)',
        definition: 'French (France)',
      },
      {
        code: PreferredLanguage.Fy,
        display: 'Frysian',
        definition: 'Frysian',
      },
      {
        code: PreferredLanguage.FyNL,
        display: 'Frysian (Netherlands)',
        definition: 'Frysian (Netherlands)',
      },
      {
        code: PreferredLanguage.Hat,
        display: 'Haitian Creole',
        definition: 'Haitian Creole',
      },
      {
        code: PreferredLanguage.Hi,
        display: 'Hindi',
        definition: 'Hindi',
      },
      {
        code: PreferredLanguage.Hr,
        display: 'Croatian',
        definition: 'Croatian',
      },
      {
        code: PreferredLanguage.It,
        display: 'Italian',
        definition: 'Italian',
      },
      {
        code: PreferredLanguage.ItCH,
        display: 'Italian (Switzerland)',
        definition: 'Italian (Switzerland)',
      },
      {
        code: PreferredLanguage.ItIT,
        display: 'Italian (Italy)',
        definition: 'Italian (Italy)',
      },
      {
        code: PreferredLanguage.Ja,
        display: 'Japanese',
        definition: 'Japanese',
      },
      {
        code: PreferredLanguage.Ko,
        display: 'Korean',
        definition: 'Korean',
      },
      {
        code: PreferredLanguage.Nl,
        display: 'Dutch',
        definition: 'Dutch',
      },
      {
        code: PreferredLanguage.NlBE,
        display: 'Dutch (Belgium)',
        definition: 'Dutch (Belgium)',
      },
      {
        code: PreferredLanguage.NlNL,
        display: 'Dutch (Netherlands)',
        definition: 'Dutch (Netherlands)',
      },
      {
        code: PreferredLanguage.No,
        display: 'Norwegian',
        definition: 'Norwegian',
      },
      {
        code: PreferredLanguage.NoNO,
        display: 'Norwegian (Norway)',
        definition: 'Norwegian (Norway)',
      },
      {
        code: PreferredLanguage.Pa,
        display: 'Punjabi',
        definition: 'Punjabi',
      },
      {
        code: PreferredLanguage.Pl,
        display: 'Polish',
        definition: 'Polish',
      },
      {
        code: PreferredLanguage.Pt,
        display: 'Portuguese',
        definition: 'Portuguese',
      },
      {
        code: PreferredLanguage.PtBR,
        display: 'Portuguese (Brazil)',
        definition: 'Portuguese (Brazil)',
      },
      {
        code: PreferredLanguage.Ru,
        display: 'Russian',
        definition: 'Russian',
      },
      {
        code: PreferredLanguage.RuRU,
        display: 'Russian (Russia)',
        definition: 'Russian (Russia)',
      },
      {
        code: PreferredLanguage.Sr,
        display: 'Serbian',
        definition: 'Serbian',
      },
      {
        code: PreferredLanguage.SrRS,
        display: 'Serbian (Serbia)',
        definition: 'Serbian (Serbia)',
      },
      {
        code: PreferredLanguage.Sv,
        display: 'Swedish',
        definition: 'Swedish',
      },
      {
        code: PreferredLanguage.SvSE,
        display: 'Swedish (Sweden)',
        definition: 'Swedish (Sweden)',
      },
      {
        code: PreferredLanguage.Te,
        display: 'Telegu',
        definition: 'Telegu',
      },
      {
        code: PreferredLanguage.Zh,
        display: 'Chinese',
        definition: 'Chinese',
      },
      {
        code: PreferredLanguage.ZhCN,
        display: 'Chinese (China)',
        definition: 'Chinese (China)',
      },
      {
        code: PreferredLanguage.ZhHK,
        display: 'Chinese (Hong Kong)',
        definition: 'Chinese (Hong Kong)',
      },
      {
        code: PreferredLanguage.ZhSG,
        display: 'Chinese (Singapore)',
        definition: 'Chinese (Singapore)',
      },
      {
        code: PreferredLanguage.ZhTW,
        display: 'Chinese (Taiwan)',
        definition: 'Chinese (Taiwan)',
      },
      {
        code: PreferredLanguage.Vi,
        display: 'Vietnamese',
        definition: 'Vietnamese',
      },
      {
        code: PreferredLanguage.Fa,
        display: 'Farsi',
        definition: 'Farsi',
      },
      {
        code: PreferredLanguage.Sw,
        display: 'Swahili',
        definition: 'Swahili',
      },
      {
        code: PreferredLanguage.Unknown,
        display: 'Unknown',
        definition: 'Unknown',
      },
      {
        code: PreferredLanguage.Misc,
        display: 'MISCELLANEOUS (OTHER)',
        definition: 'MISCELLANEOUS (OTHER)',
      },
      {
        code: PreferredLanguage.Cpf,
        display: 'CREOLES & PIDGINS(FRENCH)',
        definition: 'CREOLES & PIDGINS(FRENCH)',
      },
      {
        code: PreferredLanguage.Ps,
        display: 'Pashto',
        definition: 'Pashto',
      },
    ],
  },
  [Concepts.PreferredPronoun]: {
    code: Concepts.PreferredPronoun,
    display: 'Preferred Pronoun',
    definition: 'Preferred Pronoun',
    concept: [
      {
        code: PreferredPronoun.He,
        display: 'he/him',
        definition: 'he/him/his/his/himself',
      },
      {
        code: PreferredPronoun.She,
        display: 'she/her',
        definition: 'she/her/her/hers/herself',
      },
      {
        code: PreferredPronoun.They,
        display: 'they/them',
        definition: 'they/them/their/theirs/themselves',
      },
      {
        code: PreferredPronoun.Ze,
        display: 'ze/zir',
        definition: 'ze/zir/zir/zirs/zirself',
      },
      {
        code: PreferredPronoun.Xie,
        display: 'xie/hir',
        definition: 'xie/hir (here)/hir/hirs/hirself',
      },
      {
        code: PreferredPronoun.Co,
        display: 'co/co',
        definition: 'co/co/cos/cos/coself',
      },
      {
        code: PreferredPronoun.En,
        display: 'en/en',
        definition: 'en/en/ens/ens/enself',
      },
      {
        code: PreferredPronoun.Ey,
        display: 'ey/em',
        definition: 'ey/em/eir/eirs/emself',
      },
      {
        code: PreferredPronoun.Yo,
        display: 'yo/yo',
        definition: 'yo/yo/yos/yos/yoself',
      },
      {
        code: PreferredPronoun.Ve,
        display: 've/vis',
        definition: 've/vis/ver/ver/verself',
      },
      {
        code: PreferredPronoun.unknown,
        display: 'unknown',
        definition: 'unknown',
      },
    ],
  },
  [Concepts.ProgramStatus]: {
    code: Concepts.ProgramStatus,
    display: 'Program Status',
    definition: 'Program status',
    concept: [
      {
        code: ProgramStatus.NotEnrolled,
        display: 'Not enrolled',
        definition: 'Not enrolled in the program',
      },
      {
        code: ProgramStatus.Enrolled,
        display: 'Enrolled',
        definition: 'Enrolled in the program',
      },
      {
        code: ProgramStatus.Onboarded,
        display: 'Onboarded',
        definition: 'Onboarded to the program',
      },
      {
        code: ProgramStatus.Disenrolled,
        display: 'Disenrolled',
        definition: 'Disenrolled from the program',
      },
    ],
  },
  [Concepts.TaskPriority]: {
    code: Concepts.TaskPriority,
    display: 'Task Priority',
    definition: 'Task priority',
    concept: [
      {
        code: TaskPriority.High,
        display: 'High',
        definition: 'Highest priority task',
      },
      {
        code: TaskPriority.Medium,
        display: 'Medium',
        definition: 'Medium priority task',
      },
      {
        code: TaskPriority.Low,
        display: 'Low',
        definition: 'Low priority task',
      },
    ],
  },
  [Concepts.TaskType]: {
    code: Concepts.TaskType,
    display: 'Task Type',
    definition: 'Task type',
    concept: Object.entries(taskTypeMap).map(([code, { display, definition }]) => ({
      code,
      display,
      definition,
    })),
  },
};
