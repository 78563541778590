import React, { useMemo } from 'react';
import { Button, Flex, LoadingOverlay, Modal, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { ApolloQueryResult } from '@apollo/client';
import { GetPatientQuery } from 'medplum-gql';
import ContactEditForm from '../../ContactEditForm';
import PatientContactForm from '../patientContacts/PatientContactForm';
import { PatientContactFormHandler } from '../patientContacts/PatientContactFormHandler';
import { Coding, Patient, RelatedPerson } from '@medplum/fhirtypes';
import { createError, useApiClient } from '@/hooks/useApiClient';
import { hydratePatientContactForm } from '../handlers/patientContactForm';
import { getErrorMessage } from '@/errors';
import {
  MaybeExistingContactProvider,
  useMaybeExistingContacts,
} from '@/components/MaybeExistingContactForm/MaybeExistingContactContext';

interface ExtendedRelatedPerson extends RelatedPerson {
  PatientList?: Patient[];
}
interface ExtendedPatient extends Patient {
  RelatedPersonList?: ExtendedRelatedPerson[];
}

interface PatientContactsProps {
  patient: ExtendedPatient;
  selectedPerson?: ExtendedRelatedPerson;
  refetch: () => Promise<ApolloQueryResult<GetPatientQuery>>;
  isModalOpen: boolean;
  closeModal: () => void;
  setSelectedPerson?: (person: ExtendedRelatedPerson | undefined) => void;
  label: string;
  openAddContactModal?: () => void;
}

const PatientContactModal = ({
  patient,
  selectedPerson,
  refetch,
  isModalOpen,
  closeModal,
  setSelectedPerson,
  label,
  openAddContactModal,
}: PatientContactsProps): JSX.Element => {
  const apiClient = useApiClient();
  const maybeExistingContacts = useMaybeExistingContacts();
  const isEditing = label !== 'Add contact';

  const initialForm = useMemo(() => {
    if (isEditing && selectedPerson) {
      return hydratePatientContactForm(selectedPerson, patient);
    } else {
      return {
        contact: {
          firstName: '',
          lastName: '',
          emailAddress: '',
          relationship: undefined,
          phoneNumbers: [
            {
              number: '',
              type: '',
              status: '',
              primary: false,
            },
          ],
          address: {
            line1: '',
            line2: '',
            city: '',
            state: '',
            zip: '',
          },
          language: '',
          primary: false,
          requiresInterpreter: false,
          contactType: '',
          usePatientAddress: true,
          usePatientLanguage: true,
        },
      };
    }
  }, [isEditing, selectedPerson, patient]);

  const [loading, { open: showLoader, close: hideLoader }] = useDisclosure(false);
  const patientContactForm = PatientContactForm(initialForm);

  // TODO: Consider using Levensthein distance to mitigate false-positives where the intent may be to fix a typo in first & last names.
  // Proceed with caution as false-negatives would be worse that the friction imposed by a false-positive.
  // Consider possibly tightening the condition to account for when a new contact _is intended_ but the first name or last name may be the same between new and old contact.
  const maybeNewContactIntended =
    isEditing && patientContactForm.isDirty('contact.firstName') && patientContactForm.isDirty('contact.lastName');

  const handleExisting = (existing: Patient, relationship: Coding, contactType?: Coding) => {
    return apiClient
      .fetch('/api/patients/links', {
        method: 'POST',
        body: JSON.stringify({
          caregiverId: existing.id,
          linkPatientId: patient?.id,
          relationship: relationship,
          contactType: contactType,
        }),
      })
      .then(async (res: Response) => {
        if (!res.ok) {
          throw await createError(res, 'Failed to link contact');
        }
        return refetch().catch(() => {});
      });
  };

  const submitHandler = async () => {
    showLoader();
    try {
      if (maybeExistingContacts.selected && maybeExistingContacts.relationship) {
        await handleExisting(
          maybeExistingContacts.selected,
          maybeExistingContacts.relationship,
          maybeExistingContacts.contactType,
        );
      } else {
        await PatientContactFormHandler(apiClient, patient!.id!, patientContactForm, refetch, isEditing);
      }
      showNotification({
        color: 'status-success',
        message: 'Contact saved successfully',
      });
      setSelectedPerson?.(undefined);
      closeModal();
    } catch (err: unknown) {
      showNotification({
        color: 'status-error',
        message: getErrorMessage(err),
      });
    } finally {
      hideLoader();
    }
  };

  let saveCopy = 'Save changes';
  if (!isEditing) {
    saveCopy = maybeExistingContacts.selected ? 'Link existing' : label;
  }

  return (
    <>
      <LoadingOverlay visible={loading} />
      <Modal
        opened={isModalOpen}
        onClose={() => {
          setSelectedPerson?.(undefined);
          closeModal();
        }}
        title={<Title c="imagine-green">{label}</Title>}
        size="xl"
      >
        <form onSubmit={patientContactForm.onSubmit(submitHandler)}>
          <Title order={4} mb="md">
            Contact info
          </Title>
          <ContactEditForm
            formWithContact={patientContactForm}
            maybeNewContactIntended={maybeNewContactIntended}
            openAddContactModal={openAddContactModal}
          />
          <Flex
            mih={50}
            gap="md"
            justify="flex-end"
            align="flex-start"
            direction="row"
            wrap="wrap"
            style={{ marginTop: '30px' }}
          >
            <Button variant="outline" onClick={() => closeModal()}>
              Cancel
            </Button>
            <Button
              disabled={maybeExistingContacts.inputRequired}
              type="submit"
              onClick={maybeExistingContacts.selected ? () => submitHandler() : undefined}
            >
              {saveCopy}
            </Button>
          </Flex>
        </form>
      </Modal>
    </>
  );
};

const PatientContactModalWrapped = (props: PatientContactsProps) => {
  return (
    <MaybeExistingContactProvider>
      <PatientContactModal {...props} />
    </MaybeExistingContactProvider>
  );
};

export default PatientContactModalWrapped;
