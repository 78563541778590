import React, { useMemo, useState } from 'react';
import {
  Text,
  Stack,
  Box,
  Group,
  Anchor,
  ActionIcon,
  Button,
  Modal,
  Collapse,
  ThemeIcon,
  Checkbox,
  List,
} from '@mantine/core';
import { ApolloQueryResult } from '@apollo/client';
import { GetPatientQuery, RelatedPerson, Patient, Consent, DocumentReference, Maybe } from 'medplum-gql';
import { getName, isCaregiver } from 'imagine-dsl/utils/patient';
import { CodeableConcept, RelatedPerson as FHIRRelatedPerson, QuestionnaireResponse } from '@medplum/fhirtypes';
import { System } from 'const-utils';
import { capitalize } from 'lodash';
import { useApiClient } from '@/hooks/useApiClient';
import { showNotification } from '@mantine/notifications';
import { IconAlertTriangleFilled, IconChevronDown, IconChevronRight, IconDownload } from '@tabler/icons-react';
import classes from '../shared.module.css';
import { useDisclosure } from '@mantine/hooks';
import { genericErrorMessage, logError } from '@/errors';
import { getRelatedPersonRelationship } from 'imagine-dsl/utils/relatedPerson';
import { useMedplum } from '@medplum/react';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { ConsentUploadButton } from './ConsentUploadButton';
import { SurveyDrawerContent } from '@/components/questionnaire/DrawerContent';
import { ExpandableDrawer } from '@/design-system/ExpandableDrawer';

interface ConsentProps {
  patient: Patient;
  linkedPatients: RelatedPerson[];
  refetch: () => Promise<ApolloQueryResult<GetPatientQuery>>;
}
interface ExtendedConsent extends Consent {
  sourceReference: {
    resource: DocumentReference;
  };
}
const isActiveConsent = (consent: Maybe<Consent>): boolean => !!(consent?.status === 'active');

const hasAnyConsent = (consenter?: { ConsentList?: Patient['ConsentList'] }): boolean => {
  return !!consenter?.ConsentList?.some(isActiveConsent);
};

const download = (blob: Blob, name: string): void => {
  const fileURL = URL.createObjectURL(blob);
  const element = document.createElement('a');
  element.setAttribute('href', fileURL);
  element.setAttribute('download', name);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

const base64ToArrayBuffer = (base64: string): ArrayBuffer => {
  const binaryString = atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

export const Consents = ({ patient, linkedPatients, refetch }: ConsentProps): JSX.Element => {
  const consenters = useMemo(() => [patient, ...linkedPatients].filter(hasAnyConsent), [patient, linkedPatients]);
  const apiClient = useApiClient();
  const medplum = useMedplum();
  const [isClearConfirmOpen, { open: openClearConfirm, close: closeClearConfirm }] = useDisclosure();
  const [consentToClear, setConsentToClear] = useState<{
    consenter: Patient | RelatedPerson;
    consent: ExtendedConsent;
  } | null>(null);
  const [clearConsentAttested, setClearConsentAttested] = useState(false);
  const [clearConsentLoading, setClearConsentLoading] = useState(false);
  const [consentsOpened, setConsentsOpened] = useState(false);

  const [selectedQuestionnaireResponse, setSelectedQuestionnaireResponse] = useState<
    QuestionnaireResponse | undefined
  >();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const flags = useFeatureFlags();

  const getConsentPDF = (consent: Consent): Promise<ArrayBuffer> => {
    return apiClient
      .fetch(`/api/consents/${consent.id}`)
      .then((res) => {
        if (res.status === 200) {
          return res.text();
        } else if (res.status === 404) {
          throw new Error('File not found');
        }

        throw new Error(genericErrorMessage);
      })
      .then(base64ToArrayBuffer);
  };

  const onViewConsent = async (consent: Consent): Promise<void> => {
    if (!consent?.sourceReference) {
      return;
    }
    getConsentPDF(consent)
      .then((data) => {
        const file = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((err) => {
        showNotification({
          color: 'status-error',
          title: 'Error',
          message: err.message,
        });
      });
  };

  const onDownloadConsent = (consent: Consent): void => {
    getConsentPDF(consent)
      .then((data) => {
        const file = new Blob([data], { type: 'application/pdf' });
        download(file, consent.id!);
      })
      .catch((err) => {
        showNotification({
          color: 'status-error',
          title: 'Error',
          message: err.message,
        });
      });
  };

  const closeClearConsentModal = (): void => {
    setConsentToClear(null);
    setClearConsentAttested(false);
    closeClearConfirm();
  };

  const onClearConsent = async (consent: Consent): Promise<void> => {
    if (clearConsentLoading) {
      return;
    }
    setClearConsentLoading(true);

    apiClient
      .fetch(`/api/consents/${consent.id}/clear`, {
        method: 'POST',
      })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(`unexpected non-200 status clearing consent ${res.status}`);
        }

        showNotification({
          color: 'status-success',
          message: 'Successfully cleared consent',
        });
        refetch().catch((e) => logError(e));
        closeClearConsentModal();
      })
      .catch((err) => {
        showNotification({
          color: 'status-error',
          title: 'Error clearing consent',
          message: err.message,
        });
      })
      .finally(() => setClearConsentLoading(false));
  };

  return (
    <>
      <Group justify="space-between">
        <Group align="center" onClick={() => setConsentsOpened(!consentsOpened)}>
          {consentsOpened ? (
            <ThemeIcon color="imagine-green" variant="transparent" mr={5}>
              <IconChevronDown size={15} />
            </ThemeIcon>
          ) : (
            <ThemeIcon color="imagine-green" variant="transparent" mr={5}>
              <IconChevronRight size={15} />
            </ThemeIcon>
          )}
          <Text style={{ cursor: 'pointer' }} c="imagine-green">
            Consents
          </Text>
        </Group>
        {(flags.ConsentUpload || medplum.isProjectAdmin()) && (
          <ConsentUploadButton patient={patient} refetch={refetch} />
        )}
      </Group>
      <Collapse in={consentsOpened}>
        {consenters.length === 0 ? (
          <Text c="dimmed" fz="xs">
            This patient has no consents
          </Text>
        ) : (
          consenters.map((consenter) => (
            <Box key={consenter.id}>
              <Text fw={700} size="md">
                {getName(consenter)}
              </Text>
              {patient.id !== consenter.id ? (
                <Text c="dimmed">
                  {(() => {
                    const contactType = (consenter as FHIRRelatedPerson)?.relationship?.find(
                      (relation: CodeableConcept) => relation?.coding?.[0]?.system === System.ContactType,
                    )?.coding?.[0]?.display;

                    const roleCode = getRelatedPersonRelationship(consenter as FHIRRelatedPerson)?.display;

                    return `${contactType ? contactType + ', ' : ''}${capitalize(roleCode) || ''}`;
                  })()}
                </Text>
              ) : (
                <Text c="dimmed" fz="xs">
                  {isCaregiver(patient) ? 'Caregiver' : 'Patient'}
                </Text>
              )}
              <Stack gap={0}>
                {(consenter.ConsentList as ExtendedConsent[]).filter(isActiveConsent).map((consent) => (
                  <Group key={consent.id} gap="xs" className={classes.lineSeparatedListItem} justify="space-between">
                    <Anchor
                      style={{ flexGrow: 1, wordBreak: 'break-word', maxWidth: '60%' }}
                      onClick={async (e) => {
                        e.preventDefault();
                        await onViewConsent(consent);
                      }}
                    >
                      {getConsentName(consent)}
                    </Anchor>
                    <Group gap="xs">
                      <ActionIcon color="imagine-green" onClick={() => onDownloadConsent(consent)}>
                        <IconDownload size={20} />
                      </ActionIcon>
                      <Button
                        color="red"
                        variant="outline"
                        size="xs"
                        onClick={() => {
                          setConsentToClear({ consent, consenter });
                          openClearConfirm();
                        }}
                      >
                        Clear
                      </Button>
                    </Group>
                  </Group>
                ))}
              </Stack>
            </Box>
          ))
        )}
      </Collapse>
      <Modal
        size="lg"
        opened={isClearConfirmOpen}
        onClose={() => closeClearConsentModal()}
        title={<Text c="imagine-green">Clear consent</Text>}
      >
        <Text mb="md">
          Are you sure you want to clear {getConsentName(consentToClear?.consent)} for{' '}
          {getName(consentToClear?.consenter)}
        </Text>
        <Group mb="md">
          <ThemeIcon variant="transparent" color="status-warn">
            <IconAlertTriangleFilled />
          </ThemeIcon>
          <Text fw={700}>Please note the following:</Text>
        </Group>
        <List spacing="sm">
          <List.Item>
            <Text>
              Caregivers{' '}
              <Text span fw={700}>
                <Text span inherit style={{ textDecoration: 'underline' }}>
                  cannot
                </Text>
                <Text span> log in, send or receive messages,</Text>
              </Text>
              <Text span> or</Text>
              <Text span fw={700}>
                {' '}
                get care for their children
              </Text>
              <Text span>
                {' '}
                if consents aren't signed. Only clear consents if the patient is 18 or older OR if the caregiver is able
                to re-sign right after and you can get confirmation of a successful re-signing.
              </Text>
            </Text>
          </List.Item>
          <List.Item>
            <Text>
              Ensure that consents definitely need to be reset before doing so. Don't jump to conclusions, and eliminate
              other possible issues first.
            </Text>
          </List.Item>
        </List>
        <Box style={{ outline: '1px solid grey', borderRadius: 5 }} p="md" my="md">
          <Checkbox
            disabled={clearConsentLoading}
            checked={clearConsentAttested}
            onChange={(event) => setClearConsentAttested(event.currentTarget.checked)}
            required
            label="I understand that resetting consents will cause caregivers to lose access to the app and would still like to
            proceed"
          />
        </Box>
        <Group style={{ justifyContent: 'flex-end' }}>
          <Button disabled={clearConsentLoading} onClick={() => closeClearConsentModal()} variant="outline">
            Cancel
          </Button>
          <Button
            loading={clearConsentLoading}
            onClick={() => onClearConsent(consentToClear!.consent)}
            disabled={!clearConsentAttested}
          >
            Clear consent
          </Button>
        </Group>
      </Modal>

      {selectedQuestionnaireResponse && (
        <ExpandableDrawer
          id="consent-drawer"
          size="xxl"
          opened={isDrawerOpen}
          close={() => {
            setSelectedQuestionnaireResponse(undefined);
            setIsDrawerOpen(false);
          }}
        >
          <SurveyDrawerContent
            withViewPatientProfile
            questionnaireResponse={selectedQuestionnaireResponse}
            patientId={patient.id}
            closeDrawer={() => {
              setSelectedQuestionnaireResponse(undefined);
              setIsDrawerOpen(false);
            }}
            drawerExpanded={isDrawerOpen}
            setDrawerExpanded={setIsDrawerOpen}
          />
        </ExpandableDrawer>
      )}
    </>
  );
};

// example legacy consent source attachment titles:
// 1676394547802__ULA.pdf
// 1681505016377__TreatAndTelehealthConsents.pdf
// 1682022127571__RoiConsent.pdf
const getConsentName = (consent?: ExtendedConsent): string => {
  if (!consent) {
    return 'Consent';
  }

  const consentTypeDisplay = consent.meta?.tag?.find((tag) => tag.system === System.ConsentType)?.display;

  if (consentTypeDisplay) {
    return consentTypeDisplay;
  }
  if (!consent.sourceAttachment) {
    return consent.sourceReference?.resource.type?.coding?.at(0)?.display ?? 'Consent';
  }

  // strip timestamp / identifier
  const name = consent.sourceAttachment.title?.split('__').at(-1);

  // strip extension
  return name?.split('.').at(0) ?? 'Consent';
};
