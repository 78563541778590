import { LocalTrackPublication, Participant, RemoteTrackPublication } from 'twilio-video';
import usePublications from '../publication/usePublications';
import Publication from '../publication/Publication';
import React, { PropsWithChildren } from 'react';

type ParticipantVideoProps = {
  participant: Participant;
};

export function ParticipantVideo({ participant, children }: PropsWithChildren<ParticipantVideoProps>): JSX.Element {
  const publications = usePublications(participant);

  return (
    <>
      {publications.map((publication) => (
        <Publication
          key={publication.kind}
          publication={publication as LocalTrackPublication | RemoteTrackPublication}
          // biome-ignore lint/correctness/noChildrenProp: <explanation> TODO: determine if we can change this
          children={children}
        />
      ))}
    </>
  );
}
