import { Button, Group, Modal, Select, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { OutreachStatus } from 'const-utils';
import {
  Patient,
  GetPatientQuery,
  GetPatientPanelDocument,
  GetPatientDocument,
  GetBasicPatientDocument,
} from 'medplum-gql';
import React, { useMemo, useState } from 'react';
import { useMedplum } from '@medplum/react';
import { notifications } from '@mantine/notifications';
import { logError } from '@/errors';
import outreachStatusDataRetention from '../programStatus/OutreachStatusDataRetention';
import {
  outreachStatusNotInterestedReasonOptions,
  outreachStatusOptions,
  outreachStatusUnableToEnrollReasonOptions,
} from 'const-utils/codeSystems/ImaginePediatrics';
import { useApolloClient } from '@apollo/client';
import { EpisodeOfCare } from '@medplum/fhirtypes';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';

interface OutreachStatusEditModalProps {
  isOpen: boolean;
  onClose: () => void;
  patient: Patient | GetPatientQuery['Patient'];
  episodeOfCare?: EpisodeOfCare;
}

export const OutreachStatusEditModal = ({ isOpen, onClose, patient, episodeOfCare }: OutreachStatusEditModalProps) => {
  const medplum = useMedplum();
  const [loading, setLoading] = useState(false);
  const apolloClient = useApolloClient();
  const tanstackClient = useQueryClient();
  const outreachStatusDate = new Date().toISOString().split('T')[0];
  const flags = useFeatureFlags();

  const result = useQuery([`episodeOfCare-${patient?.id}`], async () => {
    if (!patient?.id) {
      return null;
    }

    return medplum.searchResources('EpisodeOfCare', `patient=Patient/${patient.id}`);
  });

  const fetchedEpisodeOfCare = useMemo(() => result.data?.at(0), [result.data]);

  const form = useForm({
    initialValues: {
      status: '',
      reason: '',
      date: outreachStatusDate,
    },
  });

  const definedEpisodeOfCare = useMemo(
    () => episodeOfCare ?? fetchedEpisodeOfCare,
    [episodeOfCare, fetchedEpisodeOfCare],
  );

  const submitHandler = async (values: { status: string; reason: string; date: string }) => {
    await handleSubmit(values).catch(logError);
  };

  const handleSubmit = async (values: { status: string; reason: string; date: string }) => {
    if (!patient?.id) {
      return;
    }

    setLoading(true);

    try {
      await outreachStatusDataRetention(
        values.status as OutreachStatus,
        values.date,
        patient.id!,
        medplum,
        definedEpisodeOfCare,
        values.reason,
      );
      await apolloClient
        .refetchQueries({
          include: [GetPatientPanelDocument, GetPatientDocument, GetBasicPatientDocument],
        })
        .catch(logError);
      await tanstackClient.invalidateQueries([`episodeOfCare-${patient.id}`]);
      onClose();
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error instanceof Error ? error.message : String(error),
        color: 'status-error',
      });
    } finally {
      setLoading(false);
    }
  };
  let filteredOutreachStatusOptions = outreachStatusOptions.filter(
    (option) =>
      option.value !== OutreachStatus.DoNotCall.toString() && option.value !== OutreachStatus.UnableToEnroll.toString(),
  );
  if (flags.OutreachStatusPebble) {
    filteredOutreachStatusOptions = outreachStatusOptions;
  }

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      title="Edit patient outreach status"
      size="lg"
      radius="lg"
      c="imagine-green"
      centered
    >
      <form onSubmit={form.onSubmit(submitHandler)}>
        <Stack gap="md" c="black">
          <Text fz="xs" mb="sm">
            Select the status you would like to assign
          </Text>
          <Select
            label="Status"
            {...form.getInputProps('status')}
            clearable
            searchable={false}
            data={filteredOutreachStatusOptions}
          />

          {flags.OutreachStatusPebble && form.values.status === OutreachStatus.NotInterested.toString() && (
            <Select
              data={outreachStatusNotInterestedReasonOptions}
              label="Reason"
              {...form.getInputProps('reason')}
              error={
                form.values.status === OutreachStatus.NotInterested.toString() && !form.values.reason
                  ? 'This field is required'
                  : undefined
              }
              withAsterisk
            />
          )}

          {flags.OutreachStatusPebble && form.values.status === OutreachStatus.UnableToEnroll.toString() && (
            <Select
              data={outreachStatusUnableToEnrollReasonOptions}
              label="Reason"
              {...form.getInputProps('reason')}
              error={
                form.values.status === OutreachStatus.UnableToEnroll.toString() && !form.values.reason
                  ? 'This field is required'
                  : undefined
              }
              withAsterisk
            />
          )}
          <TextInput
            required
            label="Date"
            type="date"
            mb="md"
            {...form.getInputProps('date')}
            min={outreachStatusDate}
          />

          <Group mt="md" justify="flex-end">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={
                !form.isDirty('status') ||
                !form.values.status ||
                (flags.OutreachStatusPebble &&
                  form.values.status === OutreachStatus.NotInterested.toString() &&
                  !form.values.reason) ||
                (flags.OutreachStatusPebble &&
                  form.values.status === OutreachStatus.UnableToEnroll.toString() &&
                  !form.values.reason)
              }
              loading={loading}
            >
              Save changes
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
};
