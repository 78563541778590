import React, { useMemo, useState } from 'react';
import { Text, Paper, Table, NativeSelect } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { ActivityType } from './types';
import { useActivityData } from './useActivityData';
import { ActivityTableMux } from './ActivityTableMux';
import { Pagination } from '@/design-system/Pagination';
import { ApolloQueryResult } from '@apollo/client';
import { GetPatientQuery } from 'medplum-gql';

interface PatientActivityProps {
  patientId: string;
  refresh: () => Promise<ApolloQueryResult<GetPatientQuery>>;
}

function chunk<T>(array: T[], size: number): T[][] {
  if (!array.length) {
    return [];
  }
  const head = array.slice(0, size);
  const tail = array.slice(size);
  return [head, ...chunk(tail, size)];
}

const PER_PAGE = 100;

export const PatientActivity = ({ patientId }: PatientActivityProps): JSX.Element => {
  const [value, setValue] = useState<ActivityType>(ActivityType.All);
  const [activePage, setPage] = useState(1);
  const activity = useActivityData(patientId, value);
  const chunks = useMemo(() => chunk(activity.data, PER_PAGE), [activity.data]);
  const pageData = useMemo(() => chunks[activePage - 1], [activePage, chunks]);
  const count = activity.data.length;

  return (
    <Paper shadow="xs" radius="lg" style={{ padding: '20px', width: '100%' }}>
      <Text size="xl" c="imagine-green" style={{ marginBottom: '20px' }}>
        Activity
      </Text>
      <NativeSelect
        data={[
          ActivityType.All,
          ActivityType.Touchpoint,
          ActivityType.Alert,
          ActivityType.Video,
          ActivityType.Task,
          ActivityType.ADT,
        ]}
        placeholder="Activity Type"
        radius="md"
        size="xs"
        value={value}
        onChange={(event) => setValue(event.currentTarget.value as ActivityType)}
        rightSection={<IconChevronDown size="1rem" />}
        style={{ width: '20%', margin: '10px 0 10px 0' }}
      />
      {count === 0 ? (
        <Text c="dimmed" fz="xs" style={{ marginTop: '10px' }}>
          There is no activity to display
        </Text>
      ) : (
        <>
          <Table verticalSpacing="md">
            <Table.Tbody>
              <ActivityTableMux data={pageData} count={count} patientId={patientId} />
            </Table.Tbody>
          </Table>
          <Pagination activePage={activePage} count={count} changePage={(page) => setPage(page)} perPage={PER_PAGE} />
        </>
      )}
    </Paper>
  );
};
