import React, { FormEvent } from 'react';
import { Input, InputWrapperProps, MantineSize } from '@mantine/core';
import { IMaskInput, IMaskInputProps } from 'react-imask';
import { omit, pick } from 'lodash';

interface Props extends Omit<IMaskInputProps<HTMLInputElement>, 'size'> {
  size?: MantineSize;
}

const defaultProps = Object.freeze({
  placeholder: 'MM/DD/YYYY',
  label: 'Date of Birth',
});

export const DateOfBirthInput = (props: Props): React.ReactNode => {
  const wrapperProps: InputWrapperProps = pick(
    props,
    'placeholder',
    'label',
    'required',
    'error',
    'description',
    'id',
    'size',
    'className',
    'style',
  );

  const onAccept = (value: string): void => {
    props.onChange?.(value as unknown as FormEvent<HTMLInputElement>);
  };

  return (
    <Input.Wrapper {...wrapperProps}>
      <Input mask="00/00/0000" component={IMaskInput} type="text" {...omit(props, 'onChange')} onAccept={onAccept} />
    </Input.Wrapper>
  );
};

DateOfBirthInput.defaultProps = defaultProps;
