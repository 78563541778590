import { MedplumClient, PatchOperation, createReference } from '@medplum/core';
import { CareTeam, BundleEntry, Bundle } from '@medplum/fhirtypes';

/**
 * Handles the pod change by updating the CareTeam resource.
 *
 * @param medplum - The Medplum client instance.
 * @param engagementCareTeamId - The ID of the current CareTeam resource.
 * @param updatedPodId - The ID of the new pod to assign.
 * @param engagementPods - The list of available engagement pods.
 */
export const handlePodChange = async (
  medplum: MedplumClient,
  engagementCareTeamId: string | undefined | null,
  updatedPodId: string | undefined,
  engagementPods: CareTeam[],
) => {
  if (updatedPodId && engagementCareTeamId) {
    const updatedEngagementPod = engagementPods.find((pod) => pod.id === updatedPodId);

    if (!updatedEngagementPod) {
      throw new Error('Engagement pod not found.');
    }

    const participantOp: PatchOperation = {
      op: 'replace',
      path: '/participant',
      value: [
        {
          member: createReference(updatedEngagementPod),
        },
      ],
    };
    const patchOperations: PatchOperation[] = [participantOp];

    const bundleEntries: BundleEntry[] = [
      {
        request: {
          method: 'PATCH',
          url: `CareTeam/${engagementCareTeamId}`,
        },
        resource: {
          resourceType: 'Binary',
          contentType: 'application/json-patch+json',
          data: btoa(JSON.stringify(patchOperations)),
        },
      },
    ];

    const bundle: Bundle = {
      resourceType: 'Bundle',
      type: 'transaction',
      entry: bundleEntries,
    };

    await medplum.executeBatch(bundle);
  } else {
    throw new Error('Care team or updated pod ID not provided.');
  }
};
