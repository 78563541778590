import { PageSkeleton } from '@/design-system/PageSkeleton';
import React from 'react';
import { PER_PAGE, useNotificationContext } from './NotificationsProvider';
import { Filters } from './Filters';
import { Row } from './Row';
import { Box, Divider, Loader } from '@mantine/core';
import { Pagination } from '@/design-system/Pagination';

export const NotificationsPage = (): JSX.Element => {
  const { notificationsCount, unreadOnly, updateUnreadOnly, filteredNotifications, filteredNotificationsLoading } =
    useNotificationContext();

  return (
    <PageSkeleton variant="index" topSection={<Filters unreadOnly={unreadOnly} setUnreadOnly={updateUnreadOnly} />}>
      {filteredNotificationsLoading && <Loader m={12} />}
      {!filteredNotificationsLoading && (
        <>
          {filteredNotifications.map((notification, index) => (
            <Box p={0} key={notification.id}>
              <Row notification={notification} />
              {index !== filteredNotifications.length - 1 && <Divider />}
            </Box>
          ))}
          <Box p={8}>
            <Pagination count={notificationsCount} perPage={PER_PAGE} />
          </Box>
        </>
      )}
    </PageSkeleton>
  );
};
