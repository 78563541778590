import React from 'react';
import { useMedplum } from '@medplum/react';
import { NavLink } from 'react-router-dom';
import { Text, Stack, Badge, Tooltip, Box, Group, Skeleton, AppShell, ActionIcon, ThemeIcon } from '@mantine/core';
import {
  IconAlertTriangle,
  IconCheckupList,
  IconChevronsRight,
  IconMessages,
  IconSearch,
  IconUser,
  IconUsers,
} from '@tabler/icons-react';
import { StyledNavLink } from './StyledNavLink';
import { useCommandCenterAlertAndCountContext } from '@/pages/CommandCenter/CommandCenterAlertProvider';
import { VERTICAL_NAV_WIDTH_COLLAPSED } from '../shared/constants';
import { CareHubLogo } from '../CareHubLogo';
import { AppVersion } from './AppVersion';
import { engagementRoles, techSupportRoles, useHasRole } from '@/hooks/useHasRole';
import { useTaskCountContext } from '@/pages/Task/TaskCountProvider';

const NotificationSection = ({
  unassignedCount,
  assignedToMeCount,
}: {
  unassignedCount: number;
  assignedToMeCount: number;
}) => {
  return (
    <Stack gap={0} pos="relative" mr={8}>
      <Tooltip fw="normal" label="Unassigned tasks">
        <Badge bg="brand-blue.7" variant="filled" size="xs">
          {unassignedCount}
        </Badge>
      </Tooltip>
      <Tooltip fw="normal" label="Tasks assigned to me">
        <Badge bg="brand-yellow.3" c="black" variant="filled" size="xs">
          {assignedToMeCount}
        </Badge>
      </Tooltip>
    </Stack>
  );
};

const OutreachNotificationSection = ({
  assignedToMeCount,
  hasOverdue,
}: {
  assignedToMeCount: number;
  hasOverdue: boolean;
}) => {
  return (
    <Group gap={4}>
      <Tooltip fw="normal" label="Assigned tasks">
        <Badge bg="brand-yellow.3" c="black" variant="filled" size="xs">
          {assignedToMeCount}
        </Badge>
      </Tooltip>
      {hasOverdue ? (
        <Tooltip fw="normal" label="Some tasks are overdue">
          <ThemeIcon size={20} c="status-warn">
            <IconAlertTriangle size={20} />
          </ThemeIcon>
        </Tooltip>
      ) : (
        <Skeleton w={20} />
      )}
    </Group>
  );
};

export interface CollapsedVerticalNavProps {
  visible: boolean;
  toggleVisibility: () => void;
}

export const CollapsedVerticalNav = ({ visible, toggleVisibility }: CollapsedVerticalNavProps): JSX.Element => {
  const medplum = useMedplum();

  const { tasksUnassignedCount: ccTasksUnassignedCount, tasksAssignedToMeCount: ccTasksAssignedToMeCount } =
    useCommandCenterAlertAndCountContext();
  const { tasksAssignedToMeCount, hasOverdueTasks } = useTaskCountContext();

  const isAdmin = medplum.isProjectAdmin();
  const isUserManagementEnabled = useHasRole(techSupportRoles) || isAdmin;
  const isEngagementAssociate = useHasRole(engagementRoles);

  return (
    <AppShell.Navbar
      top={0}
      miw={visible ? VERTICAL_NAV_WIDTH_COLLAPSED : 0}
      w={visible ? VERTICAL_NAV_WIDTH_COLLAPSED : 0}
      style={{
        alignItems: 'center',
        justifyContent: 'space-between',
        visibility: visible ? 'visible' : 'hidden',
      }}
    >
      <Stack w="100%" align="center" gap={0}>
        <ActionIcon
          pos="relative"
          top={45}
          left={40}
          size="sm"
          variant="outline"
          bg="white"
          radius="xl"
          onClick={toggleVisibility}
        >
          <IconChevronsRight width="70%" height="70%" stroke={1.5} />
        </ActionIcon>
        <Box m="sm" mb="lg">
          <CareHubLogo size={48} />
          <AppVersion prefix={''} />
          <NavLink to="/release-notes">
            <Text ta="center" fz={8} c="dimmed">
              Release Notes
            </Text>
          </NavLink>
        </Box>
        <StyledNavLink
          to="/"
          label={<IconMessages width="30px" height="30px" />}
          rightSection={
            <NotificationSection
              unassignedCount={ccTasksUnassignedCount}
              assignedToMeCount={ccTasksAssignedToMeCount}
            />
          }
        />
        <StyledNavLink to="/Patients" label={<IconSearch width="30px" height="30px" />} />
        {(isEngagementAssociate || isAdmin) && (
          <StyledNavLink to="/PatientPanel" label={<IconUsers width="30px" height="30px" />} />
        )}
        {(!isEngagementAssociate || isAdmin) && (
          <StyledNavLink to="/MyPatients" label={<IconUsers width="30px" height="30px" />} />
        )}

        <StyledNavLink
          to="/Task"
          label={<IconCheckupList width="30px" height="30px" />}
          rightSection={
            <OutreachNotificationSection assignedToMeCount={tasksAssignedToMeCount} hasOverdue={hasOverdueTasks} />
          }
        />

        {isUserManagementEnabled && <StyledNavLink to="/Users" label={<IconUser width="30px" height="30px" />} />}
      </Stack>
    </AppShell.Navbar>
  );
};
