import React, { useMemo, useState } from 'react';
import { useGetBasicPatientQuery } from 'medplum-gql';
import {
  Group,
  Stack,
  Text,
  ActionIcon,
  Tooltip,
  Anchor,
  Avatar,
  Collapse,
  ThemeIcon,
  UnstyledButton,
} from '@mantine/core';
import { useMedplum } from '@medplum/react';
import { Extension, HumanName, Organization, Patient, Reference } from '@medplum/fhirtypes';
import { IconChevronRight, IconChevronUp, IconEditCircle, IconMapPin } from '@tabler/icons-react';
import classes from './PatientHeader.module.css';
import { useVideo } from '../../../video/VideoProvider';
import { getPatientExtension, getName } from 'imagine-dsl/utils/patient';
import MedplumLogo from '@assets/logo/medplum.svg';
import AttachmentPreviewModal from '../../../shared/AttachmentPreviewModal';
import { PatientPinButton } from '../../../PatientPinButton';
import { HeaderLinks } from '../HeaderLinks';
import PatientActions from '@/components/patientProfile/PatientActions';
import { HeaderDetails } from './HeaderDetails';
import { useHandleConductOutreach } from './hooks';
import { ProgramStatus, System } from 'const-utils';
import { useDisclosure } from '@mantine/hooks';
import { OutreachStatusEditModal } from '@/components/patientProfile/modals/OutreachStatusEditModal';
import { useProfilePreferences } from '@/context/ProfilePreferencesContext';
import { OutreachStatus, outreachStatusMap } from 'const-utils/codeSystems/ImaginePediatrics';

export interface CompactPatientHeaderProps {
  patientId: string;
  withViewPatientProfile?: boolean;
}

export function CompactPatientHeader({
  patientId,
  withViewPatientProfile = false,
}: CompactPatientHeaderProps): JSX.Element {
  const [previewing, setPreviewing] = useState(false);
  const [profilePreferences, setProfilePreferences] = useProfilePreferences();
  const viewDetails = !profilePreferences.patientHeaderDetailsCollapsed;
  const toggleViewDetails = () => {
    setProfilePreferences((prev) => ({
      ...prev,
      patientHeaderDetailsCollapsed: !prev.patientHeaderDetailsCollapsed,
    }));
  };

  const patientResult = useGetBasicPatientQuery({ variables: { id: patientId }, fetchPolicy: 'cache-and-network' });

  const patient = useMemo(() => patientResult.data?.Patient, [patientResult.data]);

  const { startCall, activeCall, isStartingCall } = useVideo();
  const medplum = useMedplum();

  const conductOutreach = useHandleConductOutreach(patient);
  const [Opened, { close, open }] = useDisclosure(false);

  if (!patient?.id) {
    return <div>No patient found</div>;
  }

  const managingOrganization = patient.managingOrganization?.resource as Organization | undefined;
  const preferredPronoun = getPatientExtension(patient.extension as Extension[], System.PreferredPronoun);
  const legalName = getName(patient, { use: 'official' }) || 'Unknown';
  const preferredName = getName(patient, { use: 'usual' }) || undefined;
  const hasPreferredName = preferredName && preferredName !== legalName;

  const hasPhoto = !!patient.photo?.length;

  const isEnrolled = [ProgramStatus.Enrolled.toString(), ProgramStatus.Onboarded.toString()].includes(
    patient.meta?.tag?.find((tag) => tag.system === System.ProgramStatus.toString())?.code ?? '',
  );
  const outreachStatus = patient.meta?.tag?.find((tag) => tag.system === System.OutreachStatus.toString())?.code as
    | OutreachStatus
    | undefined;
  const outreachStatusDisplay = outreachStatus && outreachStatusMap[outreachStatus].label;
  const shouldDisplayOutreachStatus = outreachStatus && !isEnrolled;

  const startVideoCall = async (): Promise<void> => {
    const reference: Reference<Patient> = {
      reference: `Patient/${patient.id}`,
      display: getName(patient, { use: 'official' }) || 'Unknown',
    };
    await startCall(reference);
  };

  return (
    <>
      {shouldDisplayOutreachStatus && <OutreachStatusEditModal isOpen={Opened} onClose={close} patient={patient} />}

      <Group px={26}>
        <Stack pb={16} className={classes.headerStack} gap="xs">
          <Group justify="space-between" className={classes.headerGroup}>
            <Group style={{ flexGrow: 1 }}>
              {previewing && (
                <AttachmentPreviewModal
                  contentType={patient.photo?.[0].contentType || ''}
                  url={patient.photo?.[0].url || ''}
                  title={(patient.name?.[0] as HumanName).text || 'Patient Photo'}
                  onClose={() => setPreviewing(false)}
                />
              )}
              {hasPhoto && (
                <Anchor onClick={() => setPreviewing(!!patient.photo?.length)}>
                  <Avatar src={patient.photo?.[0].url} alt="Patient photo" size={'md'} color="imagine-green" />
                </Anchor>
              )}

              {medplum.isProjectAdmin() && (
                <Tooltip label="View in Medplum">
                  <ActionIcon
                    onClick={() => {
                      window.open(`https://app.medplum.com/Patient/${patient.id}`, '_blank');
                    }}
                  >
                    <img style={{ height: '1.5em' }} src={MedplumLogo} alt="medplum logo" />
                  </ActionIcon>
                </Tooltip>
              )}
              <span className={classes.patientNameSmall}>{preferredName || legalName}</span>
            </Group>
            <PatientPinButton patientId={patient.id} />
            <PatientActions
              withVideoCallButton
              patientId={patient.id}
              outreachHandler={conductOutreach}
              videoHandler={startVideoCall}
              activeCall={activeCall}
              videoStarting={isStartingCall}
            />
          </Group>
          {shouldDisplayOutreachStatus && (
            <Group>
              <Text>{outreachStatusDisplay}</Text>
              <ActionIcon color="imagine-green" onClick={() => open()}>
                <IconEditCircle size={25} />
              </ActionIcon>
            </Group>
          )}
          <Collapse in={viewDetails}>
            <Stack mb={'xs'} gap={1}>
              <Group style={{ flexGrow: 1 }}>
                {preferredPronoun && <span className={classes.patientPronouns}>({preferredPronoun})</span>}
                <span>
                  <IconMapPin size={14} />
                  {'  '}
                  {managingOrganization?.name}
                </span>
              </Group>
              {hasPreferredName && (
                <Group>
                  <span className={classes.legalNameLabel}>Legal Name: </span>
                  <span className={classes.patientLegalName}>{getName(patient, { use: 'official' })}</span>
                </Group>
              )}
            </Stack>
            <HeaderDetails
              patient={patient}
              withPediatricRn
              withPhoneNumber
              withAddress
              withPatientNotesCondensed
              withProgramStepper
            />
          </Collapse>
          <HeaderLinks
            patient={patient as Patient}
            linkOptions={{
              withPatientNotesCondensed: true,
              withViewAthenaProfile: true,
              withViewPatientProfile,
            }}
          />
        </Stack>
      </Group>
      <UnstyledButton
        display="flex"
        bg="brand-gray.0"
        bd="1px solid brand-gray.4"
        w="100%"
        style={{ justifyContent: 'center' }}
        onClick={() => toggleViewDetails()}
      >
        <Group align="center">
          {viewDetails ? (
            <ThemeIcon color="imagine-green" variant="transparent">
              <IconChevronUp size={20} />
            </ThemeIcon>
          ) : (
            <ThemeIcon color="imagine-green" variant="transparent">
              <IconChevronRight size={20} />
            </ThemeIcon>
          )}

          <Text c={'imagine-green'}>{viewDetails ? 'Hide' : 'View'} Details</Text>
        </Group>
      </UnstyledButton>
    </>
  );
}
