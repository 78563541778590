import React from 'react';
import { Group, Stack, Text } from '@mantine/core';
import { Attachment, Communication, DocumentReference } from '@medplum/fhirtypes';
import { getSentByImaginePediatrics } from 'imagine-dsl/utils/communication';
import { AttachmentDisplay } from '@/components/chat/AttachmentDisplay';
import { formatWithYesterdayAndToday } from 'imagine-dsl/utils/dates';

interface ChatBubbleProps {
  message: Communication;
}

function PractitionerBubble({ contentString }: { contentString: string }): JSX.Element {
  return (
    <Text
      w="fit-content"
      p="sm"
      style={{
        borderRadius: '12px',
        borderTopRightRadius: 0,
        whiteSpace: 'pre-line',
        maxWidth: '100%',
        wordBreak: 'break-word',
        textAlign: 'left',
      }}
      bg="primary-50.1"
      size="md"
      my={4}
    >
      {contentString}
    </Text>
  );
}

function CaregiverBubble({ contentString }: { contentString: string }): JSX.Element {
  return (
    <Text
      w="fit-content"
      p="sm"
      style={{
        borderRadius: '12px',
        borderTopLeftRadius: 0,
        whiteSpace: 'pre-line',
        maxWidth: '100%',
        wordBreak: 'break-word',
      }}
      bg="var(--mantine-color-imagine-gray-0)"
      size="md"
      my={4}
    >
      {contentString}
    </Text>
  );
}

export function ChatBubble({ message }: ChatBubbleProps): JSX.Element {
  const contentString = message.payload?.find((p) => p.contentString)?.contentString;
  const contentAttachments = message.payload?.filter((p) => p.contentAttachment).map((p) => p.contentAttachment) || [];
  const contentReferences = message.payload?.filter((p) => p.contentReference).map((p) => p.contentReference) || [];

  if (!contentString && contentAttachments.length === 0 && contentReferences.length === 0) {
    return <></>;
  }

  const isSentByPractitioner = getSentByImaginePediatrics(message);
  const showReadReceipt = isSentByPractitioner && message.received;

  return (
    <Stack
      style={{
        gap: 0,
        alignItems: isSentByPractitioner ? 'end' : 'start',
      }}
      w={400}
    >
      {contentString && isSentByPractitioner && <PractitionerBubble contentString={contentString} />}
      {contentString && !isSentByPractitioner && <CaregiverBubble contentString={contentString} />}
      {contentAttachments.length > 0 &&
        contentAttachments.map((contentAttachment) => {
          const key = contentAttachment?.url?.split('?')[0];
          return (
            contentAttachment && <AttachmentDisplay key={key} value={contentAttachment as Attachment} maxWidth={200} />
          );
        })}
      {contentReferences.length > 0 &&
        contentReferences.map((contentReference) => {
          const documentReference: DocumentReference = contentReference?.resource as DocumentReference;
          const contentAttachment: Attachment = documentReference?.content?.[0].attachment;
          const key = contentAttachment?.url?.split('?')[0];
          return <AttachmentDisplay key={key} value={contentAttachment} maxWidth={200} />;
        })}
      <Group gap="sm">
        <Text size="xs" c="dimmed">
          {formatWithYesterdayAndToday(message.sent!, 'EEE, MMMM do, yyyy hh:mm aaa', true)}
        </Text>
        {showReadReceipt && (
          <Text fw={600} size="xs" c="dimmed">
            Read
          </Text>
        )}
      </Group>
    </Stack>
  );
}
