export { FeatureFlag } from './flags';

export * from './bots';

export {
  ImaginePediatricsCodeSystem,
  OutreachStatus,
  ProgramStatus,
  CallDisposition,
  CommunicationMedium,
  DocumentType,
  documentTypeMap,
  CommunicationCategory,
  CommunicationNoteType,
  CallRoute,
  SYSTEM_URL,
  Extensions,
  Attribution,
  TaskType,
  UserRole,
  UserRoleDisplay,
  ProfileType,
} from './codeSystems/ImaginePediatrics';
export { OutreachCallDisposition } from './valueSets/OutreachCallDisposition';
export { GenderIdentity } from './valueSets/GenderIdentity';
export { PreferredPronoun } from './valueSets/PreferredPronouns';
export { PreferredLanguage } from './valueSets/PreferredLanguage';
export { LivingSituation } from './valueSets/LivingSituation';
export { ContactType } from './valueSets/ContactType';
export { DisengagementReason } from './valueSets/DisengagementReason';
export { AddressType } from './valueSets/AddressType';
export { PhoneStatus } from './valueSets/PhoneStatus';
export { CarePathwayReferralReason } from './valueSets/CarePathwayReferralReason';
export { TaskTypes } from './valueSets/TaskType';
export { TaskPriority } from './valueSets/TaskPriority';
export { postalCodeToPod, podToPostalCodesMap } from './empanelment/postalCodes';
export { codeSystemConcepts, Concepts } from './codeSystems/ImaginePediatrics';

export { selectCodeByDisplay, selectDisplayByCode, extractValues } from './valueSets/selectValue';

export { System } from './codeSystems/system';

export const enum ISOSystem {
  Country = 'urn:iso:std:iso:3166',
  StateJurisdiction = 'urn:iso:std:iso:3166-2',
}

export const enum HL7System {
  ActCode = 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
  ConsentScope = 'http://terminology.hl7.org/CodeSystem/consentscope',
  CoverageClass = 'http://terminology.hl7.org/CodeSystem/coverage-class',
  DisplayCategory = 'http://terminology.hl7.org/CodeSystem/display-category',
  GenderIdentity = 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-genderIdentity',
  HCPCS = 'http://terminology.hl7.org/CodeSystem/HCPCS',
  ICD10 = 'http://hl7.org/fhir/sid/icd-10',
  ICD10CM = 'http://hl7.org/fhir/sid/icd-10-cm',
  InterpreterRequired = 'http://hl7.org/fhir/StructureDefinition/patient-interpreterRequired',
  LivingArrangement = 'http://terminology.hl7.org/CodeSystem/v3-LivingArrangement',
  OrganizationType = 'http://terminology.hl7.org/CodeSystem/organization-type',
  PrapareSurvey = 'http://hl7.org/fhir/us/sdoh-clinicalcare/Questionnaire/SDOHCC-QuestionnairePRAPARE',
  PreferredPronoun = 'http://terminology.hl7.org/ValueSet/pronouns',
  QuestionnaireDisplayCategorySystem = 'http://hl7.org/fhir/StructureDefinition/questionnaire-displayCategory',
  RoleCode = 'http://terminology.hl7.org/CodeSystem/v3-RoleCode',
  StatusReason = 'http://terminology.hl7.org/CodeSystem/status-reason',
  SupersedingTask = 'http://hl7.org/fhir/StructureDefinition/superseding-task',
  TimeZone = 'http://hl7.org/fhir/StructureDefinition/timezone',
  Translation = 'http://hl7.org/fhir/StructureDefinition/translation',
}

export const HL7OrganizationType = {
  InsuranceProvider: {
    system: HL7System.OrganizationType,
    code: 'ins',
    display: 'Insurance Provider',
  },
};

export const LOINCSystem = 'http://loinc.org';

export const enum LOINCSystemCodes {
  PatientConsent = '59284-0',
  ReleaseOfInformation = '64292-6',
  YesAnswerOption = 'LA33-6',
  NoAnswerOption = 'LA32-8',
}

export const enum HL7ValueSet {
  RoleCode = 'http://terminology.hl7.org/ValueSet/v3-RoleCode',
  RelatedPersonRelationshipType = 'http://hl7.org/fhir/ValueSet/relatedperson-relationshiptype',
}

export { default as SubscriptionsExtensions } from './utils/subscriptionsExtensions';

export * from './tagging';
export * from './notifications';

export type Env = 'dev' | 'stage' | 'prod';

// TODO: consider moving these to environment variables
const DEV_PROJECT_ID = '42cfb786-98a8-4a41-acc3-4081f2f7af0f';
const STAGE_PROJECT_ID = 'fb0e477b-f07c-4cc6-aba5-e4ead035f347';
const PROD_PROJECT_ID = 'fc27d50d-623c-48db-aa04-176d9133474e';

export const envToProjectId: Record<Env, string> = Object.freeze({
  dev: DEV_PROJECT_ID,
  stage: STAGE_PROJECT_ID,
  prod: PROD_PROJECT_ID,
});

export const projectIdToEnv: Record<string, Env> = Object.freeze(
  Object.fromEntries(Object.entries(envToProjectId).map(([env, projectId]) => [projectId, env as Env])),
);

export { getIdentifierSearchTerm } from './utils/identifier';
