import React from 'react';
import { Box, Group, Stack, Text } from '@mantine/core';
import { IconPointFilled, IconStethoscope } from '@tabler/icons-react';
import {
  CareTeam,
  PatientAddressAndTelecomFragment,
  CoverageInfoFragment,
  InternalCareTeamFragment,
  Maybe,
  TagsFragment,
} from 'medplum-gql';
import { formatAgeAndDateOfBirth } from 'imagine-dsl/utils/strings';
import { capitalize } from 'lodash';
import { is21OrOlder, pediatricRnFromPatient } from 'imagine-dsl/utils/patient';
import { HumanNameDisplay } from '@medplum/react';
import { HumanName } from '@medplum/fhirtypes';
import { PatientEligibilityBadge } from '../PatientEligibilityBadge';
import { PatientTimelineStepper } from '@/components/patientProfile/programStatus/PatientTimelineStepper';
import { PhoneDisplay } from '../PhoneDisplay';
import { AddressDisplay } from '../AddressDisplay';
import { Notes } from '../notes';
import { AcuityOrCluster } from './AcuityOrCluster';
import { useLineOfBusiness, useHealthPlan } from './hooks';
import { isDefined } from 'imagine-dsl/utils/lists';
import { Over21Badge } from '@/components/Over21Badge';

type Patient = {
  id?: Maybe<string>;
  resourceType: string;
  birthDate?: Maybe<string>;
  CoverageList?: Maybe<Maybe<CoverageInfoFragment>[]>;
  gender?: Maybe<string>;
  internalCareTeam?: Maybe<Maybe<InternalCareTeamFragment>[]>;
  meta?: Maybe<TagsFragment>;
} & PatientAddressAndTelecomFragment;

export interface HeaderDetailsProps {
  patient: Patient;
  withPediatricRn?: boolean;
  withProgramStepper?: boolean;
  withPhoneNumber?: boolean;
  withAddress?: boolean;
  hidePatientNotes?: boolean;
  withPatientNotesCondensed?: boolean;
}

export const HeaderDetails = ({
  patient,
  withPediatricRn,
  withProgramStepper,
  withPhoneNumber,
  withAddress,
  hidePatientNotes,
  withPatientNotesCondensed,
}: HeaderDetailsProps): JSX.Element => {
  const lineOfBusiness = useLineOfBusiness(patient);
  const healthPlan = useHealthPlan(patient?.CoverageList?.filter(isDefined));
  const pediatricRn = pediatricRnFromPatient(patient.internalCareTeam as CareTeam[]);

  if (!patient) {
    return <></>;
  }

  return (
    <>
      <Group>
        <Text>
          {formatAgeAndDateOfBirth(patient?.birthDate)}
          {is21OrOlder(patient.birthDate) && <Over21Badge />}
          {patient.gender ? ` ${capitalize(patient.gender)}` : ''}
        </Text>
        <IconPointFilled size={14} />
        <AcuityOrCluster patient={patient} />
      </Group>
      <Group>
        {withPediatricRn && pediatricRn && (
          <>
            <IconStethoscope size={14} />
            <HumanNameDisplay value={pediatricRn?.name?.[0] as HumanName} />
            {' (RN)'}
          </>
        )}
        {healthPlan?.name && (
          <>
            {withPediatricRn && <IconPointFilled size={14} />}
            <Text>{healthPlan?.name}</Text>
            {lineOfBusiness?.name ? ' | ' : <IconPointFilled size={14} />}
          </>
        )}
        {lineOfBusiness?.name && (
          <>
            <Text>{lineOfBusiness?.name}</Text>
            <IconPointFilled size={14} />
          </>
        )}
        <PatientEligibilityBadge patientTags={patient?.meta?.tag || []} />
      </Group>
      <Stack mt="sm">
        {withProgramStepper && (
          <Box style={{ flex: '7' }}>
            <PatientTimelineStepper patient={patient} />
          </Box>
        )}
        <Stack gap="xs">
          {withPhoneNumber && <PhoneDisplay patient={patient} />}
          {withAddress && <AddressDisplay patient={patient} />}
        </Stack>
        {!hidePatientNotes && !withPatientNotesCondensed && patient.id && (
          <Box ml="auto">
            <Notes patientId={patient.id} />
          </Box>
        )}
      </Stack>
    </>
  );
};
