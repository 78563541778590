import React from 'react';
import { ActionIcon, Box, Group, Overlay, Stack, Text } from '@mantine/core';
import { IconArrowsMaximize } from '@tabler/icons-react';
import VideoFullScreen from '../video/VideoFullScreen';
import { useDisclosure } from '@mantine/hooks';
import { RemoteParticipant } from 'twilio-video';
import { ParticipantVideo } from '../video/participants/ParticipantVideo';
import { ParticipantAudioTracks } from '../video/participants/ParticipantAudioTracks';
import { useVideo } from '../video/VideoProvider';
import DurationDisplay from '../video/DurationDisplay';
import Placeholder from '../video/placeholder/Placeholder';
import VideoControls from '../video/participants/VideoControls';
import { VideoInterfaceWrapper } from './VideoInterfaceWrapper';

const BottomLeft = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return (
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        padding: '1rem',
        color: 'white',
        fontSize: 12,
      }}
    >
      {children}
    </div>
  );
};

export const TwilioInterface = (): JSX.Element | null => {
  const {
    remoteParticipants,
    isBlurOn,
    toggleBlur,
    isCameraOn,
    toggleCamera,
    isMicrophoneOn,
    toggleMicrophone,
    endCall,
    activeTwilioCall,
    callStartTime,
    currentEncounter,
    mainParticipant,
  } = useVideo();

  const [opened, { open, close }] = useDisclosure(false);

  if (!currentEncounter) {
    return null;
  }

  return (
    <>
      <ParticipantAudioTracks participants={remoteParticipants as RemoteParticipant[]} />
      {!opened && (
        <VideoInterfaceWrapper activeCall={activeTwilioCall}>
          {mainParticipant && !opened ? (
            <ParticipantVideo participant={mainParticipant}>
              <Placeholder name={mainParticipant.identity} />
            </ParticipantVideo>
          ) : (
            <div id="video-loading-placeholder" style={{ height: 300 }} />
          )}
          <Overlay style={{ height: '100%', background: 'none' }} color="#000">
            <Stack justify="space-between" style={{ height: '100%', padding: '1rem' }} align="center" gap="xl">
              <Group style={{ width: '100%' }} justify="space-between" align="stretch">
                <Box>
                  <Text
                    size="xs"
                    style={{
                      backgroundColor: 'rgba(0,0,0,.30)',
                      padding: '.125rem .25rem',
                      borderRadius: '.25rem',
                    }}
                    data-testid="chat-title"
                  >
                    Video call with {currentEncounter?.subject?.display}
                  </Text>
                </Box>
                <ActionIcon data-testid="expand-video-btn" onClick={open} variant="transparent">
                  <IconArrowsMaximize color="white" />
                </ActionIcon>
              </Group>
              <VideoControls
                isBlurOn={isBlurOn}
                isCameraOn={isCameraOn}
                isMicrophoneOn={isMicrophoneOn}
                toggleBlur={toggleBlur}
                toggleCamera={toggleCamera}
                toggleMicrophone={toggleMicrophone}
                endCall={endCall}
              />
            </Stack>
            <BottomLeft>
              <DurationDisplay start={callStartTime || new Date()} />
            </BottomLeft>
          </Overlay>
        </VideoInterfaceWrapper>
      )}
      {opened && <VideoFullScreen opened={opened} close={close} />}
    </>
  );
};
