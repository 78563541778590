import {
  Group,
  Input,
  Loader,
  Paper,
  Select,
  MultiSelect,
  Table,
  Text,
  Button,
  ActionIcon,
  Tooltip,
} from '@mantine/core';
import { useDebouncedValue, useDisclosure, useDocumentTitle } from '@mantine/hooks';
import { useMedplum } from '@medplum/react';
import { IconEditCircle, IconPlus, IconSearch, IconSquare, IconSquareCheckFilled } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import {
  Attribution,
  CarePathway,
  OutreachDisposition,
  OutreachStatus,
  ProgramStatus,
  attributionOptions,
  carePathwayOptions,
  outreachDispositionOptions,
  outreachStatusOptions,
  programStatusOptions,
} from 'const-utils/codeSystems/ImaginePediatrics';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useEngagementPodCareTeams } from '@/hooks/useEngagementPodCareTeams';
import { engagementManagerRoles, useHasRole } from '@/hooks/useHasRole';
import { CareTeamType } from 'const-utils';
import { useUserSession } from '@/components/shared/UserSessionContext';
import { PageSkeleton } from '@/design-system/PageSkeleton';
import { PatientRow } from '@/components/patientPanel/PatientRow';
import { SortIcon } from '@/components/shared/SortIcon';
import BulkTaskCreationModal from '@/components/shared/BulkTaskCreationModal';
import { Pagination } from '@/design-system/Pagination';
import { usePagination } from '@/design-system/hooks/usePagination';
import { PanelSortField, PanelSortOrder, useGetOutreachPanelQuery } from 'imagine-gql/client';
import { imagineClient } from '@/hooks/useImagineApolloClient';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import EngagementPodAssignmentModal from '@/components/patientProfile/modals/EngagementPodAssignmentModal';

const PER_PAGE = 20;

export const PatientPanelPage = (): React.ReactNode => {
  useDocumentTitle('Patient Panel');
  const medplum = useMedplum();
  const flags = useFeatureFlags();
  const hasElevatedPrivilege = useHasRole(engagementManagerRoles) || medplum.isProjectAdmin();

  const bulkTaskingEnabled = flags.BulkTaskingAssignOthers || hasElevatedPrivilege;
  const [searchParams, setSearchParams] = useSearchParams();
  const sortInitialized = useRef(false);
  useEffect(() => {
    if (sortInitialized.current) {
      return;
    }
    sortInitialized.current = true;

    if (!searchParams.has('sort')) {
      searchParams.set('sort', 'outreachCount:desc');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const carePathwayFilter = searchParams.get('carePathway') || undefined;
  const attributionFilter = searchParams.get('attribution') || undefined;
  const programStatusFilter = searchParams.get('programStatus') || undefined;
  const outreachStatusFilter = searchParams.get('outreachStatus') || undefined;
  const dispositionFilter = searchParams.get('disposition') || undefined;
  const patientNameSearch = searchParams.get('s') || '';
  const engagementPodCareTeamId = searchParams.get('pod') ?? undefined;
  const myEngagementTeamPodOptions = useMemo(() => ({ skip: hasElevatedPrivilege }), [hasElevatedPrivilege]);
  const myEngagementPodCareTeamId = useMyEngagementTeamPodCareTeamId(myEngagementTeamPodOptions);
  useEffect(() => {
    if (myEngagementPodCareTeamId) {
      setSearchParams((prev) => {
        prev.set('pod', myEngagementPodCareTeamId);
        return prev;
      });
    }
  }, [myEngagementPodCareTeamId, setSearchParams]);

  const engagementPodCareTeamsQuery = useEngagementPodCareTeams();

  const [sortField, sortOrder] = searchParams.get('sort')?.split(':') ?? [];
  const { currentPage: page } = usePagination({});

  const [patientNameSearchDebounced] = useDebouncedValue(patientNameSearch, 500);
  const patientPanelQuery = usePatientPanel({
    engagementPodCareTeamId,
    patientName: patientNameSearchDebounced,
    carePathway: carePathwayFilter as CarePathway | undefined,
    attribution: attributionFilter as Attribution | undefined,
    programStatus: programStatusFilter as ProgramStatus | undefined,
    outreachStatus: outreachStatusFilter as OutreachStatus | undefined,
    dispositions: dispositionFilter?.split(',') as OutreachDisposition[] | undefined,
    sort: {
      field: sortField as PanelSortField,
      order: sortOrder as PanelSortOrder,
    },
    pageSize: PER_PAGE,
    page: page,
  });

  const engagementPodOptions = useMemo(
    () =>
      engagementPodCareTeamsQuery.data?.entry
        ?.map((entry) => ({
          value: entry.resource?.id ?? '',
          label: entry.resource?.name ?? '',
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [engagementPodCareTeamsQuery.data],
  );

  const [selectedPatientIdsMap, setSelectedPatientIdsMap] = useState<Record<string, boolean>>({});
  const selectedPatientIds = useMemo(() => Object.keys(selectedPatientIdsMap), [selectedPatientIdsMap]);
  const [bulkTaskModalOpened, handleBulkTaskModal] = useDisclosure();
  const isLoading = engagementPodCareTeamId && patientPanelQuery.loading;
  const cachedAt = patientPanelQuery.data?.outreachPanels.cachedAt;
  const patientPanel = useMemo(() => patientPanelQuery.data?.outreachPanels.patients ?? [], [patientPanelQuery.data]);
  const selectedCount = useMemo(() => selectedPatientIds.length, [selectedPatientIds]);
  const allSelected = selectedCount === patientPanelQuery.data?.outreachPanels.total;
  const currentPageSelected = useMemo(
    () => patientPanel.every((p) => selectedPatientIdsMap[p.id]),
    [patientPanel, selectedPatientIdsMap],
  );
  const [isPodReassignmentModalOpen, { toggle: togglePodReassignmentModal }] = useDisclosure(false);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [horizontalScrollShadow, setHorizontalScrollShadow] = useState({
    left: false,
    right: false,
  });
  const reconcileHorizontalScrollShadow = useCallback(
    (el: HTMLDivElement) => {
      const hasMoreToLeft = el.scrollLeft !== 0;
      const hasMoreToRight = el.scrollLeft + el.clientWidth <= el.scrollWidth;
      setHorizontalScrollShadow({
        left: hasMoreToLeft,
        right: hasMoreToRight,
      });
    },
    [setHorizontalScrollShadow],
  );
  // sets the initial scroll shadow state once the component mounts and data is loaded
  useEffect(() => {
    if (!scrollContainerRef.current || isLoading) {
      return;
    }

    const el = scrollContainerRef.current.firstChild as HTMLDivElement | null;
    if (!el) {
      return;
    }

    reconcileHorizontalScrollShadow(el);
  }, [isLoading, reconcileHorizontalScrollShadow]);

  const togglePatientSelect = (patientId: string) => {
    const nextMap = { ...selectedPatientIdsMap };
    if (nextMap[patientId]) {
      delete nextMap[patientId];
    } else {
      nextMap[patientId] = true;
    }
    setSelectedPatientIdsMap(nextMap);
  };
  const toggleSelectAllOnPage = () => {
    const nextMap = { ...selectedPatientIdsMap };
    patientPanel.forEach((p) => {
      if (currentPageSelected) {
        delete nextMap[p.id];
      } else {
        nextMap[p.id] = true;
      }
    });
    setSelectedPatientIdsMap(nextMap);
  };
  const toggleSelectAllResult = () => {
    const nextMap = { ...selectedPatientIdsMap };
    patientPanelQuery.data?.outreachPanels.allPatientIds.forEach((id) => {
      if (allSelected) {
        delete nextMap[id];
      } else {
        nextMap[id] = true;
      }
    });
    setSelectedPatientIdsMap(nextMap);
  };

  const createTasksHandler = () => {
    setSelectedPatientIdsMap({});
  };

  const handleBulkTaskModalOpen = async () => {
    handleBulkTaskModal.open();
  };
  const handleBulkTaskModalClose = async () => {
    handleBulkTaskModal.close();
  };

  return (
    <PageSkeleton withPaper={false}>
      {bulkTaskModalOpened && selectedPatientIds.length && (
        <BulkTaskCreationModal
          opened={bulkTaskModalOpened}
          closeModal={handleBulkTaskModalClose}
          onCreationSuccess={createTasksHandler}
          patientIds={selectedPatientIds}
        />
      )}
      {isPodReassignmentModalOpen && (
        <EngagementPodAssignmentModal
          isModalOpen={isPodReassignmentModalOpen}
          setModalOpen={togglePodReassignmentModal}
          patientIds={selectedPatientIds}
          refetch={patientPanelQuery.refetch}
        />
      )}
      <Group mb="xs" justify="space-between">
        <Group>
          <Select
            required
            placeholder="Engagement pod"
            data={engagementPodOptions}
            disabled={!hasElevatedPrivilege}
            value={engagementPodCareTeamId}
            onChange={(v) => {
              if (v) {
                searchParams.set('pod', v);
              } else {
                searchParams.delete('pod');
              }
              setSearchParams(searchParams);
            }}
          />
          <Input
            disabled={!engagementPodCareTeamId}
            value={patientNameSearch}
            onChange={(v) => {
              const search = v.target.value;
              if (search) {
                searchParams.set('s', search);
              } else {
                searchParams.delete('s');
              }
              setSearchParams(searchParams);
            }}
            leftSection={<IconSearch size={18} />}
            placeholder="Search by patient name"
          />
        </Group>
        {cachedAt && (
          <Tooltip label="To optimize performance of this page, some of the data has a maximum 'staleness' of ~5 minutes.">
            <Text size="xs" c="dimmed" maw="23rem">
              last updated {format(cachedAt, 'h:mm:ss a')}
            </Text>
          </Tooltip>
        )}
      </Group>
      <Group mb="md">
        <Select
          maw="200px"
          disabled={!engagementPodCareTeamId}
          label="Care Pathway"
          placeholder="Select to filter"
          data={carePathwayOptions}
          clearable
          searchable
          value={carePathwayFilter ?? ''}
          onChange={(v) => {
            if (v) {
              searchParams.set('carePathway', v);
            } else {
              searchParams.delete('carePathway');
            }
            setSearchParams(searchParams);
          }}
        />
        <Select
          maw="150px"
          disabled={!engagementPodCareTeamId}
          label="Attribution"
          placeholder="Select to filter"
          data={attributionOptions}
          clearable
          searchable
          value={attributionFilter ?? ''}
          onChange={(v) => {
            if (v) {
              searchParams.set('attribution', v);
            } else {
              searchParams.delete('attribution');
            }
            setSearchParams(searchParams);
          }}
        />
        <Select
          maw="150px"
          disabled={!engagementPodCareTeamId}
          label="Program Status"
          placeholder="Select to filter"
          data={programStatusOptions.filter((ps) => ps.value !== ProgramStatus.Onboarded.toString())}
          clearable
          searchable
          value={programStatusFilter ?? ''}
          onChange={(v) => {
            if (v) {
              searchParams.set('programStatus', v);
            } else {
              searchParams.delete('programStatus');
            }
            setSearchParams(searchParams);
          }}
        />
        <Select
          maw="175px"
          disabled={!engagementPodCareTeamId}
          label="Outreach Status"
          placeholder="Select to filter"
          data={outreachStatusOptions}
          clearable
          searchable
          value={outreachStatusFilter ?? ''}
          onChange={(v) => {
            if (v) {
              searchParams.set('outreachStatus', v);
            } else {
              searchParams.delete('outreachStatus');
            }
            setSearchParams(searchParams);
          }}
        />
        <MultiSelect
          style={{ flex: 1 }}
          maw="300px"
          miw="225px"
          disabled={!engagementPodCareTeamId}
          label="Disposition"
          placeholder="Select to filter"
          data={outreachDispositionOptions}
          clearable
          searchable
          value={dispositionFilter ? dispositionFilter.split(',') : []}
          onChange={(v) => {
            if (v) {
              searchParams.set('disposition', v.join(','));
            } else {
              searchParams.delete('disposition');
            }
            setSearchParams(searchParams);
          }}
        />
      </Group>
      <Paper radius="lg" shadow="xs" p="xl" style={{ marginBottom: '20px' }}>
        {isLoading && <Loader />}
        {!engagementPodCareTeamId && <Text c="dimmed">Select an engagement pod</Text>}
        {!isLoading && engagementPodCareTeamId && (
          <>
            <Table.ScrollContainer
              ref={scrollContainerRef}
              minWidth="10rem"
              onScrollCapture={(e) => {
                const el = e.target as HTMLDivElement;
                reconcileHorizontalScrollShadow(el);
              }}
            >
              <Table>
                <Table.Thead>
                  <Table.Tr>
                    {bulkTaskingEnabled && (
                      <Table.Th
                        className={`table-sticky-column-left${horizontalScrollShadow.left ? '-shadow' : ''}`}
                        style={{ position: 'sticky', left: 0, zIndex: 1 }}
                        bg="white"
                      >
                        <ActionIcon onClick={toggleSelectAllOnPage}>
                          {currentPageSelected || allSelected ? <IconSquareCheckFilled /> : <IconSquare color="gray" />}
                        </ActionIcon>
                      </Table.Th>
                    )}
                    <Table.Th
                      className={`table-sticky-column-left${horizontalScrollShadow.left ? '-shadow' : ''}`}
                      miw="250px"
                      style={{ position: 'sticky', left: bulkTaskingEnabled ? 40 : 0, zIndex: 1 }}
                      bg="white"
                    >
                      <Group gap={0}>
                        <Text fw="bold">Patient</Text>
                        <ActionIcon
                          onClick={() => {
                            if (sortField === 'name') {
                              if (sortOrder === 'asc') {
                                searchParams.delete('sort');
                                setSearchParams(searchParams);
                                return;
                              }
                              searchParams.set('sort', 'name:asc');
                              setSearchParams(searchParams);
                            } else {
                              searchParams.set('sort', 'name:desc');
                              setSearchParams(searchParams);
                            }
                          }}
                        >
                          <SortIcon isSortField={sortField === 'name'} sortOrder={sortOrder} />
                        </ActionIcon>
                      </Group>
                    </Table.Th>
                    <Table.Th miw="150px">Attribution</Table.Th>
                    <Table.Th miw="150px">Program Status</Table.Th>
                    <Table.Th miw="150px">Outreach Status</Table.Th>
                    <Table.Th miw="200px">Disposition</Table.Th>
                    <Table.Th miw="200px">Health plan</Table.Th>
                    <Table.Th>Open outreach tasks</Table.Th>
                    <Table.Th miw="175px">
                      <Group gap={0}>
                        <Text fw="bold">Outreach count</Text>
                        <ActionIcon
                          onClick={() => {
                            if (sortField === 'outreachCount') {
                              if (sortOrder === 'asc') {
                                searchParams.delete('sort');
                                setSearchParams(searchParams);
                                return;
                              }
                              searchParams.set('sort', 'outreachCount:asc');
                              setSearchParams(searchParams);
                            } else {
                              searchParams.set('sort', 'outreachCount:desc');
                              setSearchParams(searchParams);
                            }
                          }}
                        >
                          <SortIcon isSortField={sortField === 'outreachCount'} sortOrder={sortOrder} />
                        </ActionIcon>
                      </Group>
                    </Table.Th>
                    <Table.Th miw="200px">
                      <Group gap={0}>
                        <Text fw="bold">Last outreach</Text>
                        <ActionIcon
                          onClick={() => {
                            if (sortField === 'lastOutreach') {
                              if (sortOrder === 'asc') {
                                searchParams.delete('sort');
                                setSearchParams(searchParams);
                                return;
                              }
                              searchParams.set('sort', 'lastOutreach:asc');
                              setSearchParams(searchParams);
                            } else {
                              searchParams.set('sort', 'lastOutreach:desc');
                              setSearchParams(searchParams);
                            }
                          }}
                        >
                          <SortIcon isSortField={sortField === 'lastOutreach'} sortOrder={sortOrder} />
                        </ActionIcon>
                      </Group>
                    </Table.Th>
                    <Table.Th
                      className={`table-sticky-column-right${horizontalScrollShadow.right ? '-shadow' : ''}`}
                      miw="140px"
                      style={{ position: 'sticky', right: 0, zIndex: 1 }}
                      bg="white"
                    >
                      Actions
                    </Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {patientPanel.length > 0 ? (
                    patientPanel.map((patient) => {
                      return (
                        <React.Fragment key={patient.id}>
                          <PatientRow
                            selected={!!selectedPatientIdsMap[patient.id]}
                            data={patient}
                            horizontalScrollShadow={horizontalScrollShadow}
                            onToggleSelect={togglePatientSelect}
                          />
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <Text c="dimmed">No patients found</Text>
                  )}
                </Table.Tbody>
              </Table>
            </Table.ScrollContainer>
            <Group
              justify={bulkTaskingEnabled ? 'space-between' : 'flex-end'}
              align="baseline"
              className="table-sticky-footer"
              bg={'white'}
            >
              <Group>
                {bulkTaskingEnabled && (
                  <Button leftSection={<IconPlus />} disabled={selectedCount === 0} onClick={handleBulkTaskModalOpen}>
                    Create Task
                  </Button>
                )}

                {flags.BulkUpdateEngagementPod &&
                  (selectedCount > 20 ? (
                    <Tooltip label="You can only update the engagement pod for up to 20 patients at a time.">
                      <Button leftSection={<IconEditCircle />} disabled>
                        Update Engagement POD
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      leftSection={<IconEditCircle />}
                      disabled={selectedCount === 0}
                      onClick={togglePodReassignmentModal}
                    >
                      Update Engagement POD
                    </Button>
                  ))}

                {selectedCount > 0 && !allSelected && (
                  <>
                    <Text c="dimmed">
                      <b>{selectedCount}</b> selected
                    </Text>
                    <Button component="a" onClick={toggleSelectAllResult} variant="transparent">
                      {/* biome-ignore lint/a11y/useValidAnchor: <explanation> TODO: determine if we can remove this anchor */}
                      <a>Select all {patientPanelQuery.data?.outreachPanels.total} patients</a>
                    </Button>
                  </>
                )}
                {allSelected && (
                  <>
                    <Text c="dimmed">
                      <b>All {selectedCount}</b> patients selected
                    </Text>
                    <Button component="a" onClick={toggleSelectAllResult} variant="transparent">
                      {/* biome-ignore lint/a11y/useValidAnchor: <explanation> TODO: Determine can we remove the anchor tag?*/}
                      <a>Clear all selected patients</a>
                    </Button>
                  </>
                )}
              </Group>
              <Pagination count={patientPanelQuery.data?.outreachPanels.total ?? 0} perPage={PER_PAGE} />
            </Group>
          </>
        )}
      </Paper>
    </PageSkeleton>
  );
};

type PatientPanelOptions = {
  engagementPodCareTeamId?: string;
  patientName?: string;
  carePathway?: CarePathway;
  attribution?: Attribution;
  programStatus?: ProgramStatus;
  outreachStatus?: OutreachStatus;
  dispositions?: OutreachDisposition[];
  sort?: {
    field: PanelSortField;
    order: PanelSortOrder;
  };
  page: number;
  pageSize: number;
};

const usePatientPanel = ({
  engagementPodCareTeamId,
  patientName,
  carePathway,
  attribution,
  programStatus,
  outreachStatus,
  dispositions,
  sort,
  page,
  pageSize,
}: PatientPanelOptions) => {
  const nameSearch = patientName ? patientName : undefined;
  const carePathwaySearch = carePathway ? carePathway : undefined;
  const attributionSearch = attribution ? attribution : undefined;
  const programStatusSearch = programStatus ? programStatus : undefined;
  const outreachStatusSearch = outreachStatus ? outreachStatus : undefined;
  const dispositionSearch = dispositions ? dispositions : undefined;

  return useGetOutreachPanelQuery({
    variables: {
      input: {
        engagementPodCareTeamId: engagementPodCareTeamId!,
        name: nameSearch,
        carePathway: carePathwaySearch,
        attribution: attributionSearch,
        programStatus: programStatusSearch,
        outreachStatus: outreachStatusSearch,
        disposition: dispositionSearch?.join(','),
        sort: sort && {
          field: sort.field,
          order: sort.order,
        },
        page,
        pageSize,
      },
    },
    client: imagineClient,
    skip: !engagementPodCareTeamId,
  });
};

const useMyEngagementTeamPodCareTeamId = ({ skip }: { skip: boolean }): string | undefined => {
  const [myEngagementPodCareTeamId, setEngagementMyPodCareTeamId] = useState<string>();
  const { profile } = useUserSession();
  const medplum = useMedplum();
  const myEngagementPods = useQuery(
    ['my-engagement-pod-care-teams', profile.id],
    () => {
      return medplum.search('CareTeam', {
        _tag: CareTeamType.EngagementPod,
        participant: `Practitioner/${profile.id}`,
      });
    },
    { enabled: !skip },
  );
  useEffect(() => {
    if (myEngagementPods.data?.entry?.length === 1) {
      setEngagementMyPodCareTeamId(myEngagementPods.data.entry[0].resource?.id);
    }
  }, [myEngagementPods.data]);

  return myEngagementPodCareTeamId;
};
