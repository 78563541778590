import React, { useState } from 'react';
import { Image, Anchor, Box } from '@mantine/core';
import { Attachment } from '@medplum/fhirtypes';
import { useCachedBinaryUrl } from '@medplum/react';
import { IconDownload } from '@tabler/icons-react';
import AttachmentPreviewModal from '../shared/AttachmentPreviewModal';
import { ChatAttachment } from 'imagine-gql/client';

export interface AttachmentDisplayProps {
  readonly value?: Attachment | ChatAttachment;
  readonly maxWidth?: number;
}

const AttachmentDownloadLink: React.FC<{
  url: string;
  title: string | undefined;
}> = ({ url, title }) => {
  return (
    <Box data-testid="download-link" mb="sm">
      <Anchor size="xs" href={url} data-testid="attachment-details" download={title}>
        <IconDownload size={12} /> {title || 'Download'}
      </Anchor>
    </Box>
  );
};

// NOTE: This is largely copied from Medplum's AttachmentDisplay component.
// We add the ability to view images in a modal, and fix the ability to
// play video files inline
export function AttachmentDisplay(props: AttachmentDisplayProps): JSX.Element | null {
  const [previewing, setPreviewing] = useState(false);

  const { contentType, url: uncachedUrl, title } = props.value ?? {};
  const url = useCachedBinaryUrl(uncachedUrl);

  if (!url) {
    return null;
  }

  return (
    <Box my="xs">
      {previewing && (
        <AttachmentPreviewModal
          contentType={contentType}
          url={url}
          title={title ?? ''}
          onClose={() => setPreviewing(false)}
        />
      )}
      {contentType?.startsWith('image/') && (
        <Anchor onClick={() => setPreviewing(true)}>
          <Image
            display="inline"
            radius={8}
            data-testid="attachment-image"
            style={{ maxWidth: props.maxWidth }}
            src={url}
            alt={title || undefined}
          />
        </Anchor>
      )}
      {contentType?.startsWith('video/') && (
        // biome-ignore lint/a11y/useMediaCaption: <explanation> TODO: work on resolving
        <video data-testid="attachment-video" style={{ maxWidth: props.maxWidth }} controls={true}>
          <source src={url} />
        </video>
      )}
      {contentType === 'application/pdf' && (
        <div data-testid="attachment-pdf" style={{ maxWidth: props.maxWidth, minHeight: 400 }}>
          <iframe
            title="pdf attachment"
            width="100%"
            height="400"
            src={`${url}#navpanes=0`}
            allowFullScreen={true}
            frameBorder={0}
            seamless={true}
          />
        </div>
      )}
      <AttachmentDownloadLink url={url} title={title || undefined} />
    </Box>
  );
}
