import { FeatureFlag } from 'const-utils';

type LocalFeatures = Record<FeatureFlag, 'on' | 'off'>;

export interface Config {
  baseUrl?: string;
  sentryUrl?: string;
  sentryEnabled: boolean;
  appEnv: 'local' | 'dev' | 'stage' | 'prod';
  clientId: string;
  externalAuthorizeUrl: string;
  externalClientId: string;
  graphqlUrl?: string;
  projectId: string;
  webAppRedirectUri: string;
  pusherAppKey: string;
  splitSdkKey?: string;
  splitWorkspaceId?: string;
  splitFeatures?: LocalFeatures;
  demoPatient?: string;
  enableAutomation: boolean;
  commitHash: string;
  posthogApiKey?: string;
  posthogApiHost?: string;
}

export const appEnv = import.meta.env.VITE_APP_ENV || 'local';
export const posthogApiKey = appEnv === 'dev' && import.meta.env.VITE_POSTHOG_API_KEY;

export const posthogApiHost = import.meta.env.VITE_POSTHOG_API_HOST;

// Set local feature flags here
const localFeatures: LocalFeatures = {
  [FeatureFlag.TranslateMessage]: 'on',
  [FeatureFlag.TestFlag]: 'off',
  [FeatureFlag.ChatGQLQuery]: 'off',
  [FeatureFlag.PathwayReviewOwners]: 'on',
  [FeatureFlag.ConsentUpload]: 'on',
  [FeatureFlag.EngagementPodReassignment]: 'on',
  [FeatureFlag.DisasterPreparednessSurvey]: 'on',
  [FeatureFlag.BulkTaskingAssignOthers]: 'on',
  [FeatureFlag.TaskDueDateFilter]: 'on',
  [FeatureFlag.BulkUpdateEngagementPod]: 'on',
  [FeatureFlag.QuestionnaireConsents]: 'on',
  [FeatureFlag.PatientProfileOpenTasks]: 'on',
  [FeatureFlag.DyteVideoProvider]: 'off',
  [FeatureFlag.OutreachStatusPebble]: 'on',
};

const config: Config = {
  appEnv,
  sentryUrl: import.meta.env.VITE_SENTRY_URL,
  sentryEnabled: import.meta.env.VITE_SENTRY_ENABLED?.toString() === 'true',
  baseUrl: import.meta.env.VITE_MEDPLUM_BASE_URL,
  clientId: import.meta.env.VITE_MEDPLUM_CLIENT_ID,
  externalAuthorizeUrl: import.meta.env.VITE_EXTERNAL_AUTHORIZE_URL,
  externalClientId: import.meta.env.VITE_EXTERNAL_CLIENT_ID,
  graphqlUrl: import.meta.env.VITE_MEDPLUM_GRAPHQL_URL,
  projectId: import.meta.env.VITE_MEDPLUM_PROJECT_ID,
  webAppRedirectUri: import.meta.env.VITE_WEB_APP_REDIRECT_URI,
  pusherAppKey: import.meta.env.VITE_PUSHER_APP_KEY,
  splitSdkKey: import.meta.env.VITE_SPLIT_SDK_KEY,
  splitWorkspaceId: import.meta.env.VITE_SPLIT_WORKSPACE_ID,
  splitFeatures: appEnv === 'local' ? localFeatures : undefined,
  enableAutomation: import.meta.env.VITE_ENABLE_AUTOMATION || false,
  commitHash: import.meta.env.VITE_COMMIT_HASH,
  demoPatient: import.meta.env.VITE_DEMO_PATIENT,
  posthogApiKey,
  posthogApiHost,
};

export function getConfig(): Config {
  return config;
}
