import { QuestionnaireResponse, QuestionnaireResponseItem } from '@medplum/fhirtypes';
import { System } from 'const-utils';
import { Maybe } from 'medplum-gql';

export const getResponseItemByLinkId = (
  response: Maybe<QuestionnaireResponse> | undefined,
  linkId: string,
): QuestionnaireResponseItem[] => {
  return response?.item?.filter((i) => i.linkId === linkId) ?? [];
};

export const getPreviousItemsResponseLength = (
  response: Maybe<QuestionnaireResponse> | undefined,
  items: QuestionnaireResponseItem[],
  index: number,
): number => {
  return index === 0
    ? 0
    : (
        response?.item?.filter((i) =>
          items
            .slice(0, index)
            .map((i) => i.linkId)
            .includes(i.linkId),
        ) ?? []
      ).flatMap((i) => i.item)?.length;
};

export const isBulletDisplayType = (item: QuestionnaireResponseItem): boolean | undefined => {
  return item.extension?.some(
    (extension) =>
      extension.url === (System.QuestionnaireItemDisplayType as string) && extension.valueCode === 'bullet',
  );
};
