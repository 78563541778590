import { logError } from '@/errors';
import { notifications } from '@mantine/notifications';
import { formatHumanName } from '@medplum/core';
import { HumanName, Questionnaire, QuestionnaireItem, QuestionnaireResponse } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';
import { System } from 'const-utils';
import {
  PreferredLanguage,
  QuestionnaireResponseCategory,
  QuestionnaireType,
} from 'const-utils/codeSystems/ImaginePediatrics';
import { Maybe, useGetPatientQuery } from 'medplum-gql';
import { TranslationCodeMapping, translateQuestionnaire } from 'imagine-dsl/services/questionnaireService';
import React, { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { SurveyReviewTask } from 'imagine-dsl/models/tasks/surveyReviewTask';

interface QuestionnaireResponseProviderProps {
  patientId: string;
  surveyTask?: SurveyReviewTask;
  initialQuestionnaireResponse?: QuestionnaireResponse;
}
interface QuestionnaireResponseContext {
  items: QuestionnaireItem[];
  translatedItemMapping: TranslationCodeMapping[];
  questionnaireResponse?: Maybe<QuestionnaireResponse>;
  questionnaire?: Maybe<Questionnaire>;
  responseCategory: string;
  patientPreferredLanguage: string;
  patientName: string;
  questionnaireResponseLoading: boolean;
  surveyTask?: SurveyReviewTask;
}

const QuestionnaireResponseContextValue = createContext<QuestionnaireResponseContext | undefined>(undefined);

export const useQuestionnaireResponseContext = (): QuestionnaireResponseContext => {
  const value = useContext(QuestionnaireResponseContextValue);
  if (!value) {
    throw new Error('No QuestionnaireResponseContextValue');
  }
  return value;
};

export function QuestionnaireResponseProvider(
  props: PropsWithChildren<QuestionnaireResponseProviderProps>,
): JSX.Element {
  const { children, patientId, surveyTask, initialQuestionnaireResponse } = props;
  const medplum = useMedplum();
  const [translatedItemMapping, setTranslatedItemMapping] = useState<TranslationCodeMapping[]>([]);
  const [questionnaire, setQuestionnaire] = useState<Questionnaire | null>(null);
  const [questionnaireResponse, setQuestionnaireResponse] = useState<QuestionnaireResponse | null>(null);
  const [questionnaireResponseLoading, setQuestionnaireResponseLoading] = useState(false);

  const { data: patientData } = useGetPatientQuery({
    variables: { id: patientId },
  });

  const patientPreferredLanguage = patientData?.Patient?.communication?.[0]?.language?.coding?.[0]?.code || 'en';

  useEffect(() => {
    const getQuestionnaire = async (): Promise<void> => {
      const quetionnaireQueryResult = await medplum.readResource(
        'Questionnaire',
        initialQuestionnaireResponse?.questionnaire?.split('/')[1] as string,
      );

      if (quetionnaireQueryResult) {
        setQuestionnaire(quetionnaireQueryResult as Questionnaire);
        setQuestionnaireResponse(initialQuestionnaireResponse!);
        setQuestionnaireResponseLoading(false);
      }
    };

    if (initialQuestionnaireResponse) {
      setQuestionnaireResponseLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getQuestionnaire();
    }

    if (!surveyTask) {
      return;
    }

    translateQuestionnaire(medplum, {
      toLanguage: PreferredLanguage.En,
      questionnaireTags: surveyTask.questionnaireTags,
      needsTranslation: !surveyTask.isSurveyWithTranslations,
    })
      .then((translatedItemMapping) => {
        setTranslatedItemMapping(translatedItemMapping);
      })
      .catch((error) => {
        logError('Error translating questionnaire', error);
      });
  }, [medplum, patientPreferredLanguage, surveyTask, initialQuestionnaireResponse]);

  useEffect(() => {
    (async () => {
      if (!surveyTask) {
        return {};
      }
      const questionnaireTags = surveyTask.questionnaireTags;
      const questionnaireTagString = questionnaireTags.map((tag) => `_tag=${tag}`).join('&');

      //TODO: need to search by the questionnaire id or tag when we don't need to translate and add a language tag
      //Will need to update this to get the tag from the response, or include it in results
      const questionnaireResult = surveyTask.isSurveyWithTranslations
        ? await medplum.searchOne('Questionnaire', `status=active&_tag=${QuestionnaireType.DisasterPreparedness}`)
        : await medplum.searchOne(
            'Questionnaire',
            `${questionnaireTagString}&status=active&_tag=${System.QuestionnaireLanguage}|en`,
          );

      const questionnaireResponseId = surveyTask?.focus?.id;

      setQuestionnaireResponseLoading(true);
      const questionnaireResponseResult =
        questionnaireResponseId && (await medplum.readResource('QuestionnaireResponse', questionnaireResponseId));

      return { questionnaireResult, questionnaireResponseResult };
    })()
      .then(({ questionnaireResult, questionnaireResponseResult }) => {
        if (!questionnaireResult || !questionnaireResponseResult) {
          return;
        }
        setQuestionnaireResponse(questionnaireResponseResult as QuestionnaireResponse);
        setQuestionnaire(questionnaireResult as Questionnaire);
        setQuestionnaireResponseLoading(false);
      })
      .catch((error) => {
        notifications.show({ color: 'status-error', title: 'Error fetching questionnaire', message: error.message });
        setQuestionnaireResponseLoading(false);
      });
  }, [patientId, medplum, patientPreferredLanguage, surveyTask]);

  const patientName = patientData?.Patient?.name?.[0]
    ? formatHumanName(patientData?.Patient?.name?.[0] as HumanName)
    : '';

  const responseCategory = useMemo(() => {
    if (!questionnaireResponse?.extension) {
      return 'pending score';
    }

    const responseCategories = questionnaireResponse.extension.filter(
      (ext) => ext.url === System.QuestionnaireResponseCategory.toString(),
    );

    if (responseCategories.some((ext) => ext.valueString === QuestionnaireResponseCategory.SuicideRisk)) {
      return QuestionnaireResponseCategory.SuicideRisk;
    }

    return responseCategories[0]?.valueString || 'pending score';
  }, [questionnaireResponse]);

  const value = {
    translatedItemMapping,
    items: questionnaire?.item ?? [],
    questionnaire,
    questionnaireResponse,
    responseCategory,
    patientPreferredLanguage,
    patientName,
    questionnaireResponseLoading,
    surveyTask,
  };

  return (
    <QuestionnaireResponseContextValue.Provider value={value}>{children}</QuestionnaireResponseContextValue.Provider>
  );
}
