import { logError } from '@/errors';
import { localStorageKeys } from '@/localAndSessionStorage';
import { useLocalStorage } from '@mantine/hooks';
import { ContentType, decodeBase64, encodeBase64 } from '@medplum/core';
import { useMedplum } from '@medplum/react';
import React, { createContext, useContext, useEffect, useMemo } from 'react';

type ProfilePreferences = {
  patientHeaderDetailsCollapsed: boolean;
  verticalNavCollapsed: boolean;
  // TODO: add release notes notifications enabled, last seen version id, last notified version id, etc. once that work is merged
};

const defaultProfilePreferences: ProfilePreferences = {
  patientHeaderDetailsCollapsed: true,
  verticalNavCollapsed: true,
};

const ProfilePreferencesContext = createContext<{
  preferences: ProfilePreferences;
  setter: (val: ProfilePreferences | ((prevState: ProfilePreferences) => ProfilePreferences)) => void;
}>({
  preferences: defaultProfilePreferences,
  setter: () => {},
});

export const useProfilePreferences = () => {
  const context = useContext(ProfilePreferencesContext);
  return useMemo(() => [context.preferences, context.setter] as const, [context]);
};

export const ProfilePreferencesProvider = ({ children }: { children: React.ReactNode }) => {
  const medplum = useMedplum();
  const [profilePreferences, setProfilePreferences] = useLocalStorage<ProfilePreferences>({
    key: localStorageKeys.profilePreferences,
    defaultValue: defaultProfilePreferences,
    serialize: JSON.stringify,
    deserialize: (v) => (v ? JSON.parse(v) : defaultProfilePreferences),
  });

  useEffect(() => {
    let ignore = false;

    medplum.keyValue
      .get('profilePreferences')
      .then((value) => {
        if (!value || ignore) {
          return;
        }

        const result = JSON.parse(decodeBase64(value));
        setProfilePreferences(result);
      })
      .catch((err) => logError(err));

    return () => {
      ignore = true;
    };
  }, [medplum, setProfilePreferences]);

  useEffect(() => {
    if (!profilePreferences) {
      return;
    }

    // TODO: use medplum.keyValue.set once content type is fixed https://github.com/medplum/medplum/pull/4575
    medplum
      .put('keyvalue/v1/profilePreferences', encodeBase64(JSON.stringify(profilePreferences)), ContentType.TEXT)
      .catch((err) => logError(err));
  }, [medplum, profilePreferences]);

  const value = useMemo(
    () => ({
      preferences: profilePreferences,
      setter: setProfilePreferences,
    }),
    [profilePreferences, setProfilePreferences],
  );

  return <ProfilePreferencesContext.Provider value={value}>{children}</ProfilePreferencesContext.Provider>;
};
