import { Avatar, Badge, Box, Card, Group, Text, ThemeIcon } from '@mantine/core';
import { IconChevronRight, IconAlertTriangle } from '@tabler/icons-react';
import React from 'react';
import { BaseTask } from 'imagine-dsl/models/tasks/baseTask';
import { PriorityBadge } from '@/components/tasks/PriorityBadge';
import { format } from 'date-fns';
import { getInitials } from 'imagine-dsl/utils/strings';
import { Practitioner } from '@medplum/fhirtypes';
import { capitalize } from '@medplum/core';
import { System } from 'const-utils';

interface TasksCardProps {
  task: BaseTask;
  onClick: () => void;
}
function formatTaskName(taskName: string, subtaskType?: string): string {
  const formattedTaskName = taskName
    .split('-')
    .map((word) => {
      if (word.length === 2) {
        return word.toUpperCase();
      }
      return capitalize(word);
    })
    .join(' ');

  if (subtaskType) {
    const formattedSubtaskType = subtaskType.toUpperCase();
    return `${formattedTaskName} (${formattedSubtaskType})`;
  }

  return formattedTaskName;
}

export const TasksCard = ({ task, onClick }: TasksCardProps) => {
  const assignedTo = task?.owner as Practitioner;
  const [name] = assignedTo?.name || [];
  const nameDisplay = name ? `${assignedTo?.name?.[0]?.given} ${assignedTo?.name?.[0]?.family}` : '';
  const taskName = task?.task?.meta?.tag?.find((tag) => tag.system === System.TaskType)?.code;
  const subtaskType = task?.task?.meta?.tag?.find((tag) => tag.system === System.BHSurveyType)?.code;
  const formattedTaskName = formatTaskName(taskName || '', subtaskType || '');

  return (
    <Card shadow="sm" withBorder radius="md" onClick={onClick} style={{ cursor: 'pointer' }}>
      <Group justify="space-between">
        <Text fw={700}>{formattedTaskName}</Text>
        <Box>
          <Group align="center">
            <Box>
              {task.owner && (
                <Avatar size="sm" radius="xl" bg="imagine-green.6" color="white">
                  {assignedTo?.name?.[0] && getInitials(nameDisplay)}
                </Avatar>
              )}
            </Box>
            <Box>{task.priority && <PriorityBadge priority={task.priority} />}</Box>
            <Box>
              {task.dueDate && (
                <Badge color={task.isOverdue ? 'orange' : 'indigo'} variant="filled" p="sm">
                  {task.isOverdue ? (
                    <Group align="center" gap="xs">
                      <IconAlertTriangle size={16} />
                      {`Overdue ${format(task.dueDate, 'P')}`}
                    </Group>
                  ) : (
                    `Due ${format(task.dueDate, 'P')}`
                  )}
                </Badge>
              )}
            </Box>

            <ThemeIcon color="imagine-green" variant="transparent" mr={5}>
              <IconChevronRight size={20} />
            </ThemeIcon>
          </Group>
        </Box>
      </Group>
    </Card>
  );
};
