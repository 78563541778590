import React from 'react';
import { ClusterBadge } from '@/components/ClusterBadge';
import { ClinicalImpression } from '@medplum/fhirtypes';
import { clusterFromClinicalImpressions, getCarePathway } from 'imagine-dsl/utils/clinicalImpression';
import { useHasOpenCarePathwayReferralReviewTask, useManagingOrganization } from './hooks';
import { CarePathway, MarketIdentifier } from 'const-utils/codeSystems/ImaginePediatrics';
import { marketCarePathwayReferralOwnerMap } from 'imagine-dsl/utils/task';
import { CarePathwayReferralModal } from '@/components/patientProfile/modals/CarePathwayReferralModal';
import { useDisclosure } from '@mantine/hooks';
import { System } from 'const-utils';
import { logError } from '@/errors';
import CarePathwayInfoModal from '@/components/patientProfile/modals/CarePathwayInfoModal';
import { ActionIcon, Button, Tooltip } from '@mantine/core';
import { IconEditCircle, IconInfoCircle } from '@tabler/icons-react';
import { PatientWithCluster, PatientWithManagingOrg } from 'imagine-dsl/utils/types/patient';

interface AcuityOrClusterProps {
  patient: PatientWithManagingOrg & PatientWithCluster;
}

export const AcuityOrCluster = ({ patient }: AcuityOrClusterProps): JSX.Element => {
  const hasOpenCarePathwayReferralReviewTask = useHasOpenCarePathwayReferralReviewTask(patient?.id);
  const managingOrganization = useManagingOrganization(patient);

  const managingOrgIdentifier = managingOrganization?.identifier?.find((i) => i.system === System.Organization)
    ?.value as MarketIdentifier | undefined;

  const hasCarePathwayReferralReviewOwnerMapping =
    managingOrgIdentifier && managingOrgIdentifier in marketCarePathwayReferralOwnerMap;

  const [carePathwayDetailsOpened, { close: closeCarePathwayDetails, open: openCarePathwayDetails }] = useDisclosure();
  const [carePathwayOverridesOpened, { close: closeCarePathwayOverrides, open: openCarePathwayOverrides }] =
    useDisclosure(false);

  const carePathway = getCarePathway(patient?.ClinicalImpressionList as ClinicalImpression[]);

  if (!patient) {
    return <></>;
  }

  const clusterFromClinicalImpression = clusterFromClinicalImpressions(
    patient.ClinicalImpressionList as ClinicalImpression[],
  );

  if (!clusterFromClinicalImpression) {
    return (
      <ClusterBadge cluster={'Unknown'} isOpenCarePathwayReferralReview={hasOpenCarePathwayReferralReviewTask.data} />
    );
  }

  return (
    <>
      <CarePathwayReferralModal
        isOpen={carePathwayOverridesOpened}
        onClose={closeCarePathwayOverrides}
        refetch={async () => {
          await hasOpenCarePathwayReferralReviewTask.refetch().catch(logError);
        }}
        cluster={clusterFromClinicalImpression}
        carePathway={carePathway}
        patientId={patient.id!}
      />
      <CarePathwayInfoModal
        key={patient?.id}
        impressions={patient.ClinicalImpressionList as ClinicalImpression[]}
        isModalOpen={carePathwayDetailsOpened}
        setModalOpen={carePathwayDetailsOpened ? closeCarePathwayDetails : openCarePathwayDetails}
        setCarePathwayEditModalOpen={openCarePathwayOverrides}
        hasOpenCarePathwayReferralReviewTask={hasOpenCarePathwayReferralReviewTask}
        carePathway={carePathway}
        patientId={patient.id || ''}
      />
      <ClusterBadge
        cluster={clusterFromClinicalImpression}
        carePathway={carePathway}
        isOpenCarePathwayReferralReview={hasOpenCarePathwayReferralReviewTask.data}
      />
      <Tooltip
        disabled={hasCarePathwayReferralReviewOwnerMapping}
        label="Unable to request care pathway referral for patient in this market"
      >
        <ActionIcon
          onClick={openCarePathwayOverrides}
          disabled={
            hasOpenCarePathwayReferralReviewTask.data ||
            !hasCarePathwayReferralReviewOwnerMapping ||
            carePathway === CarePathway.AcuteCare
          }
        >
          <IconEditCircle size={20} />
        </ActionIcon>
      </Tooltip>
      <Button
        onClick={openCarePathwayDetails}
        leftSection={<IconInfoCircle size={14} />}
        variant="transparent"
        p={0}
        h="md"
      >
        Details
      </Button>
    </>
  );
};
