import { MedplumClient, ProfileResource, parseReference } from '@medplum/core';
import { Patient, Practitioner, Task } from '@medplum/fhirtypes';
import { System, TaskType } from 'const-utils';
import { PractitionerInfoFragment } from 'medplum-gql';
import { isNil, sample } from 'lodash';
import { ResourceBuilderParams } from './resource';
import { CarePathway, MarketIdentifier } from 'const-utils/codeSystems/ImaginePediatrics';
import { isAfter } from 'date-fns';
import { Owner } from './types/task';

export const countUnassignedTasks = (taskOwners: (PractitionerInfoFragment | Owner | undefined | null)[]): number => {
  return taskOwners.filter((o) => isNil(o)).length;
};

export const countTasksAssignedToProfile = (ownerIds: string[], profile: ProfileResource): number => {
  return ownerIds.reduce((count, ownerId) => count + (ownerId === profile?.id ? 1 : 0), 0);
};

export const hasOverdueAssignedTasks = (dueDates: string[]): boolean => {
  return dueDates.some((d) => isAfter(new Date(), d));
};

type ManagedFields = {
  for: NonNullable<Task['for']>;
  type: TaskType;
  intent?: Task['intent'];
};

type CreateTaskParams = ResourceBuilderParams<Task, ManagedFields>;

/**
 *
 * @param args - the details of the task to create
 * @param args.for - the resource the task is for
 * @param args.type - the type of task which will be used to tag the task
 * @param args.intent - the intent of the task, defaults to 'order'
 * @returns a task object that can be used to create a task resource
 */
export const buildTask = (args: CreateTaskParams): Task => {
  const { for: forResource, focus, intent, type, ...rest } = args;

  const now = new Date().toISOString();
  return {
    resourceType: 'Task',
    authoredOn: now,
    ...rest,
    for: forResource,
    intent: intent || 'order',
    focus,
    lastModified: now,
    executionPeriod: { start: now },
    meta: {
      ...(args.meta ?? {}),
      tag: [
        { code: type, display: type, system: System.TaskType },
        ...(args.meta?.tag ?? []).filter((t) => t.system !== System.TaskType),
      ],
    },
  };
};
type OutreachManagedFields = {
  forPatientId: string;
  assignToPractitionerId: string;
  requestedByPractitionerId: string;
  dueDate: string;
  taskType: { code: string; display: string };
  businessStatus: string;
  notes?: string;
  priority?: 'routine' | 'urgent' | 'asap' | 'stat' | undefined;
  id?: string;
  createdDate?: string;
  bulkTask?: boolean;
};
/**
 *
 * @param args - the details of the outreach task to create
 * @param args.forPatientId - the patient the task is for
 * @param args.assignToPractitionerId - the practitioner the task is assigned to
 * @param args.requestedByPractitionerId - the practitioner who requested the task
 * @param args.dueDate - the date the task is due
 * @param args.taskType - the type of task which will be used to tag the task
 * @param args.businessStatus - the status of the task
 * @param args.notes - any notes to add to the task
 * @param args.priority - the priority of the task
 * @param args.id - the id of the task to update - if not provided it will create a new task
 * @param args.createdDate - the date the task was originally created
 * @param args.bulkTask - whether the task is part of a bulk task creation
 *
 * @returns a task object that can be used to create an outreach task resource
 */
export const buildOutreachTask = (args: OutreachManagedFields): Task => {
  const {
    forPatientId,
    assignToPractitionerId,
    requestedByPractitionerId,
    dueDate,
    taskType,
    businessStatus,
    notes,
    priority,
    id,
    createdDate,
    bulkTask = false,
  } = args;
  const noteToAdd = notes
    ? {
        note: [
          {
            text: notes,
          },
        ],
      }
    : undefined;

  const bulkTaskTag = bulkTask
    ? [
        {
          system: System.BulkTask,
          code: 'bulkTask',
          display: 'Created as part of a bulk task creation',
        },
      ]
    : [];

  return {
    id: id,
    resourceType: 'Task',
    status: 'ready',
    priority: priority,
    intent: 'order',
    for: {
      reference: `Patient/${forPatientId}`,
    },
    owner: {
      reference: `Practitioner/${assignToPractitionerId}`,
    },
    code: {
      coding: [
        {
          system: System.TaskType,
          ...taskType,
        },
      ],
    },
    meta: {
      tag: [
        {
          system: System.TaskCategory,
          code: 'outreach',
        },
        {
          system: System.TaskType,
          ...taskType,
        },
        ...bulkTaskTag,
      ],
    },
    requester: {
      reference: `Practitioner/${requestedByPractitionerId}`,
    },
    businessStatus: {
      coding: [
        {
          system: System.TaskPriority,
          code: businessStatus,
        },
      ],
    },
    authoredOn: createdDate,
    lastModified: id ? new Date().toISOString() : createdDate,
    restriction: {
      period: {
        end: dueDate,
      },
    },
    executionPeriod: {
      start: createdDate,
    },
    ...noteToAdd,
  };
};

export const carePathwayReferralReviewOwnerEmails = [
  'mlevine@imaginepediatrics.org',
  'dramospatel@imaginepediatrics.org',
  'azuckerman@imaginepediatrics.org',
  'bupadhyay@imaginepediatrics.org',
  'czuniga@imaginepediatrics.org',
  'placeholder',
] as const;
type CarePathwayReferralReviewOwnerEmail = (typeof carePathwayReferralReviewOwnerEmails)[number];

export const marketCarePathwayReferralOwnerMap: Record<
  MarketIdentifier,
  Record<CarePathway, CarePathwayReferralReviewOwnerEmail>
> = Object.freeze({
  [MarketIdentifier.Texas]: Object.freeze({
    [CarePathway.BehavioralHealth]: 'mlevine@imaginepediatrics.org',
    [CarePathway.CoreCare]: 'czuniga@imaginepediatrics.org',
    [CarePathway.Medical]: 'czuniga@imaginepediatrics.org',
    [CarePathway.AcuteCare]: 'czuniga@imaginepediatrics.org',
  }),
  [MarketIdentifier.Florida]: Object.freeze({
    [CarePathway.BehavioralHealth]: 'mlevine@imaginepediatrics.org',
    [CarePathway.CoreCare]: 'bupadhyay@imaginepediatrics.org',
    [CarePathway.Medical]: 'bupadhyay@imaginepediatrics.org',
    [CarePathway.AcuteCare]: 'czuniga@imaginepediatrics.org',
  }),
  [MarketIdentifier.WashingtonDC]: Object.freeze({
    [CarePathway.BehavioralHealth]: 'czuniga@imaginepediatrics.org',
    [CarePathway.CoreCare]: 'czuniga@imaginepediatrics.org',
    [CarePathway.Medical]: 'czuniga@imaginepediatrics.org',
    [CarePathway.AcuteCare]: 'czuniga@imaginepediatrics.org',
  }),
  //TODO: Implement these email values when they become known
  [MarketIdentifier.NewYork]: Object.freeze({
    [CarePathway.BehavioralHealth]: 'placeholder',
    [CarePathway.CoreCare]: 'placeholder',
    [CarePathway.Medical]: 'placeholder',
    [CarePathway.AcuteCare]: 'placeholder',
  }),
});

/**
 * getCarePathwayReviewTaskOwner determines the Practitioner resource that will be assigned the care pathway referral review task
 * based on the patient's managing organization and the requested care pathway
 *
 * @param medplum - MedplumClient
 * @param patient - the Patient resource for which the care pathway referral request is for
 * @param requestedCarePathway - the requested care pathway
 * @returns Promise<Practitioner> the Practitioner resource that will be assigned the care pathway referral review task
 */
export const getCarePathwayReviewTaskOwner = async (
  medplum: MedplumClient,
  patient: Patient,
  requestedCarePathway: CarePathway,
): Promise<Practitioner> => {
  const [, id] = parseReference(patient.managingOrganization);
  const managingOrg = await medplum.readResource('Organization', id);
  if (!managingOrg) {
    throw new Error('Could not determine managing organization for patient');
  }

  const managingOrgIdentifier = managingOrg?.identifier?.find((i) => i.system === System.Organization)?.value as
    | MarketIdentifier
    | undefined;
  if (!managingOrgIdentifier) {
    throw new Error('Could not determine managing organization identifier for patient');
  }

  const marketOwners = marketCarePathwayReferralOwnerMap[managingOrgIdentifier];
  if (!marketOwners) {
    throw new Error(`Could not determine market owners for managing organization ${managingOrgIdentifier}`);
  }

  const ownerEmail = marketOwners[requestedCarePathway] ?? sample(carePathwayReferralReviewOwnerEmails);

  const practitioner = await medplum.searchOne('Practitioner', `telecom=${ownerEmail}`);
  if (!practitioner) {
    throw new Error(`Could not find practitioner to assign care pathway referral review task with email ${ownerEmail}`);
  }

  return practitioner;
};

/**
 * Determines the priority of a task based on the number of days since a reference date.
 *
 * @param dateDifference - The number of days between the current date and a reference date (e.g., encounter end date)
 * @returns The priority of the task: 'urgent', 'asap', or 'routine'
 *
 * Priority levels:
 * - 'urgent': 0-7 days (high)
 * - 'asap': 8-30 days (medium)
 * - 'routine': 31+ days (low)
 */
export const getTaskPriority = (dateDifference: number): string => {
  if (dateDifference >= 0 && dateDifference <= 7) {
    return 'high';
  } else if (dateDifference > 7 && dateDifference <= 30) {
    return 'medium';
  } else {
    return 'low';
  }
};

export const generateTaskFilter = (
  status: 'incomplete' | 'complete',
  patientRef: string,
  marketsString: string,
  ownerFilter: string,
  dueDateStart?: string,
  dueDateEnd?: string,
): string => {
  const statusFilter =
    status === 'incomplete'
      ? 'status ne completed,rejected,accepted,cancelled'
      : 'status eq completed,rejected,accepted,cancelled';

  const patientFilter = patientRef ? ` and subject re ${patientRef}` : '';
  const dueDateFilter =
    dueDateStart && dueDateEnd ? ` and due-date ge ${dueDateStart} and due-date le ${dueDateEnd}` : '';

  return `${statusFilter} and patient.organization re ${marketsString}${patientFilter}${ownerFilter}${dueDateFilter}`;
};
