import { ActionIcon, Modal, Tabs, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React, { PropsWithChildren } from 'react';
import { CareTeamMemberRole } from 'const-utils/codeSystems/ImaginePediatrics';
import { IconUserShare } from '@tabler/icons-react';
import SaveInternalCareTeamMember from './SaveInternalCareTeamMember';
import SaveExternalCareTeamMember from './SaveExternalCareTeamMember';
import { ApolloQueryResult } from '@apollo/client';
import { GetPatientQuery } from 'medplum-gql';

export enum ShowOptions {
  Both = 0,
  Internal = 1,
  External = 2,
}

interface ManageCareTeamIconActionProps {
  internalCareTeamId?: string;
  externalCareTeamId?: string;
  previousMemberId?: string;
  patientId: string;
  practitionerId?: string;
  show: ShowOptions;
  disabledTooltip?: string;
  role?: CareTeamMemberRole;
  refetch: () => Promise<ApolloQueryResult<GetPatientQuery>>;
}

export default function ManageCareTeamIconAction({
  children,
  patientId,
  internalCareTeamId,
  externalCareTeamId,
  previousMemberId,
  role,
  refetch,
  show,
  practitionerId,
  disabledTooltip,
}: PropsWithChildren<ManageCareTeamIconActionProps>): JSX.Element {
  const [opened, { open, close }] = useDisclosure();

  const defaultTab = show === ShowOptions.External ? 'external' : 'internal';

  return (
    <>
      {disabledTooltip ? (
        <Tooltip label={disabledTooltip}>
          <span>
            <ActionIcon bg="imagine-green.0" p={0} disabled>
              {children}
            </ActionIcon>
          </span>
        </Tooltip>
      ) : (
        <ActionIcon bg="imagine-green.0" p={0} onClick={open}>
          {children}
        </ActionIcon>
      )}
      <Modal.Root size="lg" padding="xl" radius="xl" centered opened={opened} onClose={close}>
        <Modal.Overlay />
        <Modal.Content style={{ overflow: 'visible' }}>
          <Modal.Header style={{ borderRadius: '100px' }}>
            <Modal.Title fz={20} c="imagine-green">
              Save Care Team Member
            </Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <Tabs defaultValue={defaultTab} variant="outline">
              {show === ShowOptions.Both && (
                <>
                  <Tabs.List>
                    <Tabs.Tab value="internal">Internal Care Team</Tabs.Tab>
                    <Tabs.Tab value="external" leftSection={<IconUserShare />}>
                      External Care Team
                    </Tabs.Tab>
                  </Tabs.List>
                  <br />
                </>
              )}
              <Tabs.Panel value="internal">
                <SaveInternalCareTeamMember
                  cancel={close}
                  refetch={refetch}
                  patientId={patientId}
                  careTeamId={internalCareTeamId}
                  role={role}
                  previousMemberId={previousMemberId}
                />
              </Tabs.Panel>
              <Tabs.Panel value="external">
                <SaveExternalCareTeamMember
                  cancel={close}
                  refetch={refetch}
                  patientId={patientId}
                  careTeamId={externalCareTeamId}
                  role={role}
                  previousMemberId={previousMemberId}
                  practitionerId={practitionerId}
                />
              </Tabs.Panel>
              <br />
            </Tabs>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
}
