import React from 'react';
import { createRoot } from 'react-dom/client';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { getConfig } from './config';
import { App } from './App';
import * as Sentry from '@sentry/react';
import { addFetchInstrumentationHandler } from '@sentry/core';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import './global.css';

const config = getConfig();

Sentry.init({
  environment: config.appEnv,
  enabled: config.sentryEnabled,
  dsn: config.sentryUrl,
  release: config.commitHash,
  integrations: [
    Sentry.httpClientIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracePropagationTargets: [
    /^https?:\/\/localhost/,
    'https://api.medplum.com',
    'https://care.dev.imaginepediatrics.org',
    'https://care.stage.imaginepediatrics.org',
    'https://care.imaginepediatrics.org',
  ],
  tracesSampleRate: 1.0,
});

// This must come after Sentry initialization to ensure that Sentry attaches the trace id prior to this handler being executed
addFetchInstrumentationHandler((data) => {
  const request = data.args[1];
  const traceId = request?.headers?.['sentry-trace'] as string | undefined;

  if (typeof request === 'object' && 'headers' in request && typeof request.headers === 'object' && traceId) {
    // Sentry trace id is almost a traceparent format but without the version so we need to add it
    // future-proof ourselves by checking if the traceId already has the version
    const traceparent = traceId.startsWith('00-') ? traceId : `00-${traceId}`;
    request.headers['traceparent'] = traceparent;
  }
});

const container = document.getElementById('root') as HTMLDivElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
