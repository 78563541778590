import React from 'react';
import { Text, Box, Group, Paper, Stack, Title, Divider } from '@mantine/core';
import { useOutreach } from '../Context';
import { IconAlertTriangleFilled, IconCheck } from '@tabler/icons-react';
import { AppDownloadStep } from './AppDownloadStep';
import { SendCredentialsStep } from './SendCredentialsStep';
import { CheckConsentsStep } from './CheckConsentsStep';
import { TestMessageStep } from './TestMessageStep';
import { TestVideoCallStep } from './TestVideoCallStep';
import { ScheduleOnboardingStep } from './ScheduleOnboardingStep';
import cx from 'clsx';
import classes from './Enroll.module.css';

enum EnrollStepStatus {
  Pending = 'pending',
  Complete = 'complete',
  Incomplete = 'incomplete',
}

interface EnrollStepProps {
  index: number;
  activeIndex: number;
  number: number;
  title: string;
  status?: EnrollStepStatus;
  children: React.ReactNode;
}

const EnrollStepSection = ({
  index,
  activeIndex,
  number,
  title,
  status: statusProp,
  children,
}: EnrollStepProps): JSX.Element => {
  const active = index === activeIndex;
  const status = statusProp || (index < activeIndex ? EnrollStepStatus.Complete : EnrollStepStatus.Pending);

  return (
    <Stack my="md">
      <Group gap="xs">
        <Box
          className={cx(classes.numberIcon, {
            [classes.pendingIcon]: !active && status === EnrollStepStatus.Pending,
            [classes.incompleteIcon]: !active && status === EnrollStepStatus.Incomplete,
          })}
        >
          {status === EnrollStepStatus.Complete && <IconCheck size={16} />}
          {status === EnrollStepStatus.Pending && number}
          {status === EnrollStepStatus.Incomplete && <IconAlertTriangleFilled size={16} />}
        </Box>
        <Box>
          <Title
            order={4}
            className={cx({
              [classes.pendingText]: !active && status === EnrollStepStatus.Pending,
              [classes.activeText]: active,
            })}
          >
            {title}
          </Title>
          {status === EnrollStepStatus.Incomplete && (
            <Text size="xs" c="gray">
              Not complete
            </Text>
          )}
        </Box>
      </Group>
      {active && children}
    </Stack>
  );
};

export const Enroll = (): JSX.Element => {
  const { appDownloadLinkSentAt, enrollStepIndex, testMessageSentAt, videoCallTestedAt, isCaregiverOnboarded } =
    useOutreach();

  let appDownloadLinkStatus: EnrollStepStatus | undefined = undefined;
  let testMessageStatus: EnrollStepStatus | undefined = undefined;
  let testVideoCallStatus: EnrollStepStatus | undefined = undefined;

  if (!appDownloadLinkSentAt && enrollStepIndex > 0 && !isCaregiverOnboarded) {
    appDownloadLinkStatus = EnrollStepStatus.Incomplete;
  }

  if (!testMessageSentAt && enrollStepIndex > 3) {
    testMessageStatus = EnrollStepStatus.Incomplete;
  }

  if (!videoCallTestedAt && enrollStepIndex > 4) {
    testVideoCallStatus = EnrollStepStatus.Incomplete;
  }

  const steps = [
    { title: 'Send app download link', status: appDownloadLinkStatus, component: <AppDownloadStep /> },
    { title: 'Send temporary password', component: <SendCredentialsStep /> },
    { title: 'Check consents', component: <CheckConsentsStep /> },
    { title: 'Test message', status: testMessageStatus, component: <TestMessageStep /> },
    { title: 'Test video call', status: testVideoCallStatus, component: <TestVideoCallStep /> },
    { title: 'Schedule onboarding', component: <ScheduleOnboardingStep /> },
  ];

  return (
    <Paper shadow="xs" p="md">
      <Title c="imagine-green" fw="normal" mb="xl" order={3} size="26px">
        Enroll
      </Title>

      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <EnrollStepSection
            key={index}
            index={index}
            status={step.status}
            activeIndex={enrollStepIndex}
            number={index + 1}
            title={step.title}
          >
            {step.component}
          </EnrollStepSection>
          {index < steps.length - 1 ? <Divider /> : null}
        </React.Fragment>
      ))}
    </Paper>
  );
};

export default Enroll;
