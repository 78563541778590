import { ContactReviewForm } from '@/components/shared/ContactReviewForm';
import { hydrateContactReviewForm, isDuplicatePhoneNumber } from '@/utils/contactReviewUtils';
import { useForm, UseFormReturnType } from '@mantine/form';
import { GetPatientQuery } from 'medplum-gql';
import { validateEmail } from 'imagine-dsl/utils/strings';
import { isValidPhoneNumber } from 'libphonenumber-js';

interface UseContactReviewFormInput {
  patient: GetPatientQuery['Patient'];
  contactFirstName: string;
  contactLastName: string;
}

export const useContactReviewForm = ({
  patient,
  contactFirstName,
  contactLastName,
}: UseContactReviewFormInput): UseFormReturnType<ContactReviewForm> => {
  const initialForm = hydrateContactReviewForm(patient, {
    firstNameOverride: contactFirstName,
    lastNameOverride: contactLastName,
  });

  const form = useForm<ContactReviewForm>({
    initialValues: initialForm,
    validateInputOnBlur: true,
    validate: {
      address: {
        line1: (value) => (value ? null : 'Address line 1 is required'),
        city: (value) => (value ? null : 'City is required'),
        state: (value) => (value ? null : 'State is required'),
        zip: (value) => (value ? null : 'Zip code is required'),
      },
      contact: {
        firstName: (value) => (value ? null : 'First name is required'),
        lastName: (value) => (value ? null : 'Last name is required'),
        emailAddress: (value) => {
          if (!value) {
            return null;
          }
          return validateEmail(value) ? null : 'Invalid email address';
        },
        relationship: (value) => {
          return value ? null : 'Relationship is required';
        },
        phoneNumbers: {
          number: (value, values, path) => {
            if (!value) {
              return 'Phone number is required';
            }

            if (!isValidPhoneNumber(value, 'US')) {
              return 'Invalid phone number';
            }

            const valueIndex = Number.parseInt(path.replace(/[^0-9]/g, ''), 10);

            if (isDuplicatePhoneNumber(value, values.contact.phoneNumbers, valueIndex)) {
              return 'Duplicate phone number';
            }

            return null;
          },
        },
        address: {
          line1: (value, values) => (value || values.contact.usePatientAddress ? null : 'Address line 1 is required'),
          city: (value, values) => (value || values.contact.usePatientAddress ? null : 'City is required'),
          state: (value, values) => (value || values.contact.usePatientAddress ? null : 'State is required'),
          zip: (value, values) => (value || values.contact.usePatientAddress ? null : 'Zip code is required'),
        },
      },
    },
  });

  return form;
};
