import { isOk, MedplumClient, parseReference, PatchOperation } from '@medplum/core';
import { Bundle, BundleEntry, ClinicalImpression, CodeableConcept, Practitioner, Task } from '@medplum/fhirtypes';
import { System } from 'const-utils';
import {
  CarePathway,
  carePathwayRanking,
  PractitionerRoleCode,
  TaskType,
} from 'const-utils/codeSystems/ImaginePediatrics';
import { compact } from 'lodash';
import { isDefined } from '../utils/lists';
import { isAfter } from 'date-fns';
import { isA } from '../utils/resource';
import { CarePathwayReferralTask } from '../models/tasks/carePathwayReferralTask';

/**
 *
 * @param medplum - medplum client
 * @param task - the task to get the reviewers for
 * @returns valid reviewers for the task based on organization, care pathway, and having practitioner role MD
 */
export const getCarePathwayReviewers = async (medplum: MedplumClient, task: Task): Promise<Practitioner[]> => {
  const patientRef = task.for;
  if (!patientRef) {
    throw new Error(`Task: ${task.id} does not have a patient reference`);
  }

  const patient = await medplum.readResource('Patient', parseReference(patientRef).at(1)!);
  const organizationRef = patient.managingOrganization;

  if (!organizationRef) {
    throw new Error(`Patient: ${patient.id} does not have a managing organization. Task id: ${task.id}`);
  }

  const toPathway = task.input?.find((i) => i.type.coding?.some((c) => c.system === System.CarePathway))?.valueString;
  const fromPathway = task.extension?.find((ext) => ext.url === System.OriginalCarePathwayValue.toString())
    ?.valueString;

  if (!toPathway) {
    throw new Error(`Task: ${task.id} does not have a referred care pathway input`);
  }

  if (!fromPathway) {
    throw new Error(`Task: ${task.id} does not have an original care pathway`);
  }

  const pathwayToAssignFrom =
    carePathwayRanking[fromPathway as CarePathway] > carePathwayRanking[toPathway as CarePathway]
      ? fromPathway
      : toPathway;

  const query = {
    _filter: `role eq ${pathwayToAssignFrom} and role eq ${PractitionerRoleCode.MedicalDirector}`,
    organization: organizationRef.reference,
    active: true,
    _include: 'PractitionerRole:practitioner',
  };

  const carePathwayReviewersBundle: Bundle = await medplum.search('PractitionerRole', query);

  if (!carePathwayReviewersBundle.entry) {
    throw new Error(`No reviewers found for task: ${task.id}`);
  }

  const reviewers = compact(carePathwayReviewersBundle.entry)
    .filter(isDefined)
    .map((entry) => entry.resource)
    .filter(isDefined)
    .filter(isA('Practitioner'));

  const practitionerRoles = compact(
    carePathwayReviewersBundle.entry.map((e) => e.resource).filter(isA('PractitionerRole')),
  );

  return reviewers.filter((practitioner) => {
    const matchingRole = practitionerRoles.find(
      (pr) => pr.practitioner?.reference === `Practitioner/${practitioner.id}`,
    );
    const endDate = matchingRole?.period?.end;

    if (!matchingRole && !endDate) {
      return false;
    }

    if (!endDate) {
      return true;
    }

    return isAfter(endDate, new Date());
  });
};

interface ResolveCarePathwayReferralArgs {
  action: 'approve' | 'deny';
  task: CarePathwayReferralTask;
  actor?: Practitioner;
  reason?: string;
  note?: string;
}

/**
 *
 * @param medplum - Medplum client
 * @param args - Arguments
 * @param args.action - Action to take
 * @param args.task - Task to resolve
 * @param args.actor - Actor resolving the task
 * @param args.reason - Reason for rejection
 * @param args.note - Note to add to the task
 *
 * Updates the task status to 'approved' or 'rejected'
 * If approved, Updates the clinical impression and closes other open referral tasks for the patient
 * If rejected, closes the task and updates the status reason with notes
 */
export const resolveCarePathwayReferral = async (
  medplum: MedplumClient,
  args: ResolveCarePathwayReferralArgs,
): Promise<void> => {
  const { action, task, actor, reason, note } = args;
  const patientId = task.for?.id;

  if (!task.id || !patientId) {
    return;
  }

  const periodOp: PatchOperation = {
    op: 'add',
    path: '/executionPeriod/end',
    value: new Date().toISOString(),
  };

  const noteOp: PatchOperation = {
    op: 'add',
    path: '/extension/-',
    value: { url: System.CarePathwayReferralReviewNote.toString(), valueString: note },
  };
  const statusOp: PatchOperation = {
    op: 'replace',
    path: '/status',
    value: action === 'approve' ? 'accepted' : 'rejected',
  };

  const lastModifiedOp: PatchOperation = {
    op: 'add',
    path: '/lastModified',
    value: new Date().toISOString(),
  };

  const patchOperations: PatchOperation[] = [statusOp, periodOp, lastModifiedOp];
  if (actor) {
    const ownerOp: PatchOperation = {
      op: 'add',
      path: '/owner',
      value: { reference: `Practitioner/${actor.id}` },
    };
    patchOperations.push(ownerOp);
  }

  if (note) {
    patchOperations.push(noteOp);
  }

  if (action === 'deny') {
    const statusReasonOp: PatchOperation = {
      op: 'add',
      path: '/statusReason',
      value: {
        coding: [
          {
            system: System.CarePathwayReferralDenialReason,
          },
        ],
        text: reason,
      } satisfies CodeableConcept,
    };
    patchOperations.push(statusReasonOp);
  }

  const bundleEntries: BundleEntry[] = [
    {
      request: {
        method: 'PATCH',
        url: `Task/${task.id}`,
      },
      resource: {
        resourceType: 'Binary',
        contentType: 'application/json-patch+json',
        data: btoa(JSON.stringify(patchOperations)),
      },
    },
  ];

  if (action === 'approve') {
    const clinicalImpression = await medplum.searchOne(
      'ClinicalImpression',
      `subject=Patient/${patientId}&identifier=${System.Cluster}|`,
    );

    if (!clinicalImpression) {
      return;
    }

    const clinicalImpressionExtensionUpdateOperation = buildClinicalImpressionBundleEntry(clinicalImpression, task);

    bundleEntries.push(clinicalImpressionExtensionUpdateOperation);

    const cancellationBundleEntries = await buildCancellationBundleEntries(medplum, task);
    bundleEntries.push(...cancellationBundleEntries);
  }

  const bundle: Bundle = {
    resourceType: 'Bundle',
    type: 'transaction',
    entry: bundleEntries,
  };

  const result = await medplum.executeBatch(bundle);
  const errors = [];
  for (const entry of result.entry ?? []) {
    if (entry.response?.outcome && !isOk(entry.response.outcome)) {
      errors.push(entry.response.outcome);
    }
  }

  if (errors.length > 0) {
    throw new Error('There was an error processing the care pathway referral review' + JSON.stringify(errors));
  }
};

const buildCancellationBundleEntries = async (
  medplum: MedplumClient,
  task: CarePathwayReferralTask,
): Promise<BundleEntry[]> => {
  if (!task.for) {
    return [];
  }

  const otherOpenTasksBundle = await medplum.search(
    'Task',
    `patient=Patient/${task.for.id}&_id:not=${task.id}&status=requested,ready,received&_tag=${TaskType.CarePathwayReferralReview}`,
  );

  if (!otherOpenTasksBundle.entry) {
    return [];
  }

  const tasks = otherOpenTasksBundle.entry.map((entry) => entry.resource).filter(isDefined);

  return tasks.map((t) => {
    const statusReasonOp: PatchOperation = {
      op: 'add',
      path: '/statusReason',
      value: {
        coding: [
          {
            system: System.CarePathwayReferralCancellationReason,
            extension: [
              {
                url: System.CarePathwayReferralCancellationReason,
                valueReference: { reference: `Task/${task.id}` },
              },
            ],
          },
        ],
        text: 'Task cancelled due to another referral being approved',
      } satisfies CodeableConcept,
    };

    return {
      request: {
        method: 'PATCH',
        url: `Task/${t.id}`,
      },
      resource: {
        resourceType: 'Binary',
        contentType: 'application/json-patch+json',
        data: btoa(
          JSON.stringify([
            {
              op: 'replace',
              path: '/status',
              value: 'cancelled',
            },
            statusReasonOp,
          ]),
        ),
      },
    };
  });
};

const buildClinicalImpressionBundleEntry = (
  clinicalImpression: ClinicalImpression,
  task: CarePathwayReferralTask,
): BundleEntry => {
  const existingExtensionIndex =
    clinicalImpression.extension?.findIndex((ext) => ext.url === System.CarePathwayReferralOverride.toString()) ?? -1;

  const carePathwayOverrideExtension = {
    url: System.CarePathwayReferralOverride,
    valueString: task?.inputBySystem(System.CarePathway)?.valueString ?? undefined,
  };

  return {
    request: {
      method: 'PATCH',
      url: `ClinicalImpression/${clinicalImpression.id}`,
    },
    resource: {
      resourceType: 'Binary',
      contentType: 'application/json-patch+json',
      data: btoa(
        JSON.stringify(
          existingExtensionIndex >= 0
            ? [
                {
                  op: 'replace',
                  path: `/extension/${existingExtensionIndex}`,
                  value: carePathwayOverrideExtension,
                },
              ]
            : [
                {
                  op: 'add',
                  path: '/extension',
                  value: Array.isArray(clinicalImpression.extension)
                    ? carePathwayOverrideExtension
                    : [carePathwayOverrideExtension],
                },
              ],
        ),
      ),
    },
  };
};
