import { Text, Button, Stack } from '@mantine/core';
import { IconMessage } from '@tabler/icons-react';
import React from 'react';
import { useOutreach } from '../Context';
import { sendTestMessage } from '../handlers/testMessage';
import { InAppPreview } from '../InAppPreview';
import { SuccessMessage } from '../SuccessMessage';
import { useMedplum } from '@medplum/react';
import appImage from '@assets/images/in-app-previews/test-message.png';
import { useUserSession } from '@/components/shared/UserSessionContext';

export const TestMessageStep = (): JSX.Element => {
  const medplum = useMedplum();
  const { profile } = useUserSession();
  const { patient, wrapHandler, error, loading, setTestMessageSentAt, testMessageSentAt } = useOutreach();

  const handleTestMessage = async (): Promise<void> =>
    wrapHandler(async () => {
      const { success } = await sendTestMessage(medplum, patient, profile);

      if (success) {
        setTestMessageSentAt(new Date());
      } else {
        throw new Error('Test message failed to send');
      }
    });

  return (
    <InAppPreview imageUrl={appImage}>
      <Stack gap="md">
        <Button
          name="send-test-message"
          loading={loading}
          disabled={!!testMessageSentAt}
          leftSection={<IconMessage />}
          onClick={handleTestMessage}
          variant="outline"
        >
          Send test message
        </Button>
        <Stack gap="xs">
          {error && <Text c="red">{error}</Text>}
          {testMessageSentAt && (
            <SuccessMessage>
              Test message sent on: <strong>{testMessageSentAt.toLocaleString()}</strong>
            </SuccessMessage>
          )}
        </Stack>
      </Stack>
    </InAppPreview>
  );
};
