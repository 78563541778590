import React, { useMemo, useState } from 'react';
import { Text, Table, Group, Stack, Button, Loader, ThemeIcon, UnstyledButton } from '@mantine/core';
import { ADTEncounterActivity } from './types';
import { IconBell, IconChevronDown, IconChevronRight } from '@tabler/icons-react';
import { BHTOCDrawerContent } from '../../tasks/drawer/bhTOC/DrawerContent';
import { useGetLatestBhtocTaskForPatientByFocusQuery } from 'medplum-gql';
import { BaseTask } from 'imagine-dsl/models/tasks/baseTask';
import { format } from 'date-fns';
import { ExpandableDrawer } from '@/design-system/ExpandableDrawer';
import { useDisclosure } from '@mantine/hooks';

interface ADTActivityItemProps {
  encounter: ADTEncounterActivity;
  patientId: string;
}

export const ADTActivityItem = ({ encounter, patientId }: ADTActivityItemProps): JSX.Element => {
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [opened, disclosure] = useDisclosure();

  const { data, loading, refetch } = useGetLatestBhtocTaskForPatientByFocusQuery({
    variables: { patientId: patientId, focus: `Encounter/${encounter.id}` },
  });

  const task = useMemo(() => data?.TaskList?.[0] && new BaseTask(data?.TaskList?.[0]), [data]);

  const openDrawer = () => {
    setDrawerOpened(true);
  };

  const closeDrawer = () => {
    setDrawerOpened(false);
  };

  let viewAlertButton = null;
  if (loading) {
    viewAlertButton = <Loader />;
  } else if (task) {
    viewAlertButton = (
      <Button h={25} w={100} fz={12} onClick={openDrawer} variant="outline">
        View alert
      </Button>
    );
  }

  return (
    <>
      <Table.Tr style={{ verticalAlign: 'top' }} key={encounter.id}>
        <Table.Td>
          <Stack justify="flex-start" mt="25px">
            <ThemeIcon p={2} display="flex" bd="1px solid red" radius="100%" c="red">
              <IconBell size={16} />
            </ThemeIcon>
          </Stack>
        </Table.Td>
        <Table.Td>
          <Stack gap={10}>
            {encounter.start && (
              <Text size="xs" c="imagine-gray">
                {format(new Date(encounter.start), 'EEE, MMMM d, yyyy hh:mm a')}
              </Text>
            )}
            <Group gap={4}>
              <Text fw="bold" size="sm">
                ADT Alert:{' '}
              </Text>
              <Text size="sm" fw="bold">
                {encounter.description}
              </Text>
            </Group>
            <Group>
              {viewAlertButton}
              <UnstyledButton c="imagine-green" onClick={() => disclosure.toggle()}>
                <Group>
                  {opened ? <IconChevronDown size={15} /> : <IconChevronRight size={15} />}
                  <Text fz="xs">{opened ? 'Hide details' : 'View details'}</Text>
                </Group>
              </UnstyledButton>
            </Group>
            {opened && (
              <Table>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>Admit date</Table.Th>
                    <Table.Th>Discharge date</Table.Th>
                    <Table.Th>Location</Table.Th>
                    <Table.Th>Diagnosis</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  <Table.Tr key={encounter.id}>
                    <Table.Td>
                      <Text fs={encounter.start ? undefined : 'italic'} c={encounter.start ? undefined : 'dimmed'}>
                        {encounter.start ?? 'Missing'}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Text fs={encounter.end ? undefined : 'italic'} c={encounter.end ? undefined : 'dimmed'}>
                        {encounter.end ?? 'Missing'}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Text fs={location ? undefined : 'italic'} c={location ? undefined : 'dimmed'}>
                        {encounter.location ?? 'Missing'}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Text>{encounter.diagnosis}</Text>
                    </Table.Td>
                  </Table.Tr>
                </Table.Tbody>
              </Table>
            )}
          </Stack>
        </Table.Td>
      </Table.Tr>

      {task && (
        <ExpandableDrawer opened={drawerOpened} close={closeDrawer} id="task-drawer" size="xxl">
          <BHTOCDrawerContent
            task={task}
            patientId={patientId}
            withViewPatientProfile={true}
            drawerExpanded={drawerOpened}
            setDrawerExpanded={setDrawerOpened}
            closeDrawer={closeDrawer}
            refetchTasks={refetch}
          />
        </ExpandableDrawer>
      )}
    </>
  );
};
