import React, { useMemo } from 'react';
import { QuestionnaireItem, QuestionnaireResponseItem } from '@medplum/fhirtypes';
import { QuestionnaireItemType } from 'const-utils/codeSystems/ImaginePediatrics';
import { ReadOnlyQuestionnaireFormItem } from './QuestionnaireFormItem';
import { Box, List, Text } from '@mantine/core';
import { TranslationCodeMapping, translatedDisplayByCode } from 'imagine-dsl/services/questionnaireService';
import { isNumber } from 'lodash';
import { isBulletDisplayType } from './utils';

interface readOnlyQuestionnaireRepeatableItemProps {
  readonly item: QuestionnaireItem;
  readonly response?: QuestionnaireResponseItem;
  translatedItemMapping: TranslationCodeMapping[];
  index?: number;
}

export function ReadOnlyQuestionnaireRepeatableItem(
  props: readOnlyQuestionnaireRepeatableItemProps,
): JSX.Element | null {
  const { item, response, translatedItemMapping, index } = props;

  const number = useMemo(() => getNumberOfRepeats(item, response ?? { linkId: item.linkId }), [item, response]);

  if (!response) {
    return null;
  }

  if (item.type === QuestionnaireItemType.display) {
    const itemCode = item.code?.find((code) => code.system === 'http://loinc.org');
    const display =
      itemCode?.code && translatedItemMapping.length > 0
        ? translatedDisplayByCode(translatedItemMapping, itemCode.code)
        : item.text;
    return (
      <p key={item.linkId}>
        {isBulletDisplayType(item) && '• '}
        {display}
      </p>
    );
  }

  // Styling reason to avoid duplicate text
  if (item.type === QuestionnaireItemType.boolean) {
    return (
      <ReadOnlyQuestionnaireFormItem
        translatedItemMapping={translatedItemMapping}
        key={item.linkId}
        item={item}
        response={response}
        index={0}
      />
    );
  }

  return (
    <Box mt="md">
      <List size="xs" withPadding>
        <List.Item icon={isNumber(index) ? <Text>{index + 1}.</Text> : undefined}>
          <Text fw={700}>{item.text}</Text>
        </List.Item>
      </List>
      {[...Array(number)].map((_, index) => (
        <ReadOnlyQuestionnaireFormItem
          translatedItemMapping={translatedItemMapping}
          key={`${item.linkId}-${index}`}
          item={item}
          response={response}
          index={index}
        />
      ))}
    </Box>
  );
}

function getNumberOfRepeats(item: QuestionnaireItem, response: QuestionnaireResponseItem): number {
  if (item.type === QuestionnaireItemType.choice || item.type === QuestionnaireItemType.openChoice) {
    return 1;
  }
  const answers = response.answer;
  return answers?.length ? answers.length : 1;
}
