import { SearchPatientQueryVariables, useSearchPatientQuery } from 'medplum-gql';
import { omitBy, isUndefined, isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { EmptyState } from '@shared/search/EmptyState';
import { SearchForm } from './patientSearch/SearchForm';
import { ResultTable } from './patientSearch/ResultTable';
import { buildSortString } from 'imagine-dsl/utils/search';
import { cleanPhoneNumber } from 'imagine-dsl/utils/strings';
import { useSearchParams } from 'react-router-dom';
import { Paper, Title, rem } from '@mantine/core';
import { useRecentPatients } from '@/hooks/usePatientList';
import { Pagination } from '@/design-system/Pagination';
import { usePagination } from '@/design-system/hooks/usePagination';
import { format } from 'date-fns';

const DEFAULT_EMPTY_STATE_TEXT =
  'Use the search tools above to search for a patient by name, date of birth and/or phone number.';
const NO_RESULTS_TEXT = 'No results were found. Try changing the search criteria.';

const PER_PAGE = 20;

const getQueryParamsFromSearchParams = (
  searchParams: URLSearchParams,
  recentPatients: string[],
  page: number,
): SearchPatientQueryVariables => {
  const status = searchParams.get('status');
  const attribution = searchParams.get('attribution');

  const tags = ['patient'];

  if (status) {
    tags.push(status);
  }

  if (attribution) {
    tags.push(attribution);
  }

  const offset = (page - 1) * PER_PAGE;
  const phone = searchParams.get('phone');

  if (searchParams.get('submit') !== 'true') {
    return {
      id: recentPatients.join(','),
      count: searchParams.get('count') ? Number(searchParams.get('count')) : PER_PAGE,
      sort: searchParams.get('sort') || undefined,
      filter: tags.map((tag) => `_tag eq ${tag}`).join(' and '),
      offset,
    };
  }

  return omitBy(
    {
      name: searchParams.get('name') || undefined,
      birthdate: searchParams.get('birthdate') || undefined,
      phone: phone ? cleanPhoneNumber(phone) : undefined,
      count: searchParams.get('count') ? Number(searchParams.get('count')) : PER_PAGE,
      sort: searchParams.get('sort') || undefined,
      address: searchParams.get('address') || undefined,
      filter: tags.map((tag) => `_tag eq ${tag}`).join(' and '),
      offset,
    },
    isUndefined,
  );
};

export function PatientSearch(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();
  const { patientIds: recentPatients } = useRecentPatients();
  const { currentPage } = usePagination({});

  const showingRecentPatients = !searchParams.get('submit') && recentPatients.length > 0;

  const { data, loading } = useSearchPatientQuery({
    skip: !searchParams.get('submit') && !(recentPatients.length > 0),
    variables: getQueryParamsFromSearchParams(searchParams, recentPatients, currentPage) || {},
  });

  const sortedData = useMemo(() => {
    if (!showingRecentPatients) {
      return data;
    }

    const sortedEdges = [...(data?.PatientConnection?.edges || [])].sort((a, b) => {
      const aIndex = recentPatients.indexOf(a?.resource?.id || '');
      const bIndex = recentPatients.indexOf(b?.resource?.id || '');
      return aIndex - bIndex;
    });

    return {
      ...data,
      PatientConnection: {
        ...data?.PatientConnection,
        edges: sortedEdges,
      },
    };
  }, [data, recentPatients, showingRecentPatients]);

  const resetForm = (): void => {
    setSearchParams({});
  };

  const resetPage = (): void => {
    searchParams.delete('page');
  };

  const onSubmit = (formData: SearchPatientQueryVariables): void => {
    Object.entries(formData).forEach(([key, value]) => {
      if (value) {
        let transformedValue = value.toString();
        if (key === 'name') {
          transformedValue = transformedValue.replaceAll(',', '');
        }
        if (key === 'birthdate') {
          transformedValue = format(new Date(value), 'yyyy-MM-dd');
        }
        searchParams.set(key, transformedValue);
      } else {
        searchParams.delete(key);
      }
    });

    searchParams.set('submit', 'true');
    setSearchParams(searchParams);
  };

  const sort = (field?: string, sortString?: string): void => {
    if (sortString && field) {
      searchParams.set('sort', buildSortString(searchParams.get('sort') || '', field, sortString));
    } else {
      searchParams.delete('sort');
    }
    resetPage();
    setSearchParams(searchParams);
  };

  const filter = (field: string, value?: string): void => {
    if (value) {
      searchParams.set(field, value);
    } else {
      searchParams.delete(field);
    }
    resetPage();
    setSearchParams(searchParams);
  };

  const initialFilterValues = {
    name: searchParams.get('name') || '',
    birthdate: searchParams.get('birthdate') || '',
    phone: searchParams.get('phone') || '',
  };

  return (
    <>
      <SearchForm initialValues={initialFilterValues} submit={onSubmit} reset={resetForm} />
      <Paper mt="xl" radius="lg" shadow="xs" p="xl" style={{ marginBottom: '20px' }}>
        {showingRecentPatients && (
          <Title size={rem(22)} mt={rem(20)}>
            Recently viewed
          </Title>
        )}
        <ResultTable sort={sort} loading={loading} data={sortedData} filter={filter} />
        <EmptyState
          data={data?.PatientConnection?.edges}
          searchPerformed={data?.PatientConnection?.count === 0}
          loading={loading}
          emptyStateText={DEFAULT_EMPTY_STATE_TEXT}
          noResultsText={NO_RESULTS_TEXT}
        />
        {!loading && !isEmpty(data?.PatientConnection?.edges) && (
          <Pagination perPage={PER_PAGE} count={sortedData?.PatientConnection?.count || 0} />
        )}
      </Paper>
    </>
  );
}
