import { Questionnaire, QuestionnaireResponse } from '@medplum/fhirtypes';
import { QuestionnaireForm, useMedplum } from '@medplum/react';
import { QuestionnaireType } from 'const-utils/codeSystems/ImaginePediatrics';
import React, { useCallback, useEffect, useState } from 'react';
import { logError } from '@/errors';
import { useVideo } from './VideoProvider';
import classes from './PostVideoSurvey.module.css';
import { Button, Flex, Text, Title } from '@mantine/core';
import CarehubWhiteLogo from '@assets/logo/hub-white.svg';

export const PostVideoSurvey = () => {
  const medplum = useMedplum();
  const { setActiveDyteCall } = useVideo();
  const [videoSurvey, setVideoSurvey] = useState<Questionnaire>();
  const [surveySubmitted, setSurveySubmitted] = useState(false);

  const fetchPostVideoSurveyQuestions = useCallback(async () => {
    const questionnaire = await medplum.searchOne('Questionnaire', `_tag=${QuestionnaireType.PostVideoSurvey}`);
    await setVideoSurvey(questionnaire);
  }, [medplum]);

  useEffect(() => {
    fetchPostVideoSurveyQuestions().catch((err) => logError(err));
  }, [medplum, fetchPostVideoSurveyQuestions]);

  if (!videoSurvey) {
    return null;
  }

  const handleSubmit = (response: QuestionnaireResponse) => {
    medplum.createResource<QuestionnaireResponse>(response).catch((err) => logError(err));
    setSurveySubmitted(true);
  };

  const SurveyConfirmation = (): React.JSX.Element => {
    return (
      <>
        <Title c="white" mb="xl" order={3}>
          Thank you for submitting your feedback!
        </Title>
        <Text mb="lg">Hearing from you helps us create the best experience on Care Hub.</Text>
      </>
    );
  };

  return (
    <Flex direction="column" className={classes.surveyWrapper}>
      <img src={CarehubWhiteLogo} alt="carehub logo" />
      {!surveySubmitted ? (
        <QuestionnaireForm questionnaire={videoSurvey} onSubmit={handleSubmit} />
      ) : (
        <SurveyConfirmation />
      )}
      <Button onClick={() => setActiveDyteCall(false)}>Close</Button>
    </Flex>
  );
};
