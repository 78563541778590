import React from 'react';
import { QuestionnaireResponseProvider } from '../tasks/drawer/screenerReview/QuestionnaireResponseProvider';
import { DefaultTaskDrawer } from '../tasks/drawer/screenerReview/DefaultTaskDrawer';
import { ExpandedTaskDrawerContent } from '../tasks/drawer/screenerReview/ExpandedTaskDrawer';
import { Drawer, Loader } from '@mantine/core';
import { QuestionnaireResponse } from '@medplum/fhirtypes';

export type SurveyDrawerContentRendererProps = {
  withViewPatientProfile?: boolean;
  patientId?: string | null;
  drawerExpanded: boolean;
  setDrawerExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  closeDrawer: () => void;
  questionnaireResponse?: QuestionnaireResponse;
};

export type SurveyDrawerContentRenderer = (props: SurveyDrawerContentRendererProps) => React.ReactNode;

export const SurveyDrawerContent: SurveyDrawerContentRenderer = ({
  patientId,
  drawerExpanded,
  setDrawerExpanded,
  closeDrawer,
  withViewPatientProfile,
  questionnaireResponse,
}) => {
  if (!patientId) {
    return (
      <Drawer.Content>
        <Drawer.Body>
          <Loader />
        </Drawer.Body>
      </Drawer.Content>
    );
  }

  return (
    <QuestionnaireResponseProvider initialQuestionnaireResponse={questionnaireResponse} patientId={patientId}>
      {drawerExpanded ? (
        <ExpandedTaskDrawerContent
          patientId={patientId}
          onClose={closeDrawer}
          setDrawerExpanded={setDrawerExpanded}
          drawerExpanded={drawerExpanded}
        />
      ) : (
        <DefaultTaskDrawer
          patientId={patientId}
          setDrawerExpanded={setDrawerExpanded}
          drawerExpanded={drawerExpanded}
          withViewPatientProfile={withViewPatientProfile}
          closeDrawer={closeDrawer}
        />
      )}
    </QuestionnaireResponseProvider>
  );
};
