import { System, TaskType } from 'const-utils';
import { BaseTaskWrapper } from './baseTask';
import { Maybe, PractitionerInfoFragment, SurveyReviewQuestionnaireResponseFragment } from 'medplum-gql';
import { BHSurveyType, QuestionnaireType } from 'const-utils/codeSystems/ImaginePediatrics';

export class SurveyReviewTask extends BaseTaskWrapper<TaskType.ScreenerReview | TaskType.ReviewBHSurvey> {
  get focus(): Maybe<SurveyReviewQuestionnaireResponseFragment> {
    if (this.task.focus?.resource?.__typename === 'QuestionnaireResponse') {
      return this.task.focus?.resource;
    } else {
      return null;
    }
  }

  get owner(): Maybe<PractitionerInfoFragment> {
    if (this.task.owner?.resource?.__typename === 'Practitioner') {
      return this.task.owner.resource;
    }
    return null;
  }

  get isBHSurveyTask() {
    return this.type === TaskType.ReviewBHSurvey;
  }

  get isScreenerReviewTask() {
    return this.type === TaskType.ScreenerReview;
  }

  get isSurveyWithTranslations() {
    return this.type === TaskType.ReviewDisasterPreparednessSurvey;
  }

  get bhSurveyType(): Maybe<BHSurveyType> {
    const bhType = this.task.meta?.tag?.find((tag) => tag.system === System.BHSurveyType)?.code;
    if (!bhType) {
      return null;
    }

    return bhType as BHSurveyType;
  }

  get questionnaireTags(): string[] {
    if (this.isBHSurveyTask) {
      return [QuestionnaireType.BHSurvey, this.bhSurveyType ?? ''];
    }
    if (this.type === TaskType.ReviewDisasterPreparednessSurvey) {
      return [QuestionnaireType.DisasterPreparedness];
    }

    return [QuestionnaireType.Screener];
  }
}
