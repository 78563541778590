import { MedplumClient, createReference, parseReference } from '@medplum/core';
import {
  Attachment,
  Consent,
  DocumentReference,
  Patient,
  QuestionnaireResponseItem,
  Reference,
} from '@medplum/fhirtypes';
import { HL7System, LOINCSystem, LOINCSystemCodes, System } from 'const-utils';
import { getName } from '../utils/patient';
import { PDFDocument, PDFFont, PDFImage, PDFPage, rgb, StandardFonts } from 'pdf-lib';
import fs from 'node:fs';
import path from 'node:path';
import fontkit from '@pdf-lib/fontkit';
import {
  ConsentCode,
  ConsentIdentifier,
  ConsentType,
  DocumentReferenceTag,
  LegacyConsentIdentifier,
  QuestionnaireType,
} from 'const-utils/codeSystems/ImaginePediatrics';
import { P, match } from 'ts-pattern';
import { format } from 'date-fns';
import { ISOStates } from 'const-utils/codeSystems/ISO';
import { isDefined } from '../utils/lists';
import { getMarketKey } from '../utils/types/organization';

export const mapLegacyConsentToConsentIdentifier = (legacyConsent: Consent): QuestionnaireType | null => {
  const identifier = legacyConsent.identifier?.at(0)?.value;

  return match(identifier)
    .with(P.string.includes('TreatAndTelehealthConsents.pdf'), () => QuestionnaireType.ConsentToTreat)
    .with(P.string.includes('ULA.pdf'), () => QuestionnaireType.EndUserLicenseAgreement)
    .with(P.string.includes('RoiConsent.pdf'), () => QuestionnaireType.ReleaseOfInformation)
    .otherwise(() => null);
};

export const generatePatientConsents = async (
  medplum: MedplumClient,
  patientId: string,
  questionnaireConsents?: boolean,
): Promise<Consent[] | undefined> => {
  if (questionnaireConsents) {
    return generateQuestionnaireConsents(medplum, patientId, ConsentType.PatientConsent).catch(() => undefined);
  } else {
    return generateConsents(medplum, patientId, ConsentType.PatientConsent).catch(() => undefined);
  }
};

export const generateCaregiverConsents = async (
  medplum: MedplumClient,
  caregiverId: string,
  questionnaireConsents?: boolean,
): Promise<Consent[] | undefined> => {
  if (questionnaireConsents) {
    return generateQuestionnaireConsents(medplum, caregiverId, ConsentType.CaregiverConsent).catch(() => undefined);
  } else {
    return generateConsents(medplum, caregiverId, ConsentType.CaregiverConsent).catch(() => undefined);
  }
};

export const generateQuestionnaireConsents = async (
  medplum: MedplumClient,
  id: string,
  type: ConsentType,
): Promise<Consent[]> => {
  const documents = await medplum.search('DocumentReference', {
    status: 'current',
    category: type,
  });

  const consentsToGenerate: QuestionnaireType[] = [];

  if (type === ConsentType.PatientConsent) {
    consentsToGenerate.push(QuestionnaireType.ConsentToTreat, QuestionnaireType.ReleaseOfInformation);
  } else if (type === ConsentType.CaregiverConsent) {
    consentsToGenerate.push(QuestionnaireType.EndUserLicenseAgreement);
  }

  const patientRef = `Patient/${id}`;

  const patient = await medplum.readResource('Patient', id);

  const managingOrganization = await medplum.readResource(
    'Organization',
    parseReference(patient.managingOrganization)?.[1],
  );

  const marketKey = getMarketKey(managingOrganization);

  if (!marketKey) {
    throw new Error('Market not found for organization');
  }
  const jurisdisction = ISOStates[marketKey];

  const consentsBundle = await medplum.search('Consent', {
    patient: patientRef,
  });

  const existingConsents =
    consentsBundle.entry
      ?.map((e) => e.resource as Consent)
      .filter((consent) => consent.status === 'draft' || consent.status === 'active') || [];

  const createConsent = async (consentTag: QuestionnaireType): Promise<Consent> => {
    const existing = existingConsents.find((c) => {
      //existing consent matches the consentTag - this consent already exists.
      if (c.meta?.tag?.some((tag) => tag.system === System.ConsentType.toString() && tag.code === consentTag)) {
        return true;
      }

      const identifier = mapLegacyConsentToConsentIdentifier(c);
      if (identifier === consentTag) {
        //legacy consent exists
        return true;
      }

      if (
        documents.entry?.find(
          (doc) =>
            doc.resource?.masterIdentifier?.system ===
            LegacyConsentIdentifier[consentTag as keyof typeof LegacyConsentIdentifier],
        )
      ) {
        return true;
      }

      return false;
    });

    if (existing) {
      return existing;
    }

    let consentTypeDisplay;
    if (
      consentTag === QuestionnaireType.ConsentToTreat ||
      consentTag === QuestionnaireType.ReleaseOfInformation ||
      consentTag === QuestionnaireType.EndUserLicenseAgreement
    ) {
      consentTypeDisplay = ConsentCode[consentTag];
    }

    const newConsent: Consent = {
      resourceType: 'Consent',
      status: 'draft',
      patient: { reference: 'Patient/' + id },
      scope: {
        coding: [
          {
            system: HL7System.ConsentScope,
            code: 'treatment',
            display: 'Treatment',
          },
        ],
      },
      meta: {
        tag: [
          {
            system: System.ConsentType,
            code: consentTag,
            display: consentTypeDisplay,
          },
          {
            system: System.ConsentSource,
            code: 'questionnaire',
            display: 'questionnaire',
          },
          {
            system: System.ConsentJurisdiction,
            code: jurisdisction.code,
            display: jurisdisction.display,
          },
        ],
      },
      // This is a workaround for a medplum issue.
      policyRule: {
        coding: [
          {
            code: 'None',
            display: 'None',
          },
        ],
      },
      category: [
        {
          coding: [
            {
              system: LOINCSystem,
              code: LOINCSystemCodes.PatientConsent,
              display: 'Patient Consent',
            },
          ],
        },
      ],
    };

    return medplum.createResource(newConsent);
  };

  const creations = consentsToGenerate?.map((e) => createConsent(e)) || [];
  return Promise.all(creations);
};

export const generateConsents = async (medplum: MedplumClient, id: string, type: ConsentType): Promise<Consent[]> => {
  const documents = await medplum.search('DocumentReference', {
    status: 'current',
    category: type,
  });

  const patientRef = `Patient/${id}`;

  const consentsBundle = await medplum.search('Consent', {
    patient: patientRef,
  });

  const existingConsents =
    consentsBundle.entry
      ?.map((e) => e.resource as Consent)
      .filter((consent) => consent.status === 'draft' || consent.status === 'active') || [];

  const createConsent = async (doc: DocumentReference): Promise<Consent> => {
    const sourceRef = `DocumentReference/${doc.id}`;

    const existing = existingConsents.find((c) => {
      if (c.sourceReference?.reference === sourceRef) {
        return true;
      }

      const identifier = mapLegacyConsentToConsentIdentifier(c);

      if (identifier) {
        return identifier === doc.masterIdentifier?.system;
      }

      return false;
    });

    if (existing) {
      return existing;
    }

    const newConsent: Consent = {
      resourceType: 'Consent',
      status: 'draft',
      patient: { reference: 'Patient/' + id },
      sourceReference: createReference(doc),
      scope: {
        coding: [
          {
            system: HL7System.ConsentScope,
            code: 'treatment',
            display: 'Treatment',
          },
        ],
      },
      // This is a workaround for a medplum issue.
      policyRule: {
        coding: [
          {
            code: 'None',
            display: 'None',
          },
        ],
      },
      category: [
        {
          coding: [
            {
              system: LOINCSystem,
              code: LOINCSystemCodes.PatientConsent,
              display: 'Patient Consent',
            },
          ],
        },
      ],
    };

    return medplum.createResource(newConsent);
  };

  const creations =
    documents?.entry
      ?.map((e) => e.resource)
      ?.filter(isDefined)
      ?.map((e) => createConsent(e)) || [];

  return Promise.all(creations);
};

type GetSignedConsentDocumentOptions = {
  cursiveFontBytes?: ArrayBuffer;
};

/**
 * getSignedConsentDocument 'signs' a Consent by embedding the associated patient and signer/performer names into the static document reference
 * associated with the Consent
 *
 * This is expected to be used with post-Verity signed Consents as it is dependant on a Consent.performer which does not exist on legacy consents migrated from Verity
 *
 * @param medplum - medplum client
 * @param consent - Consent resource
 * @param options - options for signing
 * @returns Promise<Uint8Array>
 */
export const getSignedConsentDocument = async (
  medplum: MedplumClient,
  consent: Consent,
  options: GetSignedConsentDocumentOptions = {},
): Promise<Uint8Array> => {
  const patientRef = consent?.patient;
  if (!patientRef) {
    throw new Error('missing patient');
  }

  const sourceReference = consent.sourceReference;
  if (!sourceReference) {
    throw new Error('missing document reference');
  }

  const isManualUpload = consent.meta?.tag?.some((tag) => tag.system === System.ManualConsentUpload.toString());
  if (isManualUpload) {
    return getManuallySignedConsent(sourceReference as Reference<DocumentReference>, medplum);
  }

  const performerRef = consent.performer?.at(0);
  if (!performerRef) {
    throw new Error('missing performer reference');
  }

  const consentedAt = consent.dateTime;
  if (!consentedAt) {
    throw new Error('missing consent date time');
  }

  const [patient, performer] = await Promise.all([
    medplum.readReference(patientRef),
    medplum.readReference(performerRef as Reference<Patient>),
  ]);

  const patientBirthDate = patient.birthDate;
  if (!patientBirthDate) {
    throw new Error('missing patient birth date');
  }

  const patientName = getName(patient, { use: 'official' });
  if (!patientName) {
    throw new Error('unable to produce "official" name for patient');
  }
  const performerName = getName(performer, { use: 'official' });
  if (!performerName) {
    throw new Error('unable to produce "official" name for performer');
  }

  let isBrandedConsent = false;
  let pdfDoc: PDFDocument | undefined;

  const [resourceType] = parseReference(sourceReference);
  if (resourceType === 'QuestionnaireResponse') {
    const consentTypeDisplay = consent.meta?.tag?.find((tag) => tag.system === System.ConsentType)?.display;
    pdfDoc = await generateQuestionnaireResponsePDF(
      medplum,
      consent,
      consentTypeDisplay ?? 'Consent',
      patientName,
      patientBirthDate,
      performerName,
      consentedAt,
      options.cursiveFontBytes,
    );

    if (pdfDoc) {
      return pdfDoc.save();
    }
  } else {
    const documentReference = await medplum.readReference(sourceReference as Reference<DocumentReference>);
    isBrandedConsent =
      documentReference.meta?.tag?.some(
        (tag) => tag.system === System.DocumentBranding.toString() && tag.code === 'branded',
      ) ?? false;
    const arrayBuffer = await getDocumentReferenceContent(documentReference);
    pdfDoc = await PDFDocument.load(arrayBuffer);
  }

  if (!pdfDoc) {
    throw new Error('PDFDoc not generated');
  }

  let cursiveFont: PDFFont | undefined = undefined;
  if (options.cursiveFontBytes) {
    pdfDoc.registerFontkit(fontkit);
    const arrayBuffer = new Uint8Array(options.cursiveFontBytes).buffer;
    cursiveFont = await pdfDoc.embedFont(arrayBuffer);
  }

  const pages = pdfDoc.getPages();
  const firstPage = pages.at(0);
  if (!firstPage) {
    throw new Error('no pages found in pdf document');
  }

  const xOffset = isBrandedConsent ? 50 : 70;
  const patientHeaderOffset = isBrandedConsent ? 150 : xOffset;

  //TODO: in future allow placing the patient name in the header of the document of the translated document as well
  //also eventual desire is to allow for serving up caregivers language preference and then only displaying English version with sig in care hub?
  const firstPages: PDFPage[] = [firstPage];
  firstPages.forEach((page) =>
    addPatientNameToConsentDocument(page, patientName, patientBirthDate, patientHeaderOffset),
  );
  const lastPages: PDFPage[] = !isBrandedConsent
    ? [pages.at(-1) as PDFPage]
    : [pages.at(pages.length / 2 - 1) as PDFPage, pages.at(-1) as PDFPage];
  lastPages.forEach((page) =>
    addSignatureToConsentDocument(
      page,
      xOffset,
      isBrandedConsent ? 200 : 100,
      performerName,
      consentedAt,
      cursiveFont!,
    ),
  );

  const lastPage = pages.at(-1)!;
  const yBaseline = isBrandedConsent ? 200 : 100;
  lastPage.drawText('Patient/Parent/Guardian signature:', {
    x: xOffset,
    y: yBaseline,
    size: 14,
  });

  return pdfDoc.save();
};

const addPatientNameToConsentDocument = (
  page: PDFPage,
  patientName: string,
  patientBirthDate: string,
  xOffset: number,
) => {
  const { height: firstPageHeight } = page.getSize();
  page.drawText(`Patient: ${patientName} - ${format(patientBirthDate, 'MM/dd/yyyy')}`, {
    x: xOffset,
    y: firstPageHeight - 50,
    color: rgb(0, 0, 0),
    size: 14,
  });
};

const addSignatureToConsentDocument = async (
  page: PDFPage,
  xOffset: number,
  yBaseline: number,
  performerName: string,
  consentedAt: string,
  cursiveFont: PDFFont,
) => {
  page.drawText('Patient/Parent/Guardian signature:', {
    x: xOffset,
    y: yBaseline,
    size: 14,
  });
  page.drawText(performerName, {
    x: xOffset,
    y: yBaseline - 25,
    size: 14,
    font: cursiveFont,
  });
  page.drawText(`Patient/Parent/Guardian printed name: ${performerName}`, {
    x: xOffset,
    y: yBaseline - 50,
    size: 14,
  });
  page.drawText(`Accepted by ${performerName} on ${format(consentedAt, 'MM/dd/yyyy')}`, {
    x: xOffset,
    y: yBaseline - 70,
    size: 14,
  });
};

const getDocumentReferenceContent = async (documentReference: DocumentReference): Promise<ArrayBuffer> => {
  const documentReferenceContentUrl = documentReference.content?.at(0)?.attachment?.url;
  if (!documentReferenceContentUrl) {
    throw new Error('missing document reference content url');
  }

  const res = await fetch(documentReferenceContentUrl);
  return res.arrayBuffer();
};

const getManuallySignedConsent = async (
  documentReferenceRef: Reference<DocumentReference>,
  medplum: MedplumClient,
): Promise<Uint8Array> => {
  const documentReference = await medplum.readReference(documentReferenceRef as Reference<DocumentReference>);
  const arrayBuffer = await getDocumentReferenceContent(documentReference);
  const pdfDoc = await PDFDocument.load(arrayBuffer);
  return pdfDoc.save();
};

const manualConsentTag = {
  system: System.ManualConsentUpload,
  code: DocumentReferenceTag.ManualConsentUpload,
  display: DocumentReferenceTag.ManualConsentUpload,
};
/**
 * This method will upload a pdf to draft consent to treat and release of information for the given patient
 * How do we want to handle if no draft is found? Do we create the consent? Verify if one is currently "final"? Then override it?
 *
 * @param medplum - medplum client
 * @param patientReference - reference to the patient
 * @param attachment - the consent attachment
 */
export const uploadPatientConsent = async (
  medplum: MedplumClient,
  patientReference: Reference<Patient>,
  attachment: Attachment,
): Promise<void> => {
  let consentToTreat: Consent | undefined;
  let roi: Consent | undefined;
  consentToTreat = await medplum.searchOne(
    'Consent',
    `patient=${patientReference.reference}&_tag=${QuestionnaireType.ConsentToTreat}&status=draft`,
  );
  roi = await medplum.searchOne(
    'Consent',
    `patient=${patientReference.reference}&_tag=${QuestionnaireType.ReleaseOfInformation}&status=draft`,
  );

  if (!consentToTreat) {
    //look for legacy consent
    const consentToTreatRef = await medplum.searchOne('DocumentReference', {
      status: 'current',
      category: ConsentType.PatientConsent,
      type: ConsentType.ConsentToTreatAndTelehealth,
    });
    consentToTreat = await medplum.searchOne(
      'Consent',
      `patient=${patientReference.reference}&source-reference=DocumentReference/${consentToTreatRef?.id}&status=draft`,
    );
  }

  if (!roi) {
    const roiRef = await medplum.searchOne('DocumentReference', {
      status: 'current',
      category: ConsentType.PatientConsent,
      type: ConsentType.ReleaseOfInformation,
    });
    roi = await medplum.searchOne(
      'Consent',
      `patient=${patientReference.reference}&source-reference=DocumentReference/${roiRef?.id}&status=draft`,
    );
  }

  if (!consentToTreat && !roi) {
    throw new Error('Patient does not have a consent in draft, document not uploaded');
  }

  if (consentToTreat) {
    //TODO: Determine if we still upload the document even if no consent draft exist
    const consentDocumentUpload = await medplum.createResource({
      resourceType: 'DocumentReference',
      masterIdentifier: {
        system: ConsentIdentifier.ConsentToTreat,
        value: 'manual',
      },
      content: [
        {
          attachment,
        },
      ],
      docStatus: 'final',
      status: 'superseded', //using this status instead of current to help differentiate between manual, the real status that matters is the "consent itself"
      type: {
        coding: [
          {
            code: ConsentType.ConsentToTreatAndTelehealth,
            display: ConsentType.ConsentToTreatAndTelehealth,
          },
        ],
      },
      category: [
        {
          coding: [
            {
              code: ConsentType.PatientConsent,
              display: ConsentType.PatientConsent,
            },
          ],
        },
      ],
      subject: patientReference,
      date: new Date().toISOString(),
      meta: {
        tag: [manualConsentTag],
      },
    });
    const tags = [...(consentToTreat.meta?.tag || []), manualConsentTag];
    await medplum.updateResource({
      ...consentToTreat,
      sourceReference: createReference(consentDocumentUpload),
      meta: { ...consentToTreat.meta, tag: tags },
      dateTime: new Date().toISOString(),
      status: 'active',
    });
  }
  if (roi) {
    //dupe of attachment with type, or reset password will generate the ROI to sign in app
    const roiDocumentUpload = await medplum.createResource({
      resourceType: 'DocumentReference',
      masterIdentifier: {
        system: ConsentIdentifier.ReleaseOfInformation,
        value: 'manual',
      },
      content: [
        {
          attachment,
        },
      ],
      docStatus: 'final',
      status: 'superseded', //using this status instead of current to help differentiate between manual, the real status that matters is the "consent itself"
      type: {
        coding: [
          {
            code: ConsentType.ReleaseOfInformation,
            display: ConsentType.ReleaseOfInformation,
          },
        ],
      },
      category: [
        {
          coding: [
            {
              code: ConsentType.PatientConsent,
              display: ConsentType.PatientConsent,
            },
          ],
        },
      ],
      subject: patientReference,
      date: new Date().toISOString(),
      meta: {
        tag: [manualConsentTag],
      },
    });
    const tags = [...(roi.meta?.tag || []), manualConsentTag];
    await medplum.updateResource({
      ...roi,
      sourceReference: createReference(roiDocumentUpload),
      meta: { ...roi.meta, tag: tags },
      dateTime: new Date().toISOString(),
      status: 'active',
    });
  }
};

/**
 * This method will upload a pdf to draft consent to treat and release of information for the given Caregiver
 * How do we want to handle if no draft is found? Do we create the consent? Verify if one is currently "final"? Then override it?
 *
 * @param medplum - medplum client
 * @param caregiverReference - reference to the patient
 * @param attachment - the consent attachment
 */
export const uploadCaregiverConsent = async (
  medplum: MedplumClient,
  caregiverReference: Reference<Patient>,
  attachment: Attachment,
): Promise<void> => {
  let eula: Consent | undefined;
  eula = await medplum.searchOne(
    'Consent',
    `patient=${caregiverReference.reference}&_tag=${QuestionnaireType.EndUserLicenseAgreement}&status=draft`,
  );

  if (!eula) {
    const eulaRef = await medplum.searchOne('DocumentReference', {
      status: 'current',
      category: ConsentType.CaregiverConsent,
    });

    eula = await medplum.searchOne(
      'Consent',
      `patient=${caregiverReference.reference}&source-reference=DocumentReference/${eulaRef?.id}&status=draft`,
    );
  }

  if (eula) {
    //TODO: Determine if we still upload the document even if no consent draft exist
    const consentDocumentUpload = await medplum.createResource({
      resourceType: 'DocumentReference',
      masterIdentifier: {
        system: ConsentIdentifier.EULA,
        value: 'manual',
      },
      content: [
        {
          attachment,
        },
      ],
      docStatus: 'final',
      status: 'superseded', //using this status instead of current to help differentiate between manual, the real status that matters is the "consent itself"
      type: {
        coding: [
          {
            code: ConsentType.Eula,
            display: ConsentType.Eula,
          },
        ],
      },
      category: [
        {
          coding: [
            {
              code: ConsentType.CaregiverConsent,
              display: ConsentType.CaregiverConsent,
            },
          ],
        },
      ],
      subject: caregiverReference,
      date: new Date().toISOString(),
      meta: {
        tag: [manualConsentTag],
      },
    });

    const tags = [...(eula.meta?.tag || []), manualConsentTag];
    await medplum.updateResource({
      ...eula,
      sourceReference: createReference(consentDocumentUpload),
      meta: { ...eula.meta, tag: tags },
      dateTime: new Date().toISOString(),
      status: 'active',
    });
  } else {
    throw new Error('Caregiver does not have a consent in draft, document not uploaded');
  }
};

export const generateQuestionnaireResponsePDF = async (
  medplum: MedplumClient,
  consent: Consent,
  title: string,
  patientName: string,
  patientBirthDate: string,
  performerName: string,
  consentedAt: string,
  cursiveFontBytes?: Int8Array | Buffer | ArrayBuffer,
): Promise<PDFDocument | undefined> => {
  const [resourceType, resourceId] = parseReference(consent.sourceReference);

  if (!resourceType || !resourceId || resourceType !== 'QuestionnaireResponse') {
    return undefined;
  }

  const questionnaireResponse = await medplum.readResource('QuestionnaireResponse', resourceId);

  const pdfDoc = await PDFDocument.create();
  const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

  let page = pdfDoc.addPage();
  const { height, width } = page.getSize();
  const fontSize = 30;

  let yPosition = height - 2 * fontSize - 145;

  const logoImageBytes = fs.readFileSync(path.join(__dirname, 'assets', 'images', 'imagine-logo.png'));

  let logoImage: PDFImage;

  if (logoImageBytes) {
    const arrayBuffer = new Uint8Array(logoImageBytes).buffer;
    pdfDoc.registerFontkit(fontkit);

    logoImage = await pdfDoc.embedPng(arrayBuffer);
    page.drawImage(logoImage, {
      x: 50,
      y: height - 100,
      width: 250,
      height: 100,
    });
  }

  page.drawText(`Patient: ${patientName} - ${format(patientBirthDate, 'MM/dd/yyyy')}`, {
    x: 50,
    y: height - 120,
    color: rgb(0, 0, 0),
    size: 14,
  });

  page.drawText(title, {
    x: 50,
    y: height - 2 * fontSize - 110,
    size: fontSize,
    font: timesRomanFont,
    color: rgb(0, 0, 0),
    maxWidth: width - 100,
  });

  const renderItems = (items: QuestionnaireResponseItem[], depth?: number) => {
    items.forEach((item) => {
      if (item.item) {
        if (item.text) {
          addHeader(item.text!);
        }
        renderItems(item.item, depth ? depth + 1 : 1);
      } else if (item.text) {
        splitParagraph(item.text, isBulletDisplayType(item), isHeadingDisplayType(item), depth);
      }
    });
  };

  const splitParagraph = (text: string, isBullet?: boolean, isHeader?: boolean, depth?: number) => {
    const words = text.split(' ');
    let line = '';

    words.forEach((word) => {
      const newLine = line + ' ' + word;
      const yOffset =
        (timesRomanFont.widthOfTextAtSize(newLine, 16) / (width - 100)) * (timesRomanFont.heightAtSize(16) + 12) + 12;
      if (yPosition < yOffset) {
        addParagraph(line, isBullet, isHeader, depth);
        line = '';
        page = pdfDoc.addPage();
        yPosition = height - 50;
      } else {
        line = newLine;
      }
    });

    if (line) {
      addParagraph(line, isBullet, isHeader, depth);
    }
  };

  const maybeAddNewPage = (newTextHeight: number) => {
    if (yPosition < newTextHeight) {
      page = pdfDoc.addPage();
      yPosition = height - 50;
    }
    return { page, yPosition };
  };

  const addHeader = (text: string) => {
    const yOffset =
      (timesRomanFont.widthOfTextAtSize(text, 20) / (width - 100)) * (timesRomanFont.heightAtSize(20) + 15) + 16;
    maybeAddNewPage(yOffset);

    page.drawText(text, {
      x: 50,
      y: yPosition,
      size: 20,
      font: timesRomanFont,
      color: rgb(0, 0, 0),
      maxWidth: width - 100,
    });

    yPosition -= yOffset;
  };

  const addParagraph = (text: string, isBullet?: boolean, isHeader?: boolean, depth?: number) => {
    const yOffset =
      (timesRomanFont.widthOfTextAtSize(text, 16) / (width - 100)) * (timesRomanFont.heightAtSize(16) + 12) + 12;

    page.drawText(isBullet ? '• ' + text : text, {
      x: 50 + (depth && isBullet ? depth * 10 : 0),
      y: yPosition - 2,
      size: isHeader ? 17 : 15,
      font: timesRomanFont,
      color: rgb(0, 0, 0),
      maxWidth: width - 100,
    });

    yPosition -= yOffset;
  };

  renderItems(questionnaireResponse.item!);

  let cursiveFont: PDFFont | undefined = undefined;
  if (cursiveFontBytes) {
    const arrayBuffer = new Uint8Array(cursiveFontBytes).buffer;
    pdfDoc.registerFontkit(fontkit);
    cursiveFont = await pdfDoc.embedFont(arrayBuffer);
  }

  if (yPosition < 150) {
    page = pdfDoc.addPage();
    yPosition = height - 50;
  }
  await addSignatureToConsentDocument(page, 50, yPosition - 25, performerName, consentedAt, cursiveFont!);

  await pdfDoc.save();

  return pdfDoc;
};

export const isBulletDisplayType = (item: QuestionnaireResponseItem): boolean | undefined => {
  return item.extension?.some(
    (extension) =>
      extension.url === (System.QuestionnaireItemDisplayType as string) && extension.valueCode === 'bullet',
  );
};

export const isHeadingDisplayType = (item: QuestionnaireResponseItem): boolean | undefined => {
  return item.extension?.some(
    (extension) =>
      extension.url === (System.QuestionnaireItemDisplayType as string) && extension.valueCode === 'heading',
  );
};
