import React from 'react';
import { Text, Table, Group, Stack, Button, ThemeIcon } from '@mantine/core';
import { TaskActivity } from './types';
import { formatWithYesterdayAndToday } from 'imagine-dsl/utils/dates';
import { IconPaperclip } from '@tabler/icons-react';
import { Concepts, codeSystemConcepts, TaskType } from 'const-utils';
import { useNavigate } from 'react-router-dom';
import { useTaskCountContext } from '@/pages/Task/TaskCountProvider';
import { SurveyTask } from 'imagine-dsl/models/tasks/surveyTask';
import { SurveyTaskInfoFragment } from 'medplum-gql';
import { useTaskAlertContext } from '@/pages/Task/TaskAlertProvider';

interface TaskActivityTableItemProps {
  alert: TaskActivity;
}
export const TaskActivityItem = ({ alert }: TaskActivityTableItemProps): JSX.Element => {
  const { setSelectedSurveyId } = useTaskCountContext();
  const { setSelectedTaskId, setResolvedTasks } = useTaskAlertContext();

  const TaskTypeCodes = codeSystemConcepts[Concepts.TaskType].concept;
  const taskTypeCode = TaskTypeCodes?.find((c) => (c.code as TaskType) === alert.type);

  const urgentColor = 'imagine-green';
  const isResolved = alert.status === 'completed' || alert.status === 'accepted' || alert.status === 'rejected';

  const navigate = useNavigate();

  const goToAlert = () => {
    if (
      alert.type === TaskType.ScreenerReview ||
      alert.type === TaskType.ReviewBHSurvey ||
      alert.type === TaskType.ReviewDisasterPreparednessSurvey
    ) {
      const originalTask = alert.task && 'basedOn' in alert.task ? alert.task.basedOn?.[0] : null;
      const surveyTask =
        originalTask && 'resource' in originalTask
          ? new SurveyTask(originalTask.resource as SurveyTaskInfoFragment)
          : null;
      if (surveyTask?.id) {
        setSelectedSurveyId(surveyTask.id);
      }
    } else if (alert.type === TaskType.CarePathwayReferralReview) {
      setSelectedTaskId(alert.id);
      if (isResolved) {
        setResolvedTasks(true);
      }
      navigate(`/Task?task=${alert.id}`);
    }
  };

  const ownerName = alert.owner?.name ? alert.owner.name : alert.author;

  return (
    <Table.Tr style={{ verticalAlign: 'top' }} key={alert.id} data-cy={`task-activity-item-${alert.id}`}>
      <Table.Td>
        <Stack justify="flex-start" mt="25px">
          <ThemeIcon c={urgentColor} bd={`1px solid ${urgentColor}`} radius="100%" p={2}>
            <IconPaperclip size={16} />
          </ThemeIcon>
        </Stack>
      </Table.Td>

      <Table.Td>
        <Stack gap={10}>
          {alert.date && (
            <Text size="xs" c="imagine-gray">
              {formatWithYesterdayAndToday(alert.date, 'EEE, MMMM do, yyyy hh:mm aaa', true)}
            </Text>
          )}
          <Group gap={4}>
            <Text fw="bold" size="sm">
              Task: {taskTypeCode?.display}
            </Text>
            {ownerName && (isResolved ? <Text>resolved by</Text> : <Text>is assigned to</Text>)}
            {ownerName && (
              <Group gap={4}>
                <Text fw={700} size="sm">
                  {ownerName}
                </Text>
                {alert.owner?.role && (
                  <Text fw={400} c="imagine-gray">
                    ({alert.owner.role})
                  </Text>
                )}
              </Group>
            )}
          </Group>
          <Button h={25} w={100} fz={12} onClick={goToAlert} variant="outline">
            View task
          </Button>
        </Stack>
      </Table.Td>
    </Table.Tr>
  );
};
